import React, { useEffect, useState } from "react";
import { IOfferPartner } from "../../../interfaces/IOfferPartner";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { IPartner } from "../../../interfaces/IPartner";
import { CustomeTextField } from "../../core/CustomeTextField";
import { Delete, Warning } from "@mui/icons-material";
import { IOffer } from "../../../interfaces/IOffer";


interface IProps {
    offerObject: IOffer;
    offerPartner: IOfferPartner;
    partnerArray: IPartner[];
    offerPartnerArray: IOfferPartner[];
    setOfferPartnerArray: Function;
}


export const OfferPartnerTableRow:React.FC<IProps> = ({offerObject, offerPartner, partnerArray, offerPartnerArray, setOfferPartnerArray}) => {
    const [editOfferPartner,setEditOfferPartner] = useState(offerPartner);
    const [partner,setPartner] = useState(partnerArray.find(x => x.id === offerPartner.Partner));
    //
    const [hasWarningSellingPrice,setHasWarningSellingPrice] = useState(false);

    useEffect(() => {
        setOfferPartnerArray([
            ...offerPartnerArray.map(x => x.Partner === editOfferPartner.Partner ? editOfferPartner : x)
        ])
    },[editOfferPartner])


    useEffect(() => {
        setHasWarningSellingPrice(partner === undefined ? false : partner?.selling_price != editOfferPartner.selling_price_offer)
    },[partner,editOfferPartner])


    const handleRemovePartner = () => {
        setOfferPartnerArray([
            ...offerPartnerArray.filter(x => x.Partner !== editOfferPartner.Partner)
        ])
    }

    return(
        <>
            <TableRow sx={{backgroundColor: (hasWarningSellingPrice) ? "#ffffe7" : undefined}}>
                <TableCell>
                    <Collapse in={hasWarningSellingPrice}>
                        <span title="Der gesetzte Stundenpreis entspricht nicht dem Stundenpreis des Partners">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>
                </TableCell>
                <TableCell> 
                    {partner === undefined ? <i>Kein Partner ausgewählt</i> : `${partner.Contact_Person.Company?.company_name}: ${partner.Contact_Person.firstname} ${partner.Contact_Person.lastname}` }
                </TableCell>
                <TableCell>{partner === undefined ? "k.A." :partner.selling_price.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                <TableCell>
                    <CustomeTextField
                        label="Ges. P.p.H."
                        attr="selling_price_offer"
                        object={editOfferPartner}
                        setObject={setEditOfferPartner}
                        type="euro"
                        disabled={offerObject.OfferState > 2}

                    />
                </TableCell>
                <TableCell>
                    <Collapse in={offerObject.OfferState <= 2}>
                        <IconButton size="small" onClick={handleRemovePartner}><Delete/></IconButton>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}