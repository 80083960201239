import React, { useEffect, useState } from "react";
import { Grid, Switch, FormControlLabel, Typography} from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import PostcodeSearchV2 from "../core/PostcodeSearchV2";
import { getEmptyAddress } from "../../sites/CustomerManagment/CustomerOverview";
import { IProject } from "../../interfaces/IProject";
import { IAddress } from "../../interfaces/IAddress";

interface IProps {
    projectObject: IProject;
    setProjectObject: Function;
}

export const AddressEdit:React.FC<IProps> = ({ projectObject, setProjectObject}) => {

  const [constructionSite, setConstructionSite] = useState<IAddress | undefined>(() => projectObject.ConstructionSite || getEmptyAddress(10));
  const [deliverySite, setDeliverySite] = useState<IAddress | undefined>(() => projectObject.DeliveryAddress || getEmptyAddress(5));

  const [useConstructionSite, setUseConstructionSite] = useState<boolean>(projectObject.ConstructionSite==null ? false: true);
  const [useDeliverySite, setUseDeliverySite] = useState<boolean>(projectObject.DeliveryAddress==null ? false: true)


  useEffect(() => {
    if(useConstructionSite===false){
      setProjectObject({...projectObject,
        ConstructionSite: null} as IProject)
    } else{
    setProjectObject({
          ...projectObject,
          ConstructionSite: { ...constructionSite}
      } as IProject)
    }
  },[useConstructionSite, constructionSite, setProjectObject, projectObject])

  useEffect(() => {

    if(useDeliverySite===false){
      setProjectObject({...projectObject,
        DeliveryAddress: null} as IProject)
    } else{
      setProjectObject({
          ...projectObject,
          DeliveryAddress: { ...deliverySite}
      } as IProject)
    }
    
  },[useDeliverySite, deliverySite, setProjectObject, projectObject])

  useEffect(() => {
    if (projectObject.ConstructionSite) {
        setConstructionSite(projectObject.ConstructionSite);
    } else if (useConstructionSite) {
        setConstructionSite(getEmptyAddress(10));
    }
}, [projectObject.ConstructionSite, useConstructionSite]);

useEffect(() => {
    if (projectObject.DeliveryAddress) {
        setDeliverySite(projectObject.DeliveryAddress);
    } else if (useDeliverySite) {
        setDeliverySite(getEmptyAddress(5));
    }
}, [projectObject.DeliveryAddress, useDeliverySite]);


  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setUseConstructionSite(checked);
    if (!checked) {
        setProjectObject({...projectObject, ConstructionSite: null});
    } else {
        setConstructionSite(current => current || getEmptyAddress(10));
    }
};

const handleToggle2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setUseDeliverySite(checked);
    if (!checked) {
        setProjectObject({...projectObject, DeliveryAddress: null});
    } else {
        setDeliverySite(current => current || getEmptyAddress(5));
    }
};

  return (
    <>
      <Grid container spacing={2}>
        <Typography>
          Falls die Baustellenaddresse oder die Lieferaddresse andere sind als die Kundenaddresse,
          bitte hier eintragen:
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={useConstructionSite} onChange={handleToggle} />}
            label="Baustellenadresse"
          />
        </Grid>
        {useConstructionSite && (
          <Grid item xs={12} container spacing={2}>
            <Grid item sm={8}>
              <CustomeTextField
                label="Straße"
                attr="street"
                type="string"
                object={constructionSite ?? {}}
                setObject={setConstructionSite}
              />
            </Grid>
            <Grid item sm={4}>
              <CustomeTextField
                label="Nr."
                attr="street_number"
                type="string"
                object={constructionSite ?? {}}
                setObject={setConstructionSite}
              />
            </Grid>
            <Grid item sm={12}>
              <PostcodeSearchV2
                idPostcode={constructionSite?.Postcode || null}
                setIdPostcode={(idPostcode: number) => setConstructionSite({...constructionSite, Postcode: idPostcode})}
              />
            </Grid>
          </Grid>
        )}
        
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <FormControlLabel
            control={<Switch checked={useDeliverySite} onChange={handleToggle2} />}
            label="Lieferadresse"
          />
        </Grid>
        {useDeliverySite && (
          <Grid item xs={12} container spacing={2}>
            <Grid item sm={8}>
              <CustomeTextField
                label="Straße"
                attr="street"
                type="string"
                object={deliverySite ?? {}}
                setObject={setDeliverySite}
              />
            </Grid>
            <Grid item sm={4}>
              <CustomeTextField
                label="Nr."
                attr="street_number"
                type="string"
                object={deliverySite ?? {}}
                setObject={setDeliverySite}
              />
            </Grid>
            <Grid item sm={12}>
              <PostcodeSearchV2
                idPostcode={deliverySite?.Postcode || null}
                setIdPostcode={(idPostcode: number) => setDeliverySite({...deliverySite, Postcode: idPostcode})}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
);
}