import React, { useEffect, useState } from "react";
import { IInsurance } from "../../../interfaces/IInsurance";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { IPostcode } from "../../../interfaces/IPostcode";
import { getFetch } from "../../../hooks/useFetch";
import { GridExpandMoreIcon } from "@mui/x-data-grid";



interface IProps {
    is_insured:boolean;
    insurance: number | null;
    insuranceArray: IInsurance[];
}



export const InsuranceContactCard:React.FC<IProps> = ({is_insured, insurance, insuranceArray}) => {
    const [insuranceObject,setInsuranceObject] = useState(insuranceArray.find(x => x.id === insurance));
    const [postcodeObject,setPostcodeObject] = useState<IPostcode | undefined>();


    useEffect(() => {
        setInsuranceObject(insuranceArray.find(x => x.id === insurance))
    },[insurance])

    useEffect(() => {
        if (insuranceObject?.Address.Postcode !== undefined) {
            getFetch("/postcode/",insuranceObject?.Address.Postcode,setPostcodeObject)
        }
    },[insuranceObject?.Address.Postcode])

    const checkNoneString = (text:string|undefined|null)  => {
        if (text === undefined || text === null) {
            return <>-</>
        } else {
            return text
        }
    }


    if(insuranceObject === undefined) {
        return(
            <>
            <Accordion disabled>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    id="panel1-header"
                >
                    Kontaktinformationen der Pflegekasse
                </AccordionSummary>
            </Accordion>                
            </>
        )
    }
    else {
        return(
            <Accordion>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    id="panel1-header"
                >
                    Kontaktinformationen der Pflegekasse
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Card variant="outlined">
                            <CardContent>
                                
                                <Grid container spacing={2}>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2}><b>{insuranceObject.insurance_name}</b></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"50%"}><b>Tel.:</b> <a href={`tel:${insuranceObject.telefon}`}>{insuranceObject.telefon}</a></TableCell>
                                                <TableCell><b>E-Mail:</b> <a href={`mailto:${insuranceObject.email}`}>{insuranceObject.email}</a></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <b>Adresse:</b> {checkNoneString(insuranceObject.Address.street)} {checkNoneString(insuranceObject.Address.street_number)}, {checkNoneString(postcodeObject?.postcode)} {checkNoneString(postcodeObject?.city)}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </AccordionDetails>
            </Accordion>
        )
    }

}