import React from 'react';
import { Snackbar, Alert, SnackbarCloseReason } from "@mui/material";
import { IFeedback } from "../../interfaces/IFeedback";


interface CustomSnackbarProps {
  feedback: IFeedback;
  setFeedback: React.Dispatch<React.SetStateAction<IFeedback>>;
}

export const CustomSnackbar: React.FC<CustomSnackbarProps> = ({feedback, setFeedback}) => {

  const handleCloseSnackbar = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason?: SnackbarCloseReason | undefined
  )  => {
    if (reason === 'clickaway') {
      return;
    }
    setFeedback({ ...feedback, open: false });
  };

  const handleAlertClose = (event: React.SyntheticEvent<Element, Event>) => {
    handleCloseSnackbar(event); // Call the original handler without the reason
  };  

  return(
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right"  }} open={feedback.open} autoHideDuration={2000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleAlertClose} severity={feedback.severity} sx={{ width: '100%' }}>
        {feedback.message}
      </Alert>
    </Snackbar>

)}