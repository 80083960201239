import React, { useState } from "react";
import { IProjectNote } from "../../../interfaces/IProjectNote";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { getTmpId } from "../../core/generic_helpders";
import { IProfile, IUser } from "../../../interfaces/IUser";
import { ProjectNoteRow } from "./ProjectNoteRow";


interface IProps {
    projectId: number;
    userArray: IProfile[];
    noteArray: IProjectNote[];
    setNoteArray: Function;
}


const getEmptyNote = (projectId: number, noteArray:IProjectNote[]) => {
    let tmpId = getTmpId(noteArray);

    return {
        created_at: null,
        id: tmpId,
        note: "",
        Project: projectId,
        User: Number(localStorage.getItem("idUser"))
    } as IProjectNote
}


export const ProjectNoteTable:React.FC<IProps> = ({projectId, userArray, noteArray, setNoteArray}) => {
    const [newNote,setNewNote] = useState(getEmptyNote(projectId,noteArray));

    const addNew = () => {
        let newObject = getEmptyNote(projectId,noteArray);
        setNoteArray([...noteArray,newObject])
    }



    return(
        <>
            <Typography variant="h5">
                Notizen
                <Button variant="outlined" onClick={addNew} sx={{float: "right"}}>Neue Notiz</Button>
            </Typography>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 200}}>Datum</TableCell>
                        <TableCell sx={{width: 200}}>Benutzer</TableCell>
                        <TableCell>Text</TableCell>
                        <TableCell sx={{width: 100}}></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {noteArray.map(x =>
                        <ProjectNoteRow
                            key={`projectnote-${x.id}`}
                            currentNoteArray={x}
                            userArray={userArray}
                            noteArray={noteArray}
                            setNoteArray={setNoteArray}
                        />
                    )}
                </TableBody>
            </Table>
        </>
    )
}