import React, { useEffect, useState } from "react";
import { IHandoverProtocolPartner } from "../../../interfaces/IHandoverProtocolPartner";
import { IPartner } from "../../../interfaces/IPartner";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowParams, deDE } from "@mui/x-data-grid";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { IHandoverProtocol } from "../../../interfaces/IHandoverProtocol";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";


interface IProps {
    handoverObject: IHandoverProtocol;
    isOpenAddPartner: boolean;
    setIsOpenAddPartner: Function;
    handoverPartnerArray: IHandoverProtocolPartner[];
    setHandoverPartnerArray: Function;
    partnerArray: IPartner[];
    offerPosArray: IOfferPosition[];
    setOfferPosArray: Function;
}



export const HandoverAddPartner:React.FC<IProps> = ({
    handoverObject, 
    isOpenAddPartner,setIsOpenAddPartner,
    handoverPartnerArray,setHandoverPartnerArray,
    partnerArray,
    offerPosArray, setOfferPosArray
}) => {
    const [addPartnerList,setAddPartnerList] = useState<GridRowId[]>([]);

    useEffect(() => {
        if (isOpenAddPartner) {
            setAddPartnerList([])
        }
    },[isOpenAddPartner])


    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 70 
        },
        { 
            field: 'Company', 
            headerName: 'Firma', 
            renderCell: (params: GridRenderCellParams<any, IPartner>) => params.row.Contact_Person.Company.company_name,
            flex: 1
        },
        { 
            field: 'Ansprechpartner', 
            headerName: 'Ansprechpartner', 
            renderCell: (params: GridRenderCellParams<any, IPartner>) => `${params.row.Contact_Person.firstname} ${params.row.Contact_Person.lastname}`,
            flex: 1
        },
    ];
    


    const handleAddPartner =  ( ) => {
        let minId = -1;
        let tmpArray = [...handoverPartnerArray]

        if (handoverPartnerArray.length > 0) {
            let minIdArray = Math.min(...handoverPartnerArray.map(x => x.id)) -1;

            if (minIdArray <= minId) {
                minId = minIdArray - 1;
            }
        }

        addPartnerList.map((idPartner,idx) => {
            let newHandover:IHandoverProtocolPartner = {
                id: minId-idx,
                HandoverProtocolPositionArray: [],
                HandoverProtocol: handoverObject.id,
                Partner: Number(idPartner),
                HandoverApplicationDocumentArray: []
            }
            

            if (idx === 0 && tmpArray.length === 0) {
                setOfferPosArray([
                    ...offerPosArray.map(x => { return {...x, Partner: idPartner}})
                ])
            }

            tmpArray.push(newHandover)
        })

        setHandoverPartnerArray(tmpArray);
        setIsOpenAddPartner(false);
    }

    
    return(
        <>
            <Dialog open={isOpenAddPartner} onClose={() => setIsOpenAddPartner(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={partnerArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setAddPartnerList([
                                ...newRowSelectionModel
                            ])                       
                        }}
                        rowSelectionModel={addPartnerList}
                        columns={columns}
                        //getRowId={(row) => row.idContact}
                        //isRowSelectable={(params: GridRowParams) => props.contactArray.map(x => x.idContact).indexOf(Number(params.row.idContact)) === -1 }
                        isRowSelectable={(params: GridRowParams) => handoverPartnerArray.map(x => x.Partner).indexOf(Number(params.id)) === -1 }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenAddPartner(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleAddPartner}>Übernehmen</Button>
                </DialogActions>
            </Dialog>


        </>
    )
}