import React, { useEffect, useState } from "react";
import { IBillPosition } from "../../../interfaces/IBillPosition";
import { IPartner } from "../../../interfaces/IPartner";
import { IService } from "../../../interfaces/IService";
import { IServiceCategory } from "../../../interfaces/IServiceCategory";
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Table, TableCell, TableRow } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { IUnit } from "../../../interfaces/IUnit";
import { ArrowDownward, ArrowUpward, Delete, Edit, RestartAlt, Warning } from "@mui/icons-material";
import { IBillPartner } from "../../../interfaces/IBillPartner";
import { IBill } from "../../../interfaces/IBill";


interface IProps {
    billObject: IBill;
    billPositionObject: IBillPosition;
    billPositionArray: IBillPosition[];
    setBillPosition: Function;
    billPartnerArray: IBillPartner[];
    partnerArray: IPartner[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
    unitArray: IUnit[];
}


export const BillPositionRow:React.FC<IProps> = ({
    billObject,
    billPositionObject, 
    billPositionArray, 
    setBillPosition, 
    billPartnerArray, 
    partnerArray, 
    serviceArray, 
    serviceCategoryArray,
    unitArray
})  => {
    const [editBillPosition,setEditBillPosition] = useState(billPositionObject);
    const [currentPostition,setCurrentPostition] = useState(billPositionObject.position);
    //
    const [hasWarning,setHasWarning] = useState(false);
    const [hasWarningPartner, setHasWarningPartner] = useState(false);
    //
    const [isOpenEditDescription, setIsOpenEditDescription] = useState(false);
    
    


    const calcPrice = (returnAsString:boolean = true, returnAsObject : boolean = false) => {
        let normalPrice = editBillPosition.price_per_hour * editBillPosition.timeFactor;

        if (editBillPosition.wasChangedPrice) {
            normalPrice = editBillPosition.price
        }

        if (returnAsString) {
            return normalPrice.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
        }
        else {
            if (returnAsObject) {
                console.log("Object")
                let returnObject:IBillPosition = {
                    ...editBillPosition,
                    price: normalPrice
                }
                console.log(returnAsObject)
                return returnObject;
            } else {
                return normalPrice;
            }
            
        }
    }


    const calcTotalPrice = () => {
        let normalPrice = calcPrice(false);
        let totalPrice = Number(normalPrice) * editBillPosition.units;
        return totalPrice.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
    }


    /// ueseEffect: Aktualiseren bei Veränderung
    useEffect(() => {
        let updatedPos = {...editBillPosition, position: currentPostition}
        setBillPosition([
            ...billPositionArray.map(x => x.id === updatedPos.id ? updatedPos : x)
        ])
    },[editBillPosition])


    // Zusammenfassen aller Warning
    useEffect(() => {
        setHasWarning(
            hasWarningPartner 
            || editBillPosition.wasChangedPricePerHour 
            || editBillPosition.wasChangedTimeFactor 
            || editBillPosition.wasChangedTitle
            || editBillPosition.wasChangedPrice
        )
    },[hasWarningPartner,editBillPosition.wasChangedPricePerHour,editBillPosition.wasChangedTimeFactor, editBillPosition.wasChangedTitle, editBillPosition.wasChangedPrice])


    // Warning bzgl. Partner
    useEffect(() => {
        setHasWarningPartner(billPartnerArray.map(x => x.Partner).indexOf(editBillPosition.Partner) <= -1)
    },[billPartnerArray])

    //

    useEffect(() => {
        if (editBillPosition.wasChangedPricePerHour === false) {
            let foundBillPartner = billPartnerArray.find(x => x.Partner === editBillPosition.Partner);

            if (foundBillPartner !== undefined) {
                setEditBillPosition({
                    ...editBillPosition,
                    price_per_hour: foundBillPartner.selling_price_bill
                } as IBillPosition)
            }
        }
    },[editBillPosition.Partner,billPartnerArray])


    const getIsMax = () => {
        let posFromArray = Math.max(...billPositionArray.map(x => x.position));
        return (editBillPosition.position === posFromArray);
    }


    const handleUp = () => {
        let currentArray = [...billPositionArray];
        let changedObject = currentArray.find(x => x.position === editBillPosition.position-1)
        let localEditBillPosition = {...editBillPosition}

        if (changedObject !== undefined) {
            changedObject = {...changedObject, position: localEditBillPosition.position}
            localEditBillPosition = {...localEditBillPosition, position: localEditBillPosition.position-1}
            setCurrentPostition(localEditBillPosition.position-1);
            setBillPosition([
                ...currentArray
                    .map(x => x.id === changedObject!.id ? changedObject : x.id === localEditBillPosition.id ? localEditBillPosition : x)
            ])
        }

    }


    const handleDown= () => {
        let currentArray = [...billPositionArray];
        let changedObject = currentArray.find(x => x.position === editBillPosition.position+1)
        let localEditBillPosition = {...editBillPosition}

        if (changedObject !== undefined) {
            changedObject = {...changedObject, position: localEditBillPosition.position}
            localEditBillPosition = {...localEditBillPosition, position: localEditBillPosition.position+1}
            setCurrentPostition(localEditBillPosition.position+1);
            setBillPosition([
                ...currentArray
                    .map(x => x.id === changedObject!.id ? changedObject : x.id === localEditBillPosition.id ? localEditBillPosition : x)
            ])
        }

    }


    const handleRemoveMe = () => {
        setBillPosition([
            ...billPositionArray
                .filter(x => x.id !== editBillPosition.id)
                .map(x => x.position > editBillPosition.position ? {...x, position: x.position-1 } : x)
        ])
    }


    const resetChangedValues = () => {
        let foundBillPartner = billPartnerArray.find(x => x.Partner === editBillPosition.Partner);
        let foundService = serviceArray.find(x => x.id === editBillPosition.Service);
        let localEditObject = {...editBillPosition}

        if (foundBillPartner !== undefined && foundService !== undefined) {
            localEditObject = {
                ...localEditObject,
                timeFactor: foundService.timeFactor,
                price_per_hour: foundBillPartner.selling_price_bill,
                price: 0,
                title: foundService.title,
                wasChangedPricePerHour: false,
                wasChangedTimeFactor: false,
                wasChangedTitle: false,
                wasChangedPrice: false
            }
            setEditBillPosition({...localEditObject});
        }
    }

    
    return(
        <>
            <Dialog open={isOpenEditDescription} onClose={() => setIsOpenEditDescription(false)} maxWidth="md" fullWidth> 
                <DialogTitle>Erweiterte Einstellungen</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}/>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Partner"
                                attr="Partner"
                                object={(editBillPosition.Partner === null ? {...editBillPosition, Partner: -1} : editBillPosition)}
                                setObject={
                                    (localBillPos:IBillPosition) => localBillPos.Partner === -1 
                                    ? setEditBillPosition({...localBillPos, Partner: 2})
                                    : setEditBillPosition(localBillPos)
                                }
                                type="select"
                                size="small"
                                required
                                disabled={billObject.BillState > 2}
                                null
                            >
                                {
                                    billPartnerArray.map(billPartner => {
                                        let currentParter = partnerArray.find(x => x.id === billPartner.Partner);

                                        if (currentParter === undefined) {
                                            return(
                                                <MenuItem key={`partner-null`} value={-1}>
                                                    <i>Kein Partner ausgewählt</i>
                                                </MenuItem>
                                            )

                                        }
                                        else{
                                            return(
                                                <MenuItem key={`partner-${currentParter.id}`} value={currentParter.id}>
                                                    {currentParter.Contact_Person.Company?.company_name}
                                                </MenuItem>
                                            )

                                        }
                                    })
                                }
                            </CustomeTextField>
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Stundenpreis"
                                attr="price_per_hour"
                                object={editBillPosition}
                                setObject={(editedObject:IBillPosition) => setEditBillPosition({
                                    ...editedObject,
                                    wasChangedPricePerHour: true
                                })}
                                type="number"
                                size="small"
                                required
                                disabled={billObject.BillState > 20}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Beschreibung"
                                attr="description"
                                object={editBillPosition}
                                setObject={setEditBillPosition}
                                type="string"
                                size="small"
                                required
                                disabled={billObject.BillState > 20}
                                rows={10}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpenEditDescription(false)}>Okay</Button>
                </DialogActions>
            </Dialog>


            <TableRow sx={{backgroundColor: (hasWarning) ? "#ffffe7" : "#f8f8f8"}}>
                <TableCell>
                    <Collapse in={editBillPosition.wasChangedPrice}>
                        <span title="Der Preis wurde händisch angepasst!">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>
                    <Collapse in={editBillPosition.wasChangedTitle}>
                        <span title="Der Titel wurde händisch angepasst!">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>
                    <Collapse in={hasWarningPartner}>
                        <span title="Der Parnter kann nicht bestimmt werden">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>

                    <Collapse in={editBillPosition.wasChangedPricePerHour || editBillPosition.wasChangedTimeFactor}>
                        <span title="Der Zeitfaktor, der Stundenpreis und/oder der Titel wurden händisch angepasst!">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>
                </TableCell>

                <TableCell sx={{p: 0}}>{editBillPosition.articelNumber}</TableCell>


                {/*
                <TableCell>
                    <CustomeTextField
                        label="Partner"
                        attr="Partner"
                        object={editBillPosition}
                        setObject={setEditBillPosition}
                        type="select"
                        size="small"
                        required
                        disabled={billObject.BillState > 20}
                    >
                        {
                            partnerArray
                                .filter(x => billPartnerArray.map(x => x.Partner).indexOf(x.id) > -1)
                                .map(x =>
                                    <MenuItem key={`partner-${x.id}`} value={x.id}>
                                        {x.Contact_Person.Company?.company_name}
                                    </MenuItem>
                                )
                        }
                    </CustomeTextField>
                </TableCell>
                 */}



                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Position"
                        attr="title"
                        object={editBillPosition}
                        setObject={(editedObject:IBillPosition) => setEditBillPosition({
                            ...editedObject,
                            wasChangedTitle: true
                        })}
                        type="string"
                        size="small"
                        required
                        disabled={billObject.BillState > 20}
                        //rows={3}
                    />
                </TableCell>


                <TableCell sx={{p: 0}}>
                    <Collapse in={billObject.BillState <= 20}>
                        <Box sx={{display: "flex"}}>
                            {/*
                            <IconButton title="Position nach oben" onClick={handleUp} size="small" disabled={currentPostition === 1}><ArrowUpward/></IconButton>
                            <IconButton title="Position nach unten" onClick={handleDown} size="small" disabled={getIsMax()}><ArrowDownward/></IconButton>
                             */}

                        </Box>

                            <IconButton title="Alle Werte zurücksetzen" onClick={resetChangedValues} size="small" disabled={editBillPosition.wasChangedPricePerHour === false && editBillPosition.wasChangedTimeFactor === false && editBillPosition.wasChangedTitle === false && editBillPosition.wasChangedPrice === false}><RestartAlt/></IconButton>
                            <IconButton title="Beschreibungstext anpassen" onClick={() => setIsOpenEditDescription(true)} size="small"><Edit/></IconButton>

                    </Collapse>
                </TableCell>


                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Zeitfaktor"
                        attr="timeFactor"
                        object={editBillPosition}
                        setObject={(editedObject:IBillPosition) => setEditBillPosition({
                            ...editedObject,
                            wasChangedTimeFactor: true
                        })}
                        type="number"
                        size="small"
                        required
                        disabled={billObject.BillState > 20}
                    />
                </TableCell>

                {/*
                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Stundenpreis"
                        attr="price_per_hour"
                        object={editBillPosition}
                        setObject={(editedObject:IBillPosition) => setEditBillPosition({
                            ...editedObject,
                            wasChangedPricePerHour: true
                        })}
                        type="number"
                        size="small"
                        required
                        disabled={billObject.BillState > 20}
                    />
                </TableCell>
                 */}



                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Einheiten"
                        attr="units"
                        object={editBillPosition}
                        setObject={setEditBillPosition}
                        type="number"
                        size="small"
                        required
                        disabled={billObject.BillState > 20}
                    />
                </TableCell>


                <TableCell sx={{p: 0}}>{unitArray.find(x => x.id === editBillPosition.Unit)?.unit}</TableCell>

                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Preis"
                        attr="price"
                        object={ {...editBillPosition, price: calcPrice(false, false)} }
                        setObject={(editedObject:IBillPosition) => setEditBillPosition({
                            ...editedObject,
                            wasChangedPrice: true
                        })}
                        type="number"
                        size="small"
                        required
                        disabled={billObject.BillState > 20}
                    />
                </TableCell>

                {/*<TableCell>{calcPrice()}</TableCell>*/}

                <TableCell sx={{p: 0}}>{calcTotalPrice()}</TableCell>

                <TableCell sx={{p: 0}}>
                    <Collapse in={billObject.BillState <= 20}>
                        <Box sx={{display: "flex"}}>
                            <IconButton size="small" onClick={handleRemoveMe}><Delete/></IconButton>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}
