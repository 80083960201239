import React, { useRef, useState } from "react";
import { IHandoverApplicationDocument } from "../../../interfaces/IHandoverApplicationDocument";
import { PDFViewer } from "../../core/PDFViewer";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import ReactSignatureCanvas from "react-signature-canvas";
import { CustomeTextField } from "../../core/CustomeTextField";
import { ITemplate } from "../../../interfaces/ITemplate";
import { IHandoverApplicationDocumentAnswers } from "../../../interfaces/IHandoverApplicationDocumentAnswers";
import { HandoverApplicationDocumentAnswer } from "./HandoverApplicationDocumentAnswer";
import { uploadFetch } from "../../../hooks/useFetch";
import { IOfferApplicationDocument } from "../../../interfaces/IOfferApplicationDocument";


interface IProps {
    currentApplicationDocument: IHandoverApplicationDocument;
    setCurrentApplicationDocument: Function;
    templateObject: ITemplate | undefined;
    setIsOpenSignedField: Function;
}



export const HandoverApplicationDocumentSigned: React.FC<IProps> = ({ currentApplicationDocument, setCurrentApplicationDocument, templateObject, setIsOpenSignedField }) => {
    const refPartner = useRef<ReactSignatureCanvas | null>(null);
    const refCustomer = useRef<ReactSignatureCanvas | null>(null);
    //
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    //
    const [answerArray, setAnswerArray] = useState<IHandoverApplicationDocumentAnswers[]>([]);


    const handleAfterSave = (res: IHandoverApplicationDocument) => {
        //setIsOpenSignedField(false);
        //setIsOpenSignedField(false);
        setCurrentApplicationDocument({
            ...res
        } as IHandoverApplicationDocument)
        setIsOpenSignedField(false);
    }



    const handleSave = () => {
        if (refPartner !== undefined && refCustomer !== undefined) {
            let base64StringPartner = refPartner.current?.toDataURL();
            let base64StringCustomer = refCustomer.current?.toDataURL();

            if (base64StringPartner !== undefined && base64StringCustomer !== undefined) {
                let uploadObject: IHandoverApplicationDocument = {
                    ...currentApplicationDocument,
                    //HandoverProtocolPartner: currentApplicationDocument.HandoverProtocolPartner,
                    HandoverApplicationDocumentAnswerArray: answerArray,
                    Document_Field_Partner: {
                        file_name: "signed_partner.png",
                        data: base64StringPartner.split(",")[1],
                        id: -1,
                        MIMEtype: {id: 2, MIMEtype: "image/png", file_ending: "png"}
                    },
                    Document_Field_Customer: {
                        file_name: "signed_customer.png",
                        data: base64StringCustomer.split(",")[1],
                        id: -1,
                        MIMEtype: {id: 2, MIMEtype: "image/png", file_ending: "png"}
                    }
                }
                uploadFetch(`/handoverprotocol/applications/document/`, 
                    true, 
                    uploadObject, 
                    handleAfterSave, 
                    setWasSuccessfully, () => {}, 
                    setIsLoading
                )
            }
        }

    }


    

    return (
        <>

            <Typography variant="h4">
                Angebot
                <Button disabled={isLoading} variant="contained" onClick={handleSave} sx={{float: "right"}}>Speichern</Button>
            </Typography>

            {(isLoading) && <LinearProgress sx={{m: 2}}/>}


            <Box sx={{mt: 5}}/>
            <PDFViewer documentObject={currentApplicationDocument.Document_Data} />

            <Box sx={{ mt: 15 }} />
            {(templateObject !== undefined) &&
                <HandoverApplicationDocumentAnswer
                    templateObject={templateObject}
                    applicationDocument={currentApplicationDocument}
                    answerArray={answerArray}
                    setAnswerArray={setAnswerArray}
                />
            }

            <Box sx={{ mt: 10 }} />
            <CustomeTextField
                label="Anmerkungen"
                attr="note"
                object={currentApplicationDocument}
                setObject={setCurrentApplicationDocument}
                type="string"
                rows={10}
            />

            <Box sx={{ float: "right", mt: 5 }}>
                <Typography>Unterschrift Handwerkspartner</Typography>
                <ReactSignatureCanvas
                    penColor='black'
                    backgroundColor='rgba(238,238,238,1)'
                    canvasProps={{ width: 800, height: 300, className: 'sigCanvas' }}
                    ref={refPartner}
                />


                <Box sx={{ mt: 10 }} />
                <Typography>Unterschrift Auftragnehmer</Typography>
                <ReactSignatureCanvas
                    penColor='black'
                    backgroundColor='rgba(238,238,238,1)'
                    canvasProps={{ width: 800, height: 300, className: 'sigCanvas' }}
                    ref={refCustomer}
                />
            </Box>

        </>
    )
}