import React, { useEffect } from "react";
import { IOfferMaterialPosition } from "../../../interfaces/IOfferMaterialPosition";
import { IMaterial } from "../../../interfaces/IMaterial";
import { IMaterialCategory } from "../../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../../interfaces/IMaterialSubcategory";
import { Box, Grid, MenuItem, SxProps, Theme, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { getTmpId } from "../../core/generic_helpders";
import { IUnit } from "../../../interfaces/IUnit";


interface IProps {
    offerId: number;
    currentMaterialPosition: IOfferMaterialPosition;
    setCurrentMaterialPosition: Function;
    unitArray: IUnit[];
    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
    sx?: SxProps<Theme>;
}



export const getEmptyOfferMaterialPosition = (offerId: number, materialPosArray: IOfferMaterialPosition[]) => {
    let newObject: IOfferMaterialPosition = {
        id: getTmpId(materialPosArray),
        description: "Boden-/Wandfliesen besteht aus ",
        Material: null,
        MaterialCategory: 1,
        MaterialSubcategory: 1,
        Offer: offerId,
        Partner: null,
        position: 1,
        title: "Boden-/Wandfliesen",
        Unit: 5,
        units: 1,
        price_per_unit: 1
    }
    return newObject;
}

export const OfferMaterialPositionEdit: React.FC<IProps> = ({
    currentMaterialPosition, setCurrentMaterialPosition,
    unitArray, materialArray, materialCategoryArray, materialSubcategoryArray,
    sx
}) => {


    useEffect(() => {
        console.log("----");
        console.log(currentMaterialPosition)
    }, [currentMaterialPosition])




    return (
        <Box sx={sx}>




            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CustomeTextField
                        label="Kategorie"
                        object={currentMaterialPosition}
                        setObject={(x: IOfferMaterialPosition) => {
                            let arrayOfSubcategory = materialSubcategoryArray.filter(y => y.MaterialCategory === x.MaterialCategory);
                            let materialSubcategory = 1
                            let title = ""
                            let description = ""

                            if (arrayOfSubcategory.length > 0) {
                                materialSubcategory = arrayOfSubcategory[0].id;
                                title = arrayOfSubcategory[0].materialSubcategory;
                                description = `${arrayOfSubcategory[0].materialSubcategory} besteht aus `;
                            }

                            setCurrentMaterialPosition({
                                ...x,
                                MaterialSubcategory: materialSubcategory,
                                Material: null,
                                title: title,
                                description: description
                            } as IOfferMaterialPosition)
                        }


                        }
                        attr="MaterialCategory"
                        type="select"
                    >
                        {materialCategoryArray.map(x =>
                            <MenuItem key={`menucategiry-${x.id}`} value={x.id}>
                                {x.materialCategory}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item xs={6}>
                    <CustomeTextField
                        label="Unterkategorie"
                        object={currentMaterialPosition}
                        setObject={(x: IOfferMaterialPosition) =>
                            setCurrentMaterialPosition({
                                ...x,
                                Material: null,
                                title: `${materialSubcategoryArray.find(y => y.id === x.MaterialSubcategory)?.materialSubcategory}`,
                                description: `${materialSubcategoryArray.find(y => y.id === x.MaterialSubcategory)?.materialSubcategory} besteht aus `,
                            } as IOfferMaterialPosition)
                        }
                        attr="MaterialSubcategory"
                        type="select"
                    >
                        {materialSubcategoryArray.filter(x => x.MaterialCategory === currentMaterialPosition.MaterialCategory).map(x =>
                            <MenuItem key={`menusubcategiry-${x.id}`} value={x.id}>
                                {x.materialSubcategory}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item xs={12}>
                    <CustomeTextField
                        label="Material (optional)"
                        object={currentMaterialPosition}
                        setObject={(x: IOfferMaterialPosition) => {
                            let foundMaterial = materialArray.find(y => y.id === x.Material)

                            if (foundMaterial !== undefined) {
                                setCurrentMaterialPosition({
                                    ...x,
                                    title: `${materialSubcategoryArray.find(y => y.id === x.MaterialSubcategory)?.materialSubcategory}: ${foundMaterial.title}`,
                                    description: `${materialArray.find(y => y.id === x.Material)?.description}`,
                                    Unit: foundMaterial.Unit,
                                    price_per_unit: foundMaterial.retail_price

                                } as IOfferMaterialPosition)
                            }
                        }}
                        attr="Material"
                        type="select"
                        null
                    >
                        {materialArray.filter(x => x.MaterialCategory === currentMaterialPosition.MaterialCategory && x.MaterialSubcategory === currentMaterialPosition.MaterialSubcategory).map(x =>
                            <MenuItem key={`materialpos-material-${x.id}`} value={x.id}>
                                {x.articelNumber} {x.title}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
            </Grid>


            <Box sx={{ mt: 5 }} />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography>
                        {(currentMaterialPosition.Material === null)
                            ? `${materialCategoryArray.find(y => y.id === currentMaterialPosition.MaterialCategory)?.materialCategoryNumber}${materialSubcategoryArray.find(y => y.id === currentMaterialPosition.MaterialSubcategory)?.materialSubcategoryNumber}000`
                            : `${materialArray.find(y => y.id === currentMaterialPosition.Material)?.articelNumber}`
                        }
                    </Typography>

                </Grid>
                <Grid item xs={8}>
                    <CustomeTextField
                        label="Bezeichnung"
                        object={currentMaterialPosition}
                        setObject={setCurrentMaterialPosition}
                        attr="title"
                        type="string"
                        error={currentMaterialPosition.title === ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomeTextField
                        label="Beschreibung"
                        object={currentMaterialPosition}
                        setObject={setCurrentMaterialPosition}
                        attr="description"
                        type="string"
                        rows={3}
                    />
                </Grid>
            </Grid>



            <Box sx={{ mt: 5 }} />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <CustomeTextField
                        label="Einheiten"
                        object={currentMaterialPosition}
                        setObject={setCurrentMaterialPosition}
                        attr="units"
                        type="number"
                    />
                </Grid>
                <Grid item xs={2}>
                    <CustomeTextField
                        label="Einheit"
                        object={currentMaterialPosition}
                        setObject={setCurrentMaterialPosition}
                        attr="Unit"
                        type="select"
                    >
                        {unitArray.map(unit =>
                            <MenuItem key={`materialpos-unit-${unit.id}`} value={unit.id}>
                                {unit.unit}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item xs={2}>
                    <CustomeTextField
                        label="PpE"
                        object={currentMaterialPosition}
                        setObject={setCurrentMaterialPosition}
                        attr="price_per_unit"
                        type="euro"
                    />
                </Grid>
                <Grid item xs={5}>
                    <Typography sx={{float: "right"}}>
                        {(currentMaterialPosition.units*currentMaterialPosition.price_per_unit).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}