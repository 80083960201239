import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TableRow } from "@mui/material";
import { Delete, Create  } from "@mui/icons-material";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";
import { uploadFetch } from "../../../hooks/useFetch";
import { IProfile } from "../../../interfaces/IUser";




interface IProps {
    worker: IProfile;
    workerArray:IProfile[];
    setWorkerArray: Function;
    setUpdateParticipant: Function;
}


export const WorkerTableRow: React.FC<IProps> = ({
  worker,
  workerArray,
  setWorkerArray,
  setUpdateParticipant
}) => {
  const [workers, setWorkers] = useState<IProfile | undefined>(
    workerArray.find(x => x.id === worker.id)
  );

  //new Feature Edit Customer
  //const [isOpenEditWorker, setIsOpenEditWorker] = useState(false);
  //const [editWorker, setEditWorker] = useState(workers || getEmptyWorker());

  const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 
  //const [wasSuccessfully,setWasSuccessfully] = useState(true);
  //const [isLoadgin, setIsLoading] = useState(false);

/*
  const handleEditCustomer = () =>{
    setIsOpenEditWorker(true)

  }

  const handleAfterSave = (savedObject:IUser) => {
    setWorkers(savedObject)
    setUpdateParticipant(savedObject, "update")
    setIsOpenEditWorker(false)
}

const handleSave = () => {
    let uploadObject = {
        ...editWorker,
    } as IUser

    setWasSuccessfully(true);
        uploadFetch(`/user/${editWorker?.id}`,false,uploadObject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
}
*/

  const handleRemoveParticipant= () => {
    setWorkerArray(worker?.id, "remove")
  };

  

  if (!workers) {
      return (
          <TableRow>
              <TableCell colSpan={6}><i>Mitarbeiter nicht bestimmen</i></TableCell>
          </TableRow>
      );
  } else {
      return (
        <>
          <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>{`${workers.id}`}</TableCell>
              <TableCell>{`${workers.User.first_name} ${workers.User.last_name}`}</TableCell>
              <TableCell>{workers.mobile_number}</TableCell>
              <TableCell>{workers.User.email}</TableCell>
              <TableCell>
                 {/*     <IconButton size="small" onClick={handleEditCustomer}>
                      <Create />
                    </IconButton>*/}
                    <IconButton size="small" onClick={handleRemoveParticipant}>
                        <Delete />
                    </IconButton> 
              </TableCell>
          </TableRow>
          <CustomSnackbar feedback={feedback} setFeedback={setFeedback} />
           {/*  <Dialog open={isOpenEditWorker} onClose={() => setIsOpenEditWorker(false)} maxWidth="md" fullWidth>
            <DialogTitle>Mitarbeiter Aktualisieren</DialogTitle>
            <DialogContent>
               <WorkerEdit
                 key={`editWorker${workers.id}`}
                 workerObject={editWorker}
                 setWorkerObject={setEditWorker}
               />
            </DialogContent>
            <DialogActions>
                <Button  variant="outlined" onClick={() => setIsOpenEditWorker(false)}>Abbruch</Button>
                <Button variant="contained" onClick={handleSave}>Aktualisieren</Button>
            </DialogActions>
      </Dialog> */}

          </>
      );
  }
};