import React, { useEffect, useState } from "react";
import { IPermission, IPermissionRelation } from "../../interfaces/IPermission";
import { Checkbox, TableCell, TableRow } from "@mui/material";

interface IProps {
    permissionObject: IPermission;
    permissionArray: IPermission[];
    setPermissionArray: Function;
    userIsAdmin: boolean;
}


const permissionRelation:IPermissionRelation[] = [
    {
        app_parent: "project", app_childs: [
            { app_child: "user", can_view: true },
            { app_child: "company", can_view: true },
            { app_child: "address", can_view: true }
        ]
    }
]



export const PermissionRow:React.FC<IProps> = ({permissionObject, permissionArray, setPermissionArray, userIsAdmin}) => {
    const [editPermission,setEditPermission] = useState(permissionObject);
    const [hasFullAccess,setFullAccess] = useState(permissionObject.can_add && permissionObject.can_change && permissionObject.can_delete && permissionObject.can_view);


    useEffect(() => {
        setPermissionArray([
            ...permissionArray.map(x => x.app === editPermission.app ? editPermission : x)
        ])
    },[editPermission])


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.checked)
        if (event.target.name === "fullaccess") {
            if (event.target.checked) {
                setEditPermission({
                    ...editPermission,
                    can_add: true,
                    can_change: true,
                    can_delete: true,
                    can_view: true
                } as IPermission)
            }
            setFullAccess(event.target.checked)

        } else {
            let testObject:any = {...editPermission};
            testObject[event.target.name] = event.target.checked;
            setEditPermission(testObject);
        }
      };

    return(
        <>
            <TableRow>
                <TableCell>{editPermission.app_de} </TableCell>
                <TableCell>
                    <Checkbox
                        name="fullaccess"
                        checked={hasFullAccess || userIsAdmin}
                        onChange={handleChange}
                        disabled={userIsAdmin}
                    />
                </TableCell>
                <TableCell>
                    <Checkbox
                        name="can_view"
                        checked={editPermission.can_view || userIsAdmin}
                        onChange={handleChange}
                        disabled={hasFullAccess || userIsAdmin}
                    />
                </TableCell>
                <TableCell>
                    <Checkbox
                        name="can_change"
                        checked={editPermission.can_change || userIsAdmin}
                        onChange={handleChange}
                        disabled={hasFullAccess || userIsAdmin}
                    />
                </TableCell>
                <TableCell>
                    <Checkbox
                        name="can_add"
                        checked={editPermission.can_add || userIsAdmin}
                        onChange={handleChange}
                        disabled={hasFullAccess || userIsAdmin}
                    />
                </TableCell>
                <TableCell>
                    <Checkbox
                        name="can_delete"
                        checked={editPermission.can_delete || userIsAdmin}
                        onChange={handleChange}
                        disabled={hasFullAccess || userIsAdmin}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}