import React, { useState } from "react";
import { IHandoverProtocol } from "../../interfaces/IHandoverProtocol";
import { IHandoverProtocolPartner } from "../../interfaces/IHandoverProtocolPartner";
import { IPartner } from "../../interfaces/IPartner";
import { Box, Button, Grid, Table, TableCell, TableHead, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IOffer } from "../../interfaces/IOffer";
import { HandoverProtocolAllocation } from "./Childs/HandoverProtocolAllocation";
import { IOfferState } from "../../interfaces/IOfferState";
import { IOfferType } from "../../interfaces/IOfferType";
import { HandoverPartnerRow } from "./HandoverPartnerRow";
import { IUnit } from "../../interfaces/IUnit";
import { ICustomer } from "../../interfaces/ICustomer";
import { IProject } from "../../interfaces/IProject";
import { ITemplate } from "../../interfaces/ITemplate";


interface IProps {
    projectObject: IProject;
    handoverProtocolObject: IHandoverProtocol;
    setHandoverProtocolObject: Function;
    partnerArray: IPartner[];
    unitArray: IUnit[];
    offerArray: IOffer[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];
    customerArray: ICustomer[];
    templateArray: ITemplate[];
}


export const HandoverProtocolEdit:React.FC<IProps> = ({
    projectObject,
    handoverProtocolObject, setHandoverProtocolObject,
    partnerArray, unitArray,
    offerArray, offerStateArray, offerTypeArray, customerArray, templateArray
}) => {
    const [editProtocol,setEditProtocol] = useState(handoverProtocolObject);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={8}>
                    <Typography variant="h6">{editProtocol.title}</Typography>
                    {/*
                    <CustomeTextField
                        label="Titel"
                        attr="title"
                        type="string"
                        object={editProtocol}
                        setObject={setEditProtocol}
                        required
                    />
                    */}

                </Grid>
                <Grid item sm={4}>
                    <HandoverProtocolAllocation
                        handoverProtocolObject={editProtocol}
                        setHandoverProtocolObject={setEditProtocol}
                        handoverPartnerArray={handoverProtocolObject.HandoverProtocolPartnerArray}
                        offerArray={offerArray}
                        partnerArray={partnerArray}
                        unitArray={unitArray}
                        offerStateArray={offerStateArray}
                        offerTypeArray={offerTypeArray}
                        
                    />

                </Grid>
            </Grid>


            <Box sx={{mt: 5}}/>
            <Table>
                <TableHead>
                    <TableCell>Partner</TableCell>
                    <TableCell sx={{width: 200}}>Aktionen</TableCell>
                </TableHead>
                {editProtocol.HandoverProtocolPartnerArray.map(x => 
                    <HandoverPartnerRow
                        key={`partner-edit-${x.id}`}
                        projectObject={projectObject}
                        handoverPartnerObject={x}
                        handoverProtocolObject={editProtocol}
                        setHandoverProtocolObject={setEditProtocol}
                        partnerArray={partnerArray}
                        unitArray={unitArray}
                        customerArray={customerArray}
                        templateArray={templateArray}
                    />
                )}
            </Table>
        </>
    )
}