import React, { useState } from "react";
import { IHandoverProtocol } from "../../interfaces/IHandoverProtocol";
import { IProject } from "../../interfaces/IProject";
import { Button, Dialog, DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IPartner } from "../../interfaces/IPartner";
import { HandoverProtocolRow } from "./HandoverProtocolRow";
import { IOffer } from "../../interfaces/IOffer";
import { IOfferState } from "../../interfaces/IOfferState";
import { IOfferType } from "../../interfaces/IOfferType";
import { uploadFetch, useFetch } from "../../hooks/useFetch";
import { CustomCircularProgress } from "../core/CustomCircularProgress";
import { IUnit } from "../../interfaces/IUnit";
import { ICustomer } from "../../interfaces/ICustomer";
import { ITemplate } from "../../interfaces/ITemplate";


interface IProps {
    handoverProtocolArray: IHandoverProtocol[];
    setHandoverProtocolArray: Function;
    projectObject: IProject;
    setProjectObject: Function;
    partnerArray: IPartner[];
    unitArray: IUnit[];
    offerArray: IOffer[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];
    customerArray: ICustomer[];
    templateArray: ITemplate[];
}


export const getEmptyHandover = (project:IProject) => {
    return {
        id: -1,
        Project: project.id,
        create_at: new Date().toLocaleDateString(),
        title: "",
        HandoverProtocolPartnerArray: []

    } as IHandoverProtocol
}


export const HandoverProtocolTable:React.FC<IProps> = ({
    handoverProtocolArray,setHandoverProtocolArray,
    projectObject,setProjectObject,
    partnerArray, unitArray,
    offerArray, offerStateArray, offerTypeArray, customerArray, templateArray
}) => {
    const [isOpenNew, setIsOpenNew] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    //
    const [newHandoverProtocol,setNewHandoverProtocol] = useState(getEmptyHandover(projectObject));

    const handleNewHandoverProtocol = () => {
        setNewHandoverProtocol(getEmptyHandover(projectObject));
        setIsOpenNew(true);
    }


    const handleAfterSave = (res:IHandoverProtocol) => {
        setHandoverProtocolArray([
            res,
            ...handoverProtocolArray

        ])
        setIsOpenNew(false);
    }

    const handleSaveNew = () => {
        uploadFetch("/handoverprotocol/",true,newHandoverProtocol,handleAfterSave,() => {},() => {},setIsLoading)
        handleAfterSave(newHandoverProtocol);
    }


    return(
        <>
            <Dialog open={isLoading}>
                <DialogContent>
                    <CustomCircularProgress/>
                </DialogContent>
            </Dialog>

            <Dialog open={isOpenNew} onClose={() => setIsOpenNew(true)}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Titel"
                                attr="title"
                                type="string"
                                object={newHandoverProtocol}
                                setObject={setNewHandoverProtocol}
                                required
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenNew(false)}>Abbruch</Button>
                    <Button variant="contained" disabled={newHandoverProtocol.title === ""} onClick={handleSaveNew}>Anlegen</Button>
                </DialogActions>
            </Dialog>


            <Typography variant="h5">
                Übergabeprotokolle
            </Typography>
            <Button onClick={handleNewHandoverProtocol} sx={{float: "right"}} variant="outlined">Neues Übergabeprotokoll</Button>


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 100}}>Nr.</TableCell>
                        <TableCell sx={{width: 200}}>Erstellt</TableCell>
                        <TableCell>Titel</TableCell>
                        <TableCell sx={{width: 100}}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {handoverProtocolArray.map(x =>
                        <HandoverProtocolRow
                            key={`handover-${x.id}`}
                            projectObject={projectObject}
                            handoverProtocolObject={x}
                            handoverProtocolArray={handoverProtocolArray}
                            setHandoverProtocolArray={setHandoverProtocolArray}
                            partnerArray={partnerArray}
                            unitArray={unitArray}
                            offerArray={offerArray}
                            offerStateArray={offerStateArray}
                            offerTypeArray={offerTypeArray}
                            customerArray={customerArray}
                            templateArray={templateArray}
                        />
                    )}
                </TableBody>
            </Table>
        </>
    )
}