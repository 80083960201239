import React, { useEffect, useState } from "react";
import { IHandoverProtocol } from "../../interfaces/IHandoverProtocol";
import { IPartner } from "../../interfaces/IPartner";
import { Button, Dialog, DialogActions, DialogContent, IconButton, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { IHandoverProtocolPartner } from "../../interfaces/IHandoverProtocolPartner";
import { HandoverProtocolEdit } from "./HandoverProtocolEdit";
import { IOffer } from "../../interfaces/IOffer";
import { IOfferState } from "../../interfaces/IOfferState";
import { IOfferType } from "../../interfaces/IOfferType";
import { IUnit } from "../../interfaces/IUnit";
import { ICustomer } from "../../interfaces/ICustomer";
import { IProject } from "../../interfaces/IProject";
import { ITemplate } from "../../interfaces/ITemplate";


interface IProps {
    projectObject: IProject;
    handoverProtocolObject: IHandoverProtocol;
    handoverProtocolArray: IHandoverProtocol[];
    setHandoverProtocolArray: Function;
    partnerArray: IPartner[];
    unitArray: IUnit[];
    offerArray: IOffer[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];
    customerArray: ICustomer[];
    templateArray: ITemplate[];
}


export const HandoverProtocolRow:React.FC<IProps> = ({
    projectObject,
    handoverProtocolObject,
    handoverProtocolArray,setHandoverProtocolArray,
    partnerArray, unitArray,
    offerArray, offerStateArray, offerTypeArray, customerArray, templateArray
}) => {
    //const [handoverPartnerArray,setHandoverPartnerArray] = useState<IHandoverProtocolPartner[]>(handoverProtocolObject.HandoverProtocolPartnerArray);
    //
    const [editHandoverProtocolObject,setEditHandoverProtocolObject] = useState(handoverProtocolObject);
    const [isOpenEdit,setIsOpenEdit] = useState(false);

    useEffect(() => {
        setHandoverProtocolArray([
            ...handoverProtocolArray.map(x => x.id === editHandoverProtocolObject.id ? editHandoverProtocolObject : x)
        ])
    },[editHandoverProtocolObject])

    return(
        <>
            <Dialog open={isOpenEdit} onClose={() => setIsOpenEdit(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <HandoverProtocolEdit
                        projectObject={projectObject}
                        handoverProtocolObject={handoverProtocolObject}
                        setHandoverProtocolObject={setEditHandoverProtocolObject}
                        partnerArray={partnerArray}
                        unitArray={unitArray}
                        offerArray={offerArray}
                        offerStateArray={offerStateArray}
                        offerTypeArray={offerTypeArray}
                        customerArray={customerArray}
                        templateArray={templateArray}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenEdit(false)}>Okay</Button>
                </DialogActions>
            </Dialog>



            <TableRow onClick={() => setIsOpenEdit(true)} sx={{cursor: "pointer"}}>
                <TableCell>{handoverProtocolObject.id}</TableCell>
                <TableCell>{handoverProtocolObject.create_at}</TableCell>
                <TableCell>{handoverProtocolObject.title}</TableCell>
                <TableCell>
                    <IconButton size="small" ><Edit/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}