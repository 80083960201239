import React, { useState } from "react";
import { IBill } from "../../interfaces/IBill";
import { IBillPosition } from "../../interfaces/IBillPosition";
import { IProject } from "../../interfaces/IProject";
import { ICustomer } from "../../interfaces/ICustomer";
import { IUnit } from "../../interfaces/IUnit";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IBillState } from "../../interfaces/IBillState";
import { Alert, Box, Button, Collapse, Grid, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { BillPositionTable } from "./Childs/BillPositionTabel";
import { BillPartnerTable } from "./Childs/BillPartnerTable";
import { ITemplate } from "../../interfaces/ITemplate";
import { IPartner } from "../../interfaces/IPartner";
import { IBillPartner } from "../../interfaces/IBillPartner";
import { IBillApplication } from "../../interfaces/IBillApplicatino";
import { uploadFetch } from "../../hooks/useFetch";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


interface IProps {
    editBillObject: IBill;
    setEditBillObject: Function;
    billArray: IBill[];
    setBillArray: Function;
    editBillPositionArray: IBillPosition[];
    setEditBillPositionArray: Function;

    projectObject: IProject;
    customerArray: ICustomer[];
    unitArray: IUnit[];
    templateArray: ITemplate[];
    partnerArray: IPartner[];
    billPartner: IBillPartner[]
    setBillPartner: Function;
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
    billStateArray: IBillState[];
    handleSave: Function;
    handleDownload?: Function;
}



export const BillEditor:React.FC<IProps> = ({
    editBillObject, setEditBillObject, editBillPositionArray, setEditBillPositionArray, partnerArray, billPartner, setBillPartner,
    projectObject, customerArray, unitArray, templateArray, serviceArray, serviceCategoryArray, billStateArray, setBillArray, billArray,
    handleSave
}) => {
    let orgState = editBillObject.BillState;
    //
    const [billEdit, setBillEdit] = useState(editBillObject);
    const [editBillPartner, setEditBillPartner] = useState<IBillPartner[]>(billPartner);
    const [billPositionArray, setBillPositionArray] = useState<IBillPosition[]>(editBillPositionArray);
    const [billApplicationArray, setBillApplicationArray] = useState<IBillApplication[]>();
    //const [partnerIdArray,setPartnerIdArray] = useState<number[]>([]);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);
    const [isLoading,setIsLoading] = useState(false);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    //


    
    const [value, setValue] = React.useState(0);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        console.log(projectObject)
      };

    const isDisabled = () => {
        return orgState > 49
    }

    const updateAfterSave = (updatedBill: IBill) => {
        console.log()
        let foundBill = billArray.find(x => x.id === updatedBill.id);
        if (foundBill === undefined) {
            setBillArray([
                ...billArray,
                updatedBill
            ])
            // Dass die PDF direkt generiert wird.
        }
        else {
            setBillArray([
                ...billArray.map(x => x.id === updatedBill.id ? updatedBill : x )
            ])
        }
        handleSave();
    }
    
    const createAfterSave = (res: IBill) => {
        let updatesObject: IBill = {
            ...res,
            BillPositions: billPositionArray.map(x => { return { ...x, Bill: res.id } }),
            BillPartners: editBillPartner.map(x => { return { ...x, Bill: res.id } }),
        }
        console.log(updatesObject)
        uploadFetch(`/bill/${updatesObject.id}`, false, updatesObject, updateAfterSave, setWasSuccessfullySaved, () => { }, setIsLoading)
    }
    

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
    
        let updatesObject: IBill = {
            ...billEdit,
            BillPositions: billPositionArray.map(x => { return { ...x, Bill: billEdit.id } }),
            BillPartners: editBillPartner.map(x => { return { ...x, Bill: billEdit.id } }),
        }
        //handleSave();
        //handleSave();
        console.log(billEdit.id) 
        if (billEdit.id < 0) {
            updatesObject.BillPositions = []
            updatesObject.BillPartners = []
            uploadFetch("/bill/", true, updatesObject, createAfterSave, setWasSuccessfullySaved, () => { }, setIsLoading)
        }
        else {
            uploadFetch(`/bill/${billEdit.id}`, false, updatesObject, updateAfterSave, setWasSuccessfullySaved, () => { }, setIsLoading)
        }
    }
    

    const handleOpenNew = ()=>{
        setIsOpenAdd(true)
}


    return(
        <>
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="Abs.RE"
                        attr="is_final"
                        type="boolean"
                        object={editBillObject}
                        setObject={setEditBillObject}
                        required
                        disabled={isDisabled()}
                    />
                </Grid>
                <Grid item sm={8}>
                    <CustomeTextField
                        label="Titel"
                        attr="title"
                        type="string"
                        object={editBillObject}
                        setObject={setEditBillObject}
                        required
                        disabled={isDisabled()}
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Angebotstatus"
                        attr="BillState"
                        type="select"
                        object={editBillObject}
                        setObject={setEditBillObject}
                        required
                    >
                        {billStateArray.map(x =>
                            <MenuItem disabled={isDisabled()} key={`billstate-${x.id}`} value={x.id}>{x.billState}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="Rechnungsziel"
                        attr="valid_till"
                        type="date"
                        object={editBillObject}
                        setObject={setEditBillObject}
                        required
                        disabled={isDisabled()}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Gezahlt am"
                        attr="paid_at"
                        type="date"
                        object={editBillObject}
                        setObject={setEditBillObject}
                        disabled={isDisabled()}
                        null
                    />
                </Grid>
            </Grid>
                <Box sx={{ width: '100%' }}>
                    <CustomTabPanel value={value} index={0}>
                        <>
                            <Box sx={{mt: 5}}>
                                <BillPartnerTable
                                    billObject={editBillObject}
                                    partnerArray={partnerArray}
                                    billPartner={editBillPartner}
                                    setBillPartner={setEditBillPartner}
                                />
                            </Box>


                            <Box sx={{mt: 10}}>
                                <BillPositionTable
                                    billObject={editBillObject}
                                    billPositionArray={billPositionArray}
                                    setBillPosition={setBillPositionArray}

                                    billPartnerArray={editBillPartner}
                                    partnerArray={partnerArray}
                                    serviceArray={serviceArray}
                                    serviceCategoryArray={serviceCategoryArray}
                                    isOpenAdd={isOpenAdd}
                                    setIsOpenAdd={setIsOpenAdd}
                                    unitArray={unitArray}
                                />
                            </Box>
                        </>
                    </CustomTabPanel>
                    <Box sx={{width: 140, height: 60, float: "right"}} style={{
                            position: 'sticky',
                            bottom: 30,
                            right: 0,
                            zIndex: 100,
                            padding: 10,
                            background: "white",
                            border: "10px solid white"
                            }} >
                        <Button style={{
                            position: 'absolute',
                            bottom: 1,
                            right: 175}} sx={{float: "right", width: 150}} variant="contained" onClick={handleOpenNew}>Neue Position</Button>
                    
                        <Button style={{
                            position: 'absolute',
                            bottom: 1,
                            right: 5}} sx={{float: "right"}} disabled={isLoading} variant="contained" onClick={handleSubmit}>Speichern</Button></Box>
                    
                    </Box>

                

        </>
    ) 
}