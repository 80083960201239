import React, { useEffect, useState } from "react";
import { IHandoverProtocol } from "../../../interfaces/IHandoverProtocol";
import { IHandoverProtocolPartner } from "../../../interfaces/IHandoverProtocolPartner";
import { IPartner } from "../../../interfaces/IPartner";
import { IOffer } from "../../../interfaces/IOffer";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowParams, deDE } from "@mui/x-data-grid";
import { IOfferState } from "../../../interfaces/IOfferState";
import { IOfferType } from "../../../interfaces/IOfferType";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";


interface IProps {
    isOpenAddOffer: boolean;
    setIsOpenAddOffer: Function;
    handoverProtocolObject: IHandoverProtocol;
    handoverPartnerArray: IHandoverProtocolPartner[];
    setHandoverPartnerArray: Function;
    partnerArray: IPartner[];
    offerArray: IOffer[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];
    offerPosArray: IOfferPosition[];
    setOfferPosArray: Function;
}






export const HandoverAddOffer:React.FC<IProps> = ({
    isOpenAddOffer, setIsOpenAddOffer, 
    handoverProtocolObject,
    handoverPartnerArray,setHandoverPartnerArray,partnerArray, 
    offerArray, offerStateArray, offerTypeArray,
    offerPosArray, setOfferPosArray
}) => {
    const [addOfferList,setAddOfferList] = useState<GridRowId[]>([]);

    /*
    useEffect(() => {
        if (isOpenAddOffer) {
            setAddOfferList([])
        }
    },[isOpenAddOffer])
    */

    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 70 
        },
        { 
            field: 'OfferType', 
            headerName: 'Art', 
            renderCell: (params: GridRenderCellParams<any, number>) => offerTypeArray.find(x => params.value === x.id)?.offerType,
            width: 200
        },
        { 
            field: 'OfferState', 
            headerName: 'Status', 
            renderCell: (params: GridRenderCellParams<any, number>) => offerStateArray.find(x => params.value === x.id)?.offerState,
            width: 150
        },
        { 
            field: 'title', 
            headerName: 'Titel', 
            flex: 1
        },
    ];



    const addPositionFromOffer = () => {
        let localOfferPosition = [...offerPosArray];
        let localHandoverParter = [...handoverPartnerArray];

        addOfferList.map(idOffer => {
            let offer = offerArray.find(x => x.id === idOffer);

            if (offer !== undefined) {
                offer.OfferPositions.map(offerPos => {
                    let testObject = offerPosArray.find(x => x.id === offerPos.id)

                    if (testObject === undefined) {
                        if (handoverPartnerArray.length > 0) {
                            localOfferPosition.push({...offerPos, Partner: handoverPartnerArray[0].Partner})
                        } else {
                            localOfferPosition.push({...offerPos, Partner: null})
                        }
                    }
                })

            }
        })
        setOfferPosArray(localOfferPosition);
        setIsOpenAddOffer(false);
    }


    
    return(
        <>
            <Dialog open={isOpenAddOffer} onClose={() => setIsOpenAddOffer(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={offerArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setAddOfferList([
                                ...newRowSelectionModel
                            ])                       
                        }}
                        rowSelectionModel={addOfferList}
                        columns={columns}
                        //getRowId={(row) => row.idContact}
                        //isRowSelectable={(params: GridRowParams) => props.contactArray.map(x => x.idContact).indexOf(Number(params.row.idContact)) === -1 }
                        //isRowSelectable={(params: GridRowParams) => offerArray.map(x => x.Partner).indexOf(Number(params.id)) === -1 }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenAddOffer(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={addPositionFromOffer}>Übernehmen</Button>
                </DialogActions>
            </Dialog>


        </>
    )
}