import React, { useState } from "react";
import { IHandoverProtocolPartner } from "../../interfaces/IHandoverProtocolPartner";
import { IHandoverProtocol } from "../../interfaces/IHandoverProtocol";
import { IPartner } from "../../interfaces/IPartner";
import { IUnit } from "../../interfaces/IUnit";
import { IHandoverProtocolPosition } from "../../interfaces/IHandoverProtocolPosition";
import { Alert, Box, Button, Collapse, LinearProgress, Table, TableBody, TableCell, TableHead, Typography } from "@mui/material";
import { HandoverPositionRow } from "./Childs/HandoverPositionRow";
import { uploadFetch } from "../../hooks/useFetch";


interface IProps {
    partner: IPartner | undefined;
    handoverProtocolObject: IHandoverProtocol;
    setHandoverProtocolObject: Function;
    editHandoverPartner: IHandoverProtocolPartner;
    setEditHandoverPartner: Function;
    editHandoverPostition: IHandoverProtocolPosition[];
    setEditHandoverPostition: Function;

    partnerArray: IPartner[];
    unitArray: IUnit[];

    setIsOpenEdit: Function;
}


export const HandoverPartnerEdit:React.FC<IProps> = ({
    handoverProtocolObject, setHandoverProtocolObject,
    editHandoverPartner,setEditHandoverPartner,
    editHandoverPostition,setEditHandoverPostition,
    partnerArray,unitArray,

    setIsOpenEdit
}) => {
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoading,setIsLoading] = useState(false);



    const handleAfterSave = (res:IHandoverProtocolPartner) => {
        setHandoverProtocolObject({
            ...handoverProtocolObject,
            HandoverProtocolPartnerArray: [
                ...handoverProtocolObject.HandoverProtocolPartnerArray.map(x => x.id === res.id ? res : x)
            ]
        } as IHandoverProtocol)
        setIsOpenEdit(false);
    }

    const handleSave = () => {
        let uploadObject:IHandoverProtocolPartner = {
            ...editHandoverPartner,
            HandoverProtocolPositionArray: editHandoverPostition
        }
        uploadFetch(`/handoverprotocol/partner/${editHandoverPartner.id}`,false,uploadObject,handleAfterSave,setWasSuccessfully,() => {},setIsLoading)
    }


    return(
        <>
            <Typography variant="h5">
                Übergabeprotokoll
                <Button variant="contained" sx={{float: "right"}} onClick={handleSave} disabled={isLoading}>Speichern</Button>
            </Typography>

            <Box sx={{mt:2}}/>

            <Collapse in={isLoading}>
                <LinearProgress/>
            </Collapse>

            <Collapse in={!wasSuccessfully}>
                <Alert severity="warning">
                    Das Übergabeprotokoll konnte nicht gespeichert werden!
                    <br/>Bitte kontrollieren Sie Ihre Angaben.
                </Alert>
            </Collapse>

            <Box sx={{mt: 5}}/>
            <Typography variant="h6">
                Positionen
                <Button variant="outlined" sx={{float: "right"}}>Hinzufügen</Button>
            </Typography>
            <Table>
                <TableHead>
                    <TableCell sx={{width: 100}}>Art.nr.</TableCell>
                    <TableCell>Bezeichnung</TableCell>
                    <TableCell sx={{width: 100}}>Menge</TableCell>
                    <TableCell sx={{width: 100}}>ME</TableCell>
                    <TableCell sx={{width: 150}}>IST-Menge</TableCell>
                    <TableCell sx={{width: 50}}>Aktionen</TableCell>
                </TableHead>

                <TableBody>
                    {editHandoverPostition.map(x =>
                        <HandoverPositionRow
                            key={`handover-position-${x.id}`}
                            handoverPosition={x}
                            handoverPositionArray={editHandoverPostition}
                            setHandoverPositionArray={setEditHandoverPostition}
                            unitArray={unitArray}
                        />
                    )}
                </TableBody>
            </Table>
        </>
    )
}