import React, { useEffect } from "react";
import { ITemplate } from "../../../interfaces/ITemplate";
import { Card, CardContent, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { IOfferApplicationDocument } from "../../../interfaces/IOfferApplicationDocument";
import { IOfferApplicationDocumentAnswer } from "../../../interfaces/IOfferApplicationDocumentAnswer";
import { OfferSignedPageQuestionRow } from "./OfferSignedPageQuestionRow";


interface IProps {
    offerApplicationDocument: IOfferApplicationDocument;
    templateArray: ITemplate[];
    offerAnswerArray: IOfferApplicationDocumentAnswer[];
    setOfferAnswerArray: Function;
}



export const OfferSignedPageQuestions:React.FC<IProps> = ({offerApplicationDocument, templateArray, offerAnswerArray, setOfferAnswerArray}) => {
    useEffect(() => {
        console.log(templateArray)
        let tmpOfferAnswerArray:IOfferApplicationDocumentAnswer[] = [];

        templateArray.map(template => 
            template.TemplateQuestions.map( (question,idx) => {
                if (question.TemplateAnswers.length > 0) {
                    tmpOfferAnswerArray.push({
                        id: (idx*-1)-1,
                        OfferApplicationDocument: offerApplicationDocument.id,
                        TemplateAnswers: question.TemplateAnswers[0].id,
                        TemplateQuestions: question.id
                    } as IOfferApplicationDocumentAnswer)
                }
            })
        )
        setOfferAnswerArray(tmpOfferAnswerArray);
        console.log("INIT ARRAY")
        console.log(tmpOfferAnswerArray)

    },[])



    useEffect(() => {
        console.log("UPDATE")
        console.log(offerAnswerArray)
    },[offerAnswerArray])



    return(
        <>
        {templateArray.map(template => 
            (template.TemplateQuestions.length > 0) &&
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h6">{template.title}</Typography>
                    <Grid container spacing={2} sx={{mt: 2}}>
                        {offerAnswerArray.map(x =>
                            <OfferSignedPageQuestionRow
                                key={`question-row-${x.id}`}
                                offerAnswerObject={x}
                                template={template}
                                offerAnswerArray={offerAnswerArray}
                                setOfferAnswerArray={setOfferAnswerArray}
                                offerApplicationDocument={offerApplicationDocument}
                                templateArray={templateArray}
                            />
                        )}
                    </Grid>
                </CardContent>
            </Card>
        )}
        </>

    )
} 