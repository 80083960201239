import { useCallback, useRef, useState } from "react";
import { deleteFetch, uploadFetch } from "../../hooks/useFetch";
import { IProjectDocument } from "../../interfaces/IProjectDocument";
import { IProject } from "../../interfaces/IProject";
import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import { IMIMEType } from "../../interfaces/IMIMEType";
import { DocumentRow } from "./DocumentRow";

interface IProps {
  projectObject: IProject;
  setProjectObject: Function;
  mimeTypeArray: IMIMEType[];
  isWorking: boolean;
  setIsWorking: Function;
}

export const DocumemtTable: React.FC<IProps> = (props) => {
  const [enableDownload, setEnableDownload] = useState(true);

  const [loadingText, setLoadingText] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  //
  const inputUpload = useRef<HTMLInputElement | null>(null);

  const showLoading = useCallback((text: string) => {
    setLoadingText(text);
    props.setIsWorking(true);
  }, [props.setIsWorking]);

  const hideLoading = useCallback(() => {
    setLoadingText("");
    props.setIsWorking(false);
  }, [props.setIsWorking]);

  const showAlertPopUp = useCallback((text: string) => {
    setAlertText(text);
    setShowAlert(true);
  }, []);

  const hideAlertPopUp = useCallback(() => {
    setAlertText("");
    setShowAlert(false);
  }, []);

  async function fileToBase64(file: File) {
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
    return String(result_base64).split(",")[1];
  }

  const handleFilesUpload = useCallback(
    async (acceptedFiles: File[]) => {
      showLoading("Dokumente werden hochgeladen...");
      // Erstelle ein Array von Promises für jeden Dateiupload
      if (acceptedFiles.length === 0) {
        return;
      }
      if (props.mimeTypeArray === undefined) {
        return;
      }
      const uploadPromises = acceptedFiles.map((file) => {
        return new Promise<IProjectDocument>(async (resolve, reject) => {
          const mimeType = props.mimeTypeArray.find(
            (y) => y.MIMEtype === file.type
          );
          if (!mimeType) {
            reject(new Error("MIME type not supported"));
            return;
          }

          const data = await fileToBase64(file);
          const document = {
            id: null,
            file_name: file.name,
            MIMEtype: mimeType,
            data,
          };

          uploadFetch(
            "/project/document/",
            true,
            { Document: document, Project: props.projectObject.id, User: 1 },
            (projectDocument: IProjectDocument) => {
              if (projectDocument) {
                resolve(projectDocument);
              } else {
                reject(new Error("Upload failed"));
              }
            }
          );
        });
      });

      // Verwende Promise.all, um auf die Fertigstellung aller Uploads zu warten
      try {
        const results = await Promise.all(uploadPromises);
        let newProjectObject = { ...props.projectObject };
        newProjectObject.Documents = [
          ...props.projectObject.Documents,
          ...results,
        ];
        props.setProjectObject(newProjectObject);
        hideAlertPopUp();
      } catch (error) {
        showAlertPopUp("Upload fehlgeschlagen");
        hideLoading();
        return;
      }
      hideLoading();
    },
    [props.mimeTypeArray, props.projectObject, props.setProjectObject]
  );

  const handleNormalUpload = (files: null | FileList) => {
    if (files !== null) {
      let acceptedFiles: File[] = Array.from(files);
      handleFilesUpload(acceptedFiles);
    }
  };

  const handleUpdate = useCallback(
    (document: IProjectDocument, name: string) => {
      let newDocument = { ...document.Document };
      delete newDocument.data;
      showLoading("Dokument wird aktualisiert...");
      newDocument.file_name = name;
      uploadFetch(
        `/project/document/${document.id}`,
        false,
        { Document: newDocument, Project: props.projectObject.id, User: 1 },
        (projectDocument: IProjectDocument) => {
          let newDocument = { ...document.Document };
          newDocument.file_name = name;
          let newProjectObject = { ...props.projectObject };
          newProjectObject.Documents = newProjectObject.Documents.map((x) => {
            if (x.id === document.id) {
              x.Document.file_name = name;
            }
            return x;
          });
        },
        (succesfull: boolean) => {
          if (!succesfull) {
            showAlertPopUp("Update fehlgeschlagen");
          } else {
            hideLoading();
            hideAlertPopUp();
          }
        }
      );
    },
    [props.projectObject, showAlertPopUp]
  );

  const handleDelete = useCallback(
    async (document: IProjectDocument) => {
      showLoading("Dokument wird gelöscht...");
      const deletePromise = new Promise((resolve, reject) => {
        if (document.id === null) {
          reject(new Error("Document ID is null"));
          return;
        }
        deleteFetch(`/project/document/`, document.id, (deleted: boolean) => {
          if (deleted) {
            resolve(true);
          } else {
            reject(new Error("Delete failed"));
          }
        });
      });

      try {
        const result = await deletePromise;
        if (!result) {
          showAlertPopUp("Löschen fehlgeschlagen");
          hideLoading();
          return;
        }
        let newProjectObject = { ...props.projectObject };
        newProjectObject.Documents = newProjectObject.Documents.filter(
          (x) => x.id !== document.id
        );
        props.setProjectObject(newProjectObject);
        hideLoading();
        hideAlertPopUp();
      } catch (error) {
        showAlertPopUp("Löschen fehlgeschlagen");
        hideLoading();
        return;
      }
    },
    [props.projectObject, props.setProjectObject]
  );

  if (
    props.mimeTypeArray === undefined ||
    props.projectObject.Documents === undefined
  ) {
    return (
      <Typography variant="h5">Fehler beim Laden der Dokumente</Typography>
    );
  }

  return (
    <>
      {showAlert && (
        <Alert severity="error" onClose={hideAlertPopUp}>
          {alertText}
        </Alert>
      )}
      {props.isWorking && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <Typography>{loadingText}</Typography>
          </Grid>
        </Grid>
      )}
      {!props.isWorking && (
        <>
          <input
            hidden
            ref={inputUpload}
            type="file"
            accept={String(
              props.mimeTypeArray.map((x) => x.MIMEtype).join(", ")
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleNormalUpload(event.target.files);
            }}
          />

          <Typography variant={"h5"} sx={{ mb: 2, mt: 0 }}>
            Dokumente
            <IconButton
              title="Daten über Auswähl hochladen"
              onClick={() =>
                inputUpload !== null && inputUpload.current?.click()
              }
              sx={{ float: "right" }}
            >
              <UploadFile />
            </IconButton>
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nr.</TableCell>
                <TableCell>Dateiname</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.projectObject.Documents.map(
                (document: IProjectDocument) => {
                  return (
                    <DocumentRow
                      key={document.id}
                      document={document}
                      handleDelete={handleDelete}
                      updateDocument={handleUpdate}
                      showAlertPopUp={showAlertPopUp}
                      setEnableDownload={setEnableDownload}
                      showLoading={showLoading}
                      hideLoading={hideLoading}
                      hideAlertPopUp={hideAlertPopUp}
                      enableDownload={enableDownload}
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};