import React, { useCallback, useEffect, useRef, useState } from "react";
import { IMaterialDocument } from "../../../interfaces/IMaterialDocument";
import { IMIMEType } from "../../../interfaces/IMIMEType";
import { IMaterialDocumentCategory } from "../../../interfaces/IMaterialDocumentCategory";
import { useDropzone } from "react-dropzone";
import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IDocument } from "../../../interfaces/IDocument";
import { UploadFile } from "@mui/icons-material";
import { MaterialDocumentRow } from "./MaterialDocumentRow";

export interface IProps {
    materialId: number;
    materialDocumentArray: IMaterialDocument[];
    setMaterialDocumentArray: Function;

    mimeTypeArray: IMIMEType[];
    materialDocumentCategory: IMaterialDocumentCategory[];
}


export const MaterialDocumentTable:React.FC<IProps> = ({materialId, materialDocumentArray,setMaterialDocumentArray, mimeTypeArray, materialDocumentCategory}) => {
    const [enableUpload, setEnableUpload] = useState(false);
    //
    const inputUpload = useRef<HTMLInputElement | null>(null);


    async function fileToBase64(file:File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return String(result_base64).split(",")[1];
    }


    const handleFilesUpload = async (acceptedFiles:File[]) => {
        let uploadArray:IMaterialDocument[] = [];
        let minIdOffset = -1;

        if (materialDocumentArray.length > 0) {
            let minIdArray = Math.min(...materialDocumentArray.map(x => x.id));

            if (minIdArray <= minIdOffset) {
                minIdOffset = minIdArray -1;
            }
        }

        let index = 0;

        
        for (let currentFile of acceptedFiles) {
            let fileName:string = String(currentFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(y => y.MIMEtype === currentFile["type"]);
            
            if (mimeType !== undefined) {
                uploadArray.push({

                    id: minIdOffset - index,
                    Document: { id: -1, file_name: fileName, MIMEtype: mimeType, data:  await fileToBase64(currentFile)} as IDocument,
                    isPublic: false,
                    Material: materialId,
                    MaterialDocumentCategory: 1
                } as IMaterialDocument)
                /*
                uploadArray.push({
                    idDocument: minIdOffset - index,
                    FileName: fileName,
                    idMIMEType: mimeType.idMIMEType,
                    Data: await fileToBase64(currentFile),
                    Created_at: new Date().toISOString()
                } as IDocument)
                */
            }
            index = index + 1;
        }
        
        setMaterialDocumentArray([
            ...materialDocumentArray,
            ...uploadArray
        ])

    }


    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }

    const onDrop = useCallback( (acceptedFiles:File[]) => { 
        //setNewFileArray(acceptedFiles);
        //callBackDriver(acceptedFiles);
        //setAddNew(false);
        handleFilesUpload(acceptedFiles);
        setEnableUpload(false);
    }, [materialDocumentArray] );

    const { getRootProps, getInputProps, isDragActive } = useDropzone( { 
        onDrop,
        noClick: true,
    } );


    useEffect(() => {console.log(materialDocumentArray)},[materialDocumentArray])


    const dropZoneTSX = () => {
        return(
            <div>
                <input {...getInputProps()} accept={String(mimeTypeArray.map(x => x.MIMEtype).join(", "))}/>
                <Card>
                    <CardContent>
                        <Typography variant="body2">
                            Dokument(e) hier reinziehen
                        </Typography>
                        Erlaubte Datentypen
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datentyp</TableCell>
                                    <TableCell>Datenendung</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mimeTypeArray.map(x => 
                                    <TableRow>
                                        <TableCell>{x.MIMEtype}</TableCell>
                                        <TableCell>{x.file_ending}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        )
    }


    return(
        <div {...getRootProps()}>
            <input 
             hidden 
             ref={inputUpload} 
             type="file" 
             accept={String(mimeTypeArray.map(x => x.MIMEtype).join(", "))} 
             onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
            />
            <Typography variant="h6">
                Dokumente
                <IconButton 
                    title="Daten über Auswähl hochladen"
                    onClick={() => (inputUpload !== null) && inputUpload.current?.click()} 
                    sx={{float: "right"}}
                >
                    <UploadFile/>
                </IconButton>

                <><br/><Typography variant="caption">(Hier können Sie Ihre Dokumente per <i>Drag & Drop</i> hochladen)</Typography></>
            </Typography>

            {((isDragActive || enableUpload)) && dropZoneTSX()}

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 200}}>Kategorie</TableCell>
                        <TableCell sx={{width: 100}}>Öffentlich</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell sx={{width: 150}}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {materialDocumentArray.map(x =>
                        <MaterialDocumentRow
                            key={`materialdocument-${x.id}`}
                            materialDocumen={x}
                            materialDocumentArray={materialDocumentArray}
                            materialDocumentCategory={materialDocumentCategory}
                            mimeTypeArray={mimeTypeArray}
                            setMaterialDocumentArray={setMaterialDocumentArray}
                        />
                    )}
                </TableBody>
            </Table>
        </div>
    )
}