import React, { useState } from "react";
import { IProject } from "../../interfaces/IProject";
import { IBill } from "../../interfaces/IBill";
import { IUnit } from "../../interfaces/IUnit";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IBillState } from "../../interfaces/IBillState";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { uploadFetch } from "../../hooks/useFetch";
import { BillRow } from "./BillRow";
import { ICustomer } from "../../interfaces/ICustomer";
import { ITemplate } from "../../interfaces/ITemplate";
import { IPartner } from "../../interfaces/IPartner";


interface IProps {
    projectObject: IProject;
    billArray: IBill[] | undefined;
    setBillArray: Function;

    customerArray: ICustomer[];
    unitArray: IUnit[];
    serviceArray: IService[];
    templateArray: ITemplate[];
    partnerArray: IPartner[];
    serviceCategoryArray: IServiceCategory[];
    billStateArray: IBillState[];
}


const getEmptyBill = (projectObject:IProject) => {
    return {
        id: -1,
        title: "",
        Project: projectObject.id,
        BillState: 10,
        BillDocuments: [],
        BillPositions: [],
        BillPartners:[],
        is_final: true,
        margin: 0,
        vat: 0.19,
        paid_at: null,
        valid_till: null
    } as IBill
}



export const BillTable: React.FC<IProps> = ({
    projectObject, billArray, setBillArray, partnerArray,
    customerArray, unitArray, serviceArray, serviceCategoryArray, billStateArray, templateArray
}) => {
    const [newBill,setNewBill] = useState(getEmptyBill(projectObject));
    const [isOpenNew,setIsOpenNew] = useState(false);
    console.log(billStateArray)

    const handleOpenNewBill = () => {
        setNewBill(getEmptyBill(projectObject));
        setIsOpenNew(true);
    }
    const handleAfterSaveNew = (res:IBill) => {
        if (billArray !== undefined) {
            setBillArray([
                res,
                ...billArray
            ])
            // Dass die PDF direkt generiert wird
            setIsOpenNew(false);
        }

    }
    const handleSaveNew = () => {
        uploadFetch("/bill/",true,newBill,handleAfterSaveNew)
    }


    if (billArray === undefined) {
        return(
            <>
                Bitte warten...
            </>
        )
    }
    else {
        return(
            <>
                <Dialog open={isOpenNew} onClose={() => setIsOpenNew(true)}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="Titel"
                                    attr="title"
                                    type="string"
                                    object={newBill}
                                    setObject={setNewBill}
                                    required
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="Status"
                                    attr="BillState"
                                    type="select"
                                    object={newBill}
                                    setObject={setNewBill}
                                    required
                                >
                                    {billStateArray.map(x =>
                                        <MenuItem key={`billstate-${x.id}`} value={x.id}>{x.billState}</MenuItem>
                                    )}
                                </CustomeTextField>
                            </Grid>
                        </Grid>
                    </DialogContent>


                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenNew(false)}>Abbruch</Button>
                        <Button variant="contained" disabled={newBill.title === ""} onClick={handleSaveNew}>Anlegen</Button>
                    </DialogActions>
                </Dialog>



                <Typography variant="h5">
                    Rechnungen
                </Typography>
                <Button onClick={handleOpenNewBill} sx={{float: "right"}} variant="outlined">Neue Rechnung</Button>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: 100}}>Nr.</TableCell>
                            <TableCell sx={{width: 100}}>Abs.-RE</TableCell>
                            <TableCell sx={{width: 100}}>Bis</TableCell>
                            <TableCell sx={{width: 175}}>Status</TableCell>
                            <TableCell sx={{width: "100%"}}>Titel</TableCell>
                            <TableCell sx={{width: 170}}>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {billArray.map(x =>
                            <BillRow
                                key={`bill-${x.id}`}
                                billObject={x}
                                billArray={billArray}
                                setBillArray={setBillArray}
                                billStateArray={billStateArray}
                                projectObject={projectObject}
                                partnerArray={partnerArray}
                                templateArray={templateArray}
                                customerArray={customerArray}
                                serviceArray={serviceArray}
                                serviceCategoryArray={serviceCategoryArray}
                                unitArray={unitArray}
                            />
                        )}
                    </TableBody>
                </Table>                    
            </>
        )
    }
    
}