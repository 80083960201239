import React, { useState } from "react";
import { IMaterialDocument } from "../../../interfaces/IMaterialDocument";
import { IMIMEType } from "../../../interfaces/IMIMEType";
import { IMaterialDocumentCategory } from "../../../interfaces/IMaterialDocumentCategory";
import { IconButton, MenuItem, TableCell, TableRow, TextField } from "@mui/material";
import { Delete, Download, Edit, RestartAlt, Save } from "@mui/icons-material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { IDocument } from "../../../interfaces/IDocument";
import { deleteFetch, uploadFetch } from "../../../hooks/useFetch";

export interface IProps {
    materialDocumen: IMaterialDocument;
    materialDocumentArray: IMaterialDocument[];
    setMaterialDocumentArray: Function;

    mimeTypeArray: IMIMEType[];
    materialDocumentCategory: IMaterialDocumentCategory[];
}



export const MaterialDocumentRow:React.FC<IProps> = ({materialDocumen,materialDocumentArray,setMaterialDocumentArray,mimeTypeArray,materialDocumentCategory})  => {
    const [isEdit,setIsEdit] = useState(materialDocumen.id < 0);
    const [editMaterialDocument,setEditMaterialDocument] = useState(materialDocumen);
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(false);
    const [isLoading,setIsLoading] = useState(false);



    const base64Download = (localDocumentObject:IDocument) => {
        let currentMimeType = mimeTypeArray.find(x => x.id === localDocumentObject.MIMEtype.id)

        if (localDocumentObject.data !== undefined && currentMimeType !== undefined) {
            let byteCharacters = atob(localDocumentObject.data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: currentMimeType.MIMEtype});
            let blobUrl = URL.createObjectURL(blob);

            let a = document.createElement("a");
            a.href = blobUrl;
            a.download = localDocumentObject.file_name;
            a.click();
        }
    }



    const handleAfterSave = (res:IMaterialDocument) => {
        if (materialDocumen.id < 0) {
            setMaterialDocumentArray([
                ...materialDocumentArray.filter(x => x.id !== materialDocumen.id),
                res
            ])
        }
        else {
            setMaterialDocumentArray([
                ...materialDocumentArray.map(x => x.id === res.id ? res : x)
            ])
        }
        setEditMaterialDocument(res);
        setIsEdit(false);
    }

    const handleSave = () => {
        uploadFetch(`/material/document/${editMaterialDocument.id < 0 ? "" : editMaterialDocument.id}`,editMaterialDocument.id < 0,editMaterialDocument,handleAfterSave,setWasSuccessfully,() => {},setIsLoading)
    }

    const handleReset = () => {
        setEditMaterialDocument(materialDocumen);
        setIsEdit(false);
    }

    const handleDownload = () => {
        base64Download(editMaterialDocument.Document);
    }


    const handleRemoveMe = () => {
        if (materialDocumen.id > 0) {
            deleteFetch(`/material/document/`,editMaterialDocument.id,() => {})
        }
        setMaterialDocumentArray([
            ...materialDocumentArray.filter(x => x.id !== materialDocumen.id),
        ])
    }



    if (isLoading) { return <><TableRow><TableCell colSpan={4}>Bitte warten...</TableCell></TableRow></> }
    else if (isEdit) {
        return(
            <>
                <TableRow>
                    <TableCell>
                        <CustomeTextField
                            label="Kategorie"
                            object={editMaterialDocument}
                            setObject={setEditMaterialDocument}
                            attr="MaterialDocumentCategory"
                            type="select"
                        >
                            {materialDocumentCategory.map(x => 
                                <MenuItem key={`materialdocuemntcategory-${x.id}`} value={x.id}>{x.materialDocumentCategory}</MenuItem>
                            )}
                        </CustomeTextField>
                    </TableCell>
                    <TableCell>
                        <CustomeTextField
                            label="Öffentlich"
                            object={editMaterialDocument}
                            setObject={setEditMaterialDocument}
                            attr="isPublic"
                            type="boolean"
                        />
                    </TableCell>
                    <TableCell>
                        <CustomeTextField
                            label="Öffentlich"
                            object={editMaterialDocument.Document}
                            setObject={(doc:IDocument) => setEditMaterialDocument({...editMaterialDocument, Document: doc})}
                            attr="file_name"
                            type="string"
                        />
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={handleSave}><Save/></IconButton>
                        { (materialDocumen.id > 0) && <IconButton onClick={handleReset}><RestartAlt/></IconButton>}
                        <IconButton onClick={handleRemoveMe}><Delete/></IconButton>
                    </TableCell>
                </TableRow>
            </>
        )
    } else {
        return(
            <TableRow>
                <TableCell>{materialDocumentCategory.find(x => x.id === materialDocumen.MaterialDocumentCategory)?.materialDocumentCategory}</TableCell>
                <TableCell>{(materialDocumen.isPublic == true) ? "Ja" : "Nein"}</TableCell>
                <TableCell>{materialDocumen.Document.file_name}</TableCell>
                <TableCell>
                    <IconButton onClick={() => setIsEdit(true)}><Edit/></IconButton>
                    <IconButton onClick={handleDownload}><Download/></IconButton>
                </TableCell>
            </TableRow>
        )
    }
}