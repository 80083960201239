import { IDocument } from "../../interfaces/IDocument";



export const base64Download = (localDocumentObject:IDocument) => {
    if (localDocumentObject.data !== undefined) {
        let currentTyepe =  "text/csv"

        if (localDocumentObject.MIMEtype.id === 1) {
            currentTyepe = "application/pdf"
        }

        console.log(localDocumentObject)
        let byteCharacters = atob(localDocumentObject.data);
        // Each character's code point (charCode) will be the value of the byte. 
        // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
        let byteArray = new Uint8Array(byteNumbers);
        // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
        let blob = new Blob([byteArray], {type: currentTyepe});
        // Erstelle Link zum BLOB
        let blobUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = blobUrl;
        a.download = localDocumentObject.file_name;
        a.click();
    }
}
