import React, { useEffect, useState } from "react";
import { IService } from "../../../interfaces/IService";
import { IServiceCategory } from "../../../interfaces/IServiceCategory";
import { IBillPosition } from "../../../interfaces/IBillPosition";
import {
    Box,
    Button,
    Checkbox,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import { IPartner } from "../../../interfaces/IPartner";
import { CustomeTextField } from "../../core/CustomeTextField";
import { IBillPartner } from "../../../interfaces/IBillPartner";
import { IBill } from "../../../interfaces/IBill";

interface IProps {
    billObject: IBill;
    setIsOpenAdd: Function;
    billPositionArray: IBillPosition[];
    setBillPosition: Function;
    billPartnerArray: IBillPartner[];
    partnerArray: IPartner[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
}

interface IFilter {
    filterIsPaidNursingCareFund: boolean,
    filterCategoryNoServices: boolean
}

export const BillPositionAdd: React.FC<IProps> = ({
    billObject,
    setIsOpenAdd,
    billPositionArray,
    setBillPosition,
    billPartnerArray,
    partnerArray,
    serviceArray,
    serviceCategoryArray
}) => {
    const [selectedPosition, setSelectedPosition] = useState<number[]>([]);
    const [filterObject, setFilterObject] = useState<IFilter>({
        filterCategoryNoServices: true,
        filterIsPaidNursingCareFund: false
    });
    const [currentCategory, setCurrentCategory] = useState(-1);
 
    const [searchTerm, setSearchTerm] = useState("");
    const [tempServiceArray, setTempServiceArray] = useState<IService[]>(serviceArray);

    const handleClose = () => {
        setSelectedPosition([]);
        setCurrentCategory(-1);
        setIsOpenAdd(false);
    }

    const handleChange = (idService: number) => {
        let isUsed = (selectedPosition.indexOf(idService) > -1);

        if (isUsed) {
            setSelectedPosition([
                ...selectedPosition.filter(x => x !== idService)
            ])
        } else {
            setSelectedPosition([
                ...selectedPosition,
                idService
            ])
        }
    }

    const handleAddPosition = () => {
        let newPositions: IBillPosition[] = [];

        if (billPartnerArray.length > 0) {
            selectedPosition.forEach((selectedPos, idx) => {
                let serviceObject = serviceArray.find(x => x.id === selectedPos);
                let maxPosition = 0;
                let newId = -1;

                if (billPositionArray.length > 0) {
                    maxPosition = Math.max(...billPositionArray.map(x => x.position))
                    let minId = Math.min(...billPositionArray.map(x => x.id)) - 1;

                    if (minId <= newId) {
                        newId = minId - 1;
                    }
                }

                if (serviceObject !== undefined) {
                    let newPosition: IBillPosition = {
                        id: newId - idx,
                        Service: serviceObject.id,
                        articelNumber: serviceObject.articelNumber,
                        Bill: billObject.id,
                        Unit: serviceObject.Unit,
                        title: serviceObject.title,
                        description: serviceObject.description,
                        units: 1,
                        Partner: Number(billPartnerArray[0].Partner),
                        position: (maxPosition + idx + 1),
                        price_per_hour: billPartnerArray[0].selling_price_bill,
                        price: 0,
                        timeFactor: serviceObject.timeFactor,
                        wasChangedPricePerHour: false,
                        wasChangedTimeFactor: false,
                        wasChangedTitle: false,
                        wasChangedPrice: false,
                    }
                    newPositions.push(newPosition);
                }
            });
        }

        setBillPosition([
            ...billPositionArray,
            ...newPositions,
        ]);
        handleClose();
    }

    const handleSearchTerm = () => {
        if (serviceArray === undefined) {
            return [];
        } else if (searchTerm === "") {
            setTempServiceArray(serviceArray);
        } else {
            setTempServiceArray(serviceArray.filter(x =>
                String(x.title).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            );
        }
    }

    useEffect(() => {
        handleSearchTerm();
    }, [searchTerm]);

    return (
        <Box sx={{ mt: 5 }}>
            <Grid container spacing={2}>
                <Grid style={{ background: "white" }} item sm={12}>
                    <TextField
                        variant="outlined"
                        label="Ausgewählte Kategorie"
                        fullWidth
                        value={currentCategory}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCurrentCategory(Number(event.target.value))}
                        select
                        size="small"
                    >
                        <MenuItem key="servicecategorie-all" value={-1}>Alle</MenuItem>
                        {serviceCategoryArray.map(category =>
                            <MenuItem key={`servicecategorie-${category.id}`}
                                value={category.id}>{category.serviceCategory}</MenuItem>
                        )}
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField>
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Nur Kategorien mit Leistungen"
                        attr="filterCategoryNoServices"
                        object={filterObject}
                        setObject={setFilterObject}
                        type="boolean"
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Nur Leistungen mit Übernahme durch die PV"
                        attr="filterIsPaidNursingCareFund"
                        object={filterObject}
                        setObject={setFilterObject}
                        type="boolean"
                    />
                </Grid>
            </Grid>

            <Box sx={{ mt: 5 }}></Box>

            <TextField
                sx={{ mt: 2, mb: 2 }}
                label="Suche"
                fullWidth
                value={searchTerm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
            />

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 10 }} />
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        serviceCategoryArray
                            .filter(category => (currentCategory === -1) ? true : category.id === currentCategory)
                            .filter(category => filterObject.filterCategoryNoServices === false ? true : (tempServiceArray.filter(service => service.ServiceCategory === category.id).length > 0))
                            .map(category =>
                                <>
                                    <TableRow>
                                        <TableCell colSpan={2}><b>{category.serviceCategory}</b></TableCell>
                                    </TableRow>
                                    {(tempServiceArray.filter(service => service.ServiceCategory === category.id).length === 0)
                                        ?
                                        <TableRow><TableCell /><TableCell><i>Keine Leistungen vorhanden.</i></TableCell></TableRow>
                                        : tempServiceArray
                                            .filter(service => service.ServiceCategory === category.id)
                                            .filter(service => filterObject.filterIsPaidNursingCareFund === false ? true : service.isPaidNursingCareFund)
                                            .map(service =>
                                                <TableRow sx={{ cursor: "pointer" }}
                                                    onClick={() => handleChange(service.id)}>
                                                    <TableCell>
                                                        <Checkbox
                                                            size="small"
                                                            checked={selectedPosition.indexOf(service.id) > -1}
                                                            onChange={() => handleChange(service.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{service.title}</TableCell>
                                                </TableRow>
                                            )
                                    }
                                </>
                            )
                    }
                </TableBody>
            </Table>

            <Box style={{
                position: 'absolute',
                display: "block",
                bottom: 0,
                right: 20,
                border: "10px solid white",
                width: "45vw",
                background: 'white',
                zIndex: 1001,
            }} sx={{ mt: 5 }}>
                <Button sx={{ float: "right", ml: 2 }} variant="contained" onClick={handleAddPosition}>Übernehmen</Button>
                <Button sx={{ float: "right", background: "white" }} variant="outlined"
                    onClick={handleClose}>Abbruch</Button>
            </Box>
        </Box>
    )
}
