import React from "react";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IUnit } from "../../interfaces/IUnit";
import { Grid, MenuItem, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";

interface IProps {
    serviceObject: IService;
    setServiceObject: Function;
    serviceCategoryArray: IServiceCategory[];
    unitArray: IUnit[];
}


export const ServiceEdit:React.FC<IProps> = ({serviceObject,setServiceObject,serviceCategoryArray,unitArray}) => {
    return(
        <>

            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid item sm={3}>
                    <CustomeTextField
                        label="Aritkelnummer"
                        attr="articelNumber"
                        type="string"
                        object={serviceObject}
                        setObject={setServiceObject}
                        required
                    />
                </Grid>
                <Grid item sm={9}>
                    <CustomeTextField
                        label="Bezeichnung"
                        attr="title"
                        type="string"
                        object={serviceObject}
                        setObject={setServiceObject}
                        required
                    />
                </Grid>


                <Grid item sm={4}>
                    <CustomeTextField
                        label="Kategorie"
                        attr="ServiceCategory"
                        type="select"
                        object={serviceObject}
                        setObject={setServiceObject}
                    >
                        {serviceCategoryArray.map(x =>
                            <MenuItem key={`servicearray-${x.id}`} value={x.id}>{x.serviceCategory}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item sm={4}>
                    <CustomeTextField
                        label="PV"
                        attr="isPaidNursingCareFund"
                        type="boolean"
                        object={serviceObject}
                        setObject={setServiceObject}
                    />
                </Grid>

                <Grid item sm={4}>
                    <CustomeTextField
                        label="Einheit"
                        attr="Unit"
                        type="select"
                        object={serviceObject}
                        setObject={setServiceObject}
                    >
                        {unitArray.map(x =>
                            <MenuItem key={`unit-${x.id}`} value={x.id}>{x.unit}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Zeitfaktor"
                        attr="timeFactor"
                        type="number"
                        object={serviceObject}
                        setObject={setServiceObject}
                        required
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Beschreibung"
                        attr="description"
                        type="string"
                        object={serviceObject}
                        setObject={setServiceObject}
                        rows={5}
                    />
                </Grid>

            </Grid>
        </>
    )
}