import React, {useEffect, useRef, useState} from "react";
import {IOffer} from "../../interfaces/IOffer";
import SignatureCanvas from 'react-signature-canvas'
import {Box, Button, LinearProgress, Typography} from "@mui/material";
import ReactSignatureCanvas from "react-signature-canvas";
import {uploadFetch} from "../../hooks/useFetch";
import {IOfferApplicationDocument} from "../../interfaces/IOfferApplicationDocument";
import {OfferSignedPageQuestions} from "./Childs/OfferSignedPageQuestions";
import {IOfferApplicationDocumentAnswer} from "../../interfaces/IOfferApplicationDocumentAnswer";
import './stylesheet.css';
import {Document, Page} from 'react-pdf'
import {IDocument} from "../../interfaces/IDocument";

import { pdfjs } from 'react-pdf';
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { PDFViewer } from "../core/PDFViewer";
import { useParams } from "react-router-dom";



pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}





interface IProps {
    editOfferDocument: IOfferApplicationDocument;
    setCurrentOfferApplicationDocument: Function;
    offerObject?: IOffer;
    offerArray?: IOffer[];
    setOfferArray?: Function;
    setWasSigend?: Function;
}

export const OfferSignedPage: React.FC<IProps> = ({
    editOfferDocument,
    setCurrentOfferApplicationDocument,
    offerObject,
    offerArray,
    setOfferArray,
    setWasSigend
}) => {
    const ref = useRef<ReactSignatureCanvas | null>(null);
    const params = useParams();
    //
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [offerAnswerArray, setOfferAnswerArray] = useState<IOfferApplicationDocumentAnswer[]>([]);




    const handleAfterSave = (res: IOfferApplicationDocument) => {
        if (offerObject!== undefined && offerArray !== undefined && setOfferArray !== undefined) {
            setOfferArray([
                ...offerArray.map(x => x.id !== offerObject.id ? x : {
                        ...offerObject,
                        OfferApplicationDocuments: [...offerObject.OfferApplicationDocuments!.map(x => x.id === res.id ? res : x)]
                    }
                )
            ])
    
            setCurrentOfferApplicationDocument(null);
        }
        else if (setWasSigend !== undefined) {
            setWasSigend(true);
            setCurrentOfferApplicationDocument(res);
        }


        //setIsOpenSignedField(false);
    }

    const handleSave = () => {
        if (ref !== undefined) {
            let base64String = ref.current?.toDataURL();

            if (base64String !== undefined) {
                let uploadObject: IOfferApplicationDocument = {
                    ...editOfferDocument,
                    OfferApplicationDocumentAnswers: offerAnswerArray,
                    Document_Signed_Field: {
                        file_name: "signed.png",
                        data: base64String.split(",")[1],
                        id: -1,
                        MIMEtype: {id : 2, MIMEtype: "image/png", file_ending: "png"}
                    }
                    //signed_field: base64String.split(",")[1]
                }

                if (offerObject === undefined || offerArray === undefined || setOfferArray === undefined) {
                    uploadFetch(`/offer/applications/public/signed/${params.token}`, true, uploadObject, handleAfterSave, setWasSuccessfully, () => {}, setIsLoading)
                }
                else {
                    uploadFetch(`/offer/applications/document/${editOfferDocument.id}`, false, uploadObject, handleAfterSave, setWasSuccessfully, () => {}, setIsLoading)
                }
            }
        }

    }





    return (
        <>

            <Typography variant="h4">
                Angebot
                <Button disabled={isLoading} variant="contained" onClick={handleSave}
                        sx={{float: "right"}}>Speichern</Button>
            </Typography>

            {(isLoading) && <LinearProgress sx={{m: 2}}/>}


            <Box sx={{mt: 5}}/>
            
            <PDFViewer documentObject={editOfferDocument.Document_Data}/>



            <Box sx={{mt: 10}}/>

            {(editOfferDocument.Templates !== undefined) &&
                <OfferSignedPageQuestions
                    offerApplicationDocument={editOfferDocument}
                    templateArray={editOfferDocument.Templates}
                    offerAnswerArray={offerAnswerArray}
                    setOfferAnswerArray={setOfferAnswerArray}
                />
            }


            <Box sx={{float: "right", mt: 5}}>
                <Typography>Unterschrift</Typography>
                <SignatureCanvas
                    penColor='black'
                    backgroundColor='rgba(238,238,238,1)'
                    canvasProps={{width: 800, height: 300, className: 'sigCanvas'}}
                    ref={ref}
                />
            </Box>
        </>
    )
}