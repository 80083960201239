import React from 'react';
import { Navigate} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useAuthContext } from '../../contexts/auth/AuthContext';


interface ProtectedRouteProps {
  element: React.ReactElement;
}

const AuthRoute: React.FC<ProtectedRouteProps> = ({ element})  => {
  const { isAuthenticated, loading } = useAuthContext();

  if (loading) {
    return <CircularProgress/>;
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default AuthRoute;

