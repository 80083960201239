import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from '../../contexts/permissions/PermissionContext';
import { CircularProgress } from '@mui/material';
import { useAuthContext } from '../../contexts/auth/AuthContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
  requiredPermission: string;
  superuserOnly?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, requiredPermission, superuserOnly }) => {
  const { isAuthenticated, loading } = useAuthContext();
  const { permissions } = usePermissions();
  const isSuperuser = localStorage.getItem("is_superuser") === "true";

  if (loading) {
    return <CircularProgress />;
  }

  const hasPermission = permissions.includes(requiredPermission);

  // Check is_superuser
  if (superuserOnly && !isSuperuser) {
    return <Navigate to="/no-permission" />;
  }

  return isAuthenticated && (hasPermission || superuserOnly) ? element : <Navigate to="/no-permission" />;
};

export default ProtectedRoute;
