import React, { useEffect, useState } from "react";
import { IOfferMaterialPosition } from "../../../interfaces/IOfferMaterialPosition";
import { IMaterial } from "../../../interfaces/IMaterial";
import { IMaterialCategory } from "../../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../../interfaces/IMaterialSubcategory";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Grid, MenuItem, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { getTmpId } from "../../core/generic_helpders";
import { IUnit } from "../../../interfaces/IUnit";
import { Category } from "@mui/icons-material";


interface IProps {
    assignMaterialList: number[];
    setAssignMaterialList: Function;
    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
    sx?: SxProps<Theme>;
}


export const OfferMaterialPositionAssign: React.FC<IProps> = ({
    materialArray, materialCategoryArray, materialSubcategoryArray,
    assignMaterialList, setAssignMaterialList,
    sx
}) => {
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");



    const handleChange = (idService: number) => {
        let isUsed = (assignMaterialList.indexOf(idService) > -1);

        if (isUsed) {
            setAssignMaterialList([
                ...assignMaterialList.filter(x => x !== idService)
            ])
        } else {
            setAssignMaterialList([
                ...assignMaterialList,
                idService
            ])
        }
    }



    return (
        <Box sx={sx}>
            <TextField
                //type="email"
                variant="outlined"
                label="Ausgewählte Kategorie"
                fullWidth
                value={selectedCategory}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedCategory(Number(event.target.value))}
                select
                size="small"
            >
                <MenuItem key="servicecategorie-all" value={0}>Alle</MenuItem>
                {materialCategoryArray.map(category =>
                    <MenuItem key={`materialcategory-${category.id}`}
                                value={category.id}>{category.materialCategory}</MenuItem>
                )}
            </TextField>

            <TextField
                sx={{mt: 2, mb: 2}}
                label="Suche"
                fullWidth
                value={searchTerm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
            />

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 10}}/>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {materialCategoryArray.filter(x => (selectedCategory === 0) ? true : x.id === selectedCategory).map(matCatgory => {
                        return(
                            <>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <b>{matCatgory.materialCategoryNumber}0000 {matCatgory.materialCategory}</b>

                                    </TableCell>
                                </TableRow>
                                {materialArray
                                    .filter(x => x.MaterialCategory === matCatgory.id)
                                    .filter(x => searchTerm === "" ? true : (
                                        x.articelNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                                        || x.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                                    ))
                                    .map(x =>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                size="small"
                                                checked={assignMaterialList.indexOf(x.id) > -1}
                                                onChange={() => handleChange(x.id)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {x.articelNumber} {x.title}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {materialArray.filter(x => x.MaterialCategory === matCatgory.id).length === 0 &&
                                    <TableRow><TableCell/><TableCell><i>Keine Materialien zugewiesen.</i></TableCell></TableRow>
                                }
                            </>
                        )
                    })}
                </TableBody>
            </Table>
        </Box>
    )
}