import React, { useEffect, useState } from "react";
import { IPartner } from "../../../interfaces/IPartner";
import { IUnit } from "../../../interfaces/IUnit";
import { IService } from "../../../interfaces/IService";
import { IServiceCategory } from "../../../interfaces/IServiceCategory";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { OfferPositionAdd } from "./OfferPositionAdd";
import { OfferPositionRow } from "./OfferPositionRow";
import { IOfferPartner } from "../../../interfaces/IOfferPartner";
import { IOffer } from "../../../interfaces/IOffer";



interface IProps {
    offerObject: IOffer;
    offerPositionArray: IOfferPosition[];
    setOfferPosition: Function;
    offerPartnerArray: IOfferPartner[];
    partnerArray: IPartner[];
    unitArray: IUnit[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
    isOpenAdd: boolean;
    setIsOpenAdd: Function;
}



export const OfferPositionTable:React.FC<IProps> = ({offerObject, offerPositionArray,setOfferPosition,
    offerPartnerArray,partnerArray,unitArray,serviceArray,serviceCategoryArray, isOpenAdd, setIsOpenAdd}) => {
    const [total,setTotal] = useState(0);

    const handleOnClose = () => {
        setIsOpenAdd(false);
    }



    const getTotal = () => {
        let total = 0;
        console.log("UPDATE")
        offerPositionArray.map(offerPosition => {
            if (offerPosition.wasChangedPrice) {
                total = total + (offerPosition.price*offerPosition.units)
            }
            else {
                let offerPartnerObject = offerPartnerArray.find(x => x.Partner === offerPosition.Partner);
                let serviceObject = serviceArray.find(x => x.id === offerPosition.Service);
                //
                let price_per_hour = 0;
                let timeFactor = 0;
                //
                if (offerPartnerObject === undefined || offerPosition.wasChangedPricePerHour) {
                    price_per_hour = offerPosition.price_per_hour
                } else {
                    price_per_hour = offerPartnerObject.selling_price_offer
                }

                if (serviceObject === undefined || offerPosition.wasChangedTimeFactor) {
                    timeFactor = offerPosition.timeFactor
                } else {
                    timeFactor = serviceObject.timeFactor
                }
                
                total = total + (offerPosition.units * price_per_hour * timeFactor)
                
            }
        })
        return total
    }

    useEffect(() => {
        setTotal(getTotal())
    },[offerPositionArray, offerPartnerArray])


    return(
        <>
            <Dialog open={isOpenAdd} onClose={handleOnClose} maxWidth="md" fullWidth>
                <DialogTitle style={{background: "white", border: "10px solid white"}} >Neue Position(en) hinzufügen</DialogTitle>
                <DialogContent >
                    <OfferPositionAdd
                        offerObject={offerObject}
                        offerPartnerArray={offerPartnerArray}
                        partnerArray={partnerArray}
                        offerPositionArray={offerPositionArray}
                        setOfferPosition={setOfferPosition}
                        serviceArray={serviceArray}
                        serviceCategoryArray={serviceCategoryArray}
                        setIsOpenAdd={setIsOpenAdd}
                    />
                </DialogContent>
            </Dialog>


            <Typography variant="h5">
                Positionen
            </Typography>
           
            <Table sx={{mb: 5}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{p:0, width: 50}}></TableCell>
                        <TableCell sx={{p:0, width: 80}}>Artk.Nr.</TableCell>
                        {/* <TableCell sx={{p:0, width: 150}}>Partner</TableCell> */}
                        <TableCell sx={{minWidth: 300}}>Position</TableCell>
                        <TableCell sx={{p:0, width: 50}}></TableCell>
                        <TableCell sx={{p:0, width: 150}}>Faktor</TableCell>
                        {/*
                            <TableCell sx={{p:0, width: 150}}>P.p.S.</TableCell>
                         */}
                        
                        <TableCell sx={{p:0, width: 150}}>Einheiten</TableCell>
                        <TableCell sx={{p:0, width: 50}}>Einheit</TableCell>
                        <TableCell sx={{p:0, width: 150}}>Preis</TableCell>
                        <TableCell sx={{p:0, width: 50}}>Total</TableCell>
                        <TableCell sx={{p:0, width: 50}}></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {offerPositionArray
                        .sort((a,b) => a.articelNumber > b.articelNumber ? 1 : -1)
                        .map((offerPosition,idx) =>
                        <OfferPositionRow
                            offerObject={offerObject}
                            key={`offerposition-${offerPosition.id}-${offerPosition.position}-${idx}`}
                            offerPositionObject={offerPosition}
                            offerPartnerArray={offerPartnerArray}
                            partnerArray={partnerArray}
                            offerPositionArray={offerPositionArray}
                            setOfferPosition={setOfferPosition}
                            serviceArray={serviceArray}
                            serviceCategoryArray={serviceCategoryArray}
                            unitArray={unitArray}
                        />
                    )}

                    <TableRow>
                        <TableCell colSpan={6} />
                        <TableCell colSpan={2}><b>Summe</b></TableCell>
                        <TableCell>{total.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell/>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={6} />
                        <TableCell sx={{borderBottom: "solid 1px"}} colSpan={2}><b>MwSt.</b></TableCell>
                        <TableCell sx={{borderBottom: "solid 1px"}}>{(total*offerObject.vat).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell/>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={6} />
                        <TableCell sx={{borderBottom: "solid 1px"}} colSpan={2}><b>Total</b></TableCell>
                        <TableCell sx={{borderBottom: "solid 1px"}}>{((total*offerObject.vat)+total).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableBody>

            </Table>
        </>
    )
}