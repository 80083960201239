import React, {useEffect, useState} from "react";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { uploadFetchFeedback } from "../../hooks/useFetch";
import { IFeedback } from "../../interfaces/IFeedback";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { CustomEditor } from "../core/CustomEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IDictionaryEntry } from "../../interfaces/IDictionaryEntry";


interface IProps {
    currentId: number;
    setCurrentId: Function;
    currentArray: ISystemMail[];
    setCurrentArray: Function;
    setWasSaved: Function;
    setWasSavedSuccesfully: Function;
    dictionaryEntryArray: IDictionaryEntry[];
}

export const SystemMailEdit: React.FC<IProps> = (props) => {
    const [systemMailOrg, setSystemMailOrg] = useState(props.currentArray.find(x => x.id === props.currentId));
    const [systemMailObject, setSystemMailObject] = useState(systemMailOrg);
    //
    const [isLoading,setIsLoading] = useState(false)
    const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 


    const setText = (txt: string) => {
        //console.log("INSIDE");
        //console.log(txt);
        setSystemMailObject({... systemMailObject!, body : txt});
        /*
        console.log("INSIDE");
        console.log(txt);
        setSettingObject({
            ...settingObject,
            SettingValue: txt
        })
        if(settingObject?.SettingValue !== undefined && systemMailObject !== undefined)
        {
            setSystemMailObject({... systemMailObject, Body : settingObject.SettingValue})
        }
        */
    }


    const isDisabled = () => {
        if (systemMailObject !== undefined) {
            return (
                systemMailObject.title === ""
                || systemMailObject.subject === ""
                || systemMailObject.body === ""
            )
        } else {
            return true;
        }
    }

    const wrapperSaved = (localObject: ISystemMail) => {
        if (props.currentId < 0) {
            props.setCurrentArray([
                ...props.currentArray,
                localObject
            ])
        } else {
            props.setCurrentArray([
                ...props.currentArray.map(x => x.id === localObject.id ? localObject : x)
            ])
        }
        props.setCurrentId(null);
    }


    const handleSave = () => {
        if (systemMailObject !== undefined) {
            let uploadObeject: ISystemMail = {
                ...systemMailObject,
            }
            uploadFetchFeedback(`/systemmail/${systemMailObject.id}`,
                false,
                uploadObeject,
                wrapperSaved,
                setIsLoading,
                setFeedback
                
            );
        }
    }

    if (systemMailOrg === undefined || systemMailObject === undefined) {
        return <>Bitte warten...</>
    }
    else {
        return (
            <>
                <Typography variant="h5">
                    {`${systemMailOrg.title}`}
                </Typography>

                <Box sx={{mt: 2}}/>

                <Grid container spacing={2}>

                    <Grid item sm={6}>
                        <TextField
                            label="Titel (nur intern)"
                            size="small"
                            value={systemMailObject.title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, title: event.target.value})}
                            error={systemMailObject.title === ""}
                            fullWidth
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            label="Betreff"
                            size="small"
                            value={systemMailObject.subject}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({...systemMailObject, subject: event.target.value})}
                            error={systemMailObject.subject === ""}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Box sx={{mt: 5}}/>

                <Grid container spacing={2}>
                    <CustomEditor 
                        txt={systemMailObject.body} 
                        setTxt={setText}
                        dictonaryIdsArray={systemMailObject.DictonaryArray}
                        dictionaryEntryArray={props.dictionaryEntryArray}
                    />
                </Grid>


                <Box sx={{mt: 2}}/>
                <Button onClick={handleSave} sx={{float: "right", ml: 2}} variant="contained"
                        disabled={isDisabled()}>Speichern</Button>
                <Button onClick={() => props.setCurrentId(null)} sx={{float: "right"}}
                        variant="outlined">Abbruch</Button>
            </>
        )
    }

}