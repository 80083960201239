import {
  Button,
  Alert,
  Collapse,
  Grid,
  LinearProgress,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogTitle, DialogContent, DialogActions,
  Box
} from "@mui/material";
import {DataGrid, GridColDef, deDE, GridRowParams, GridRenderCellParams} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {CustomSnackbar} from '../../components/core/Snackbar';
import {useFetch, uploadFetch, deleteFetch} from "../../hooks/useFetch";

//interfaces imports
import {IFeedback} from "../../interfaces/IFeedback";
import { IProfile } from "../../interfaces/IUser";

import { Delete } from "@mui/icons-material";
import { ProfileEdit } from "../../components/user/ProfileEdit";

export const getEmptyWorker = () => {
    return { 
        id: 0,
        User: {
          id: 0,
          email: '',
          username: '',
          is_superuser: false,
          profile: '',
          first_name: '',
          last_name: '',
          password: '',
        },
        permissions: [],
        mobile_number: '',
        phone_number: '',
      } as IProfile
}



export const RoleManagementOverview: React.FC = () => {
  const [feedback, setFeedback] = useState<IFeedback>({open: false, message: '', severity: 'info'});

  const [userArray, setUserArray, statusCodeUser] = useFetch<IProfile[]>("/user/");
  const [editWorker, setEditWorker] = useState<IProfile>(getEmptyWorker());
  const [wasSuccessfully, setWasSuccessfully] = useState(true);
  const [isLoadgin, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Suche
  const [searchTerm, setSearchTerm] = useState("");

  //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [userIdToDelete, setUserIdToDelete] = React.useState<number | null>(null);
  const is_superuser = localStorage.getItem("is_superuser")
  const [ userPassword, setUserPassword] = useState({password: '', second_password: ''})
  const openDeleteConfirmationDialog = (userId: number) => {
      setUserIdToDelete(userId);
      setOpenDeleteDialog(true);
  };

  const closeDeleteConfirmationDialog = () => {
      setUserIdToDelete(null);
      setOpenDeleteDialog(false);
  };

  const handleRemove = (userId: number) => {
      if (is_superuser === "true"){
          deleteFetch("/user/", userId,setWasSuccessfully)
          setFeedback({ open: true,message: "Mitarbeiter erfolgreich gelöscht!", severity: "success" })
          const newArray = (userArray === undefined) ? "" : userArray.filter(user => user.id !== userId);
          setUserArray(newArray)
          closeDeleteConfirmationDialog();
      }else{
          console.log("You have no permission.")
      }   
  };
//END OF DELETE PATTERNS


  const columns: GridColDef[] = [
      {
          field: 'customerNumber',
          headerName: 'Nr.',
          valueGetter: (params) => params.row.id,
          width: 70
      },
      {
        field: 'username',
        headerName: 'Benutzername',
        valueGetter: (params) => params.row.User.username,
        width: 200
    },
      {
          field: 'firstname',
          headerName: 'Vorname',
          valueGetter: (params) => params.row.User.first_name,
          width: 200
      },
      {
          field: 'lastname',
          headerName: 'Name',
          valueGetter: (params) => params.row.User.last_name,
          width: 200
      },
      {
        field: 'email',
        headerName: 'Email',
        valueGetter: (params) => params.row.User.email,
        width: 200
    },
      {
          field: 'mobil',
          headerName: 'Mobil',
          valueGetter: (params) => params.row.phone_number,
          width: 200
      },
      { 
          field: 'action', 
          headerName: 'Aktionen', 
          renderCell: (params: GridRenderCellParams<any, number>) => {
              return (
                  <> {(is_superuser == "true") && <>
                      <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                          <Delete />
                      </IconButton>
                      <Dialog
                          open={openDeleteDialog && userIdToDelete === params.row.id}
                          onClose={closeDeleteConfirmationDialog}
                      >
                          <DialogTitle>Bestätigung Löschung</DialogTitle>
                          <DialogContent>
                              Sind Sie sicher, dass Sie {params.row.User.first_name} {params.row.User.last_name} löschen wollen?
                          </DialogContent>
                          <DialogActions>
                              <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                  Abbruch
                              </Button>
                              <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                  Bestätigen
                              </Button>
                          </DialogActions>
                      </Dialog>
                      </>}
                  </>
              );
          },
          flex: 1
      },
  ]


  const handleChangeId = (params: GridRowParams<any>) => {
    let testObject = userArray?.find(x => x.id === params.id);
    console.log(testObject)
    if (testObject === undefined) {
      setEditWorker(getEmptyWorker())
    } else {
      setEditWorker(testObject);
    }
    setIsOpen(true);
  }

  const handleNew = () => {
    const newWorker = getEmptyWorker();
    setEditWorker(newWorker);
    setIsOpen(true);
  }


  const handleAfterSave = (savedObject: IProfile) => {
    console.log(savedObject)
    if (userArray !== undefined) {
      setFeedback({ open: true, message: "Gespeichert!", severity: "success" });
  
      let testObject = userArray.find(x => x.id === savedObject.id);
      console.log(testObject)
      if (testObject === undefined) {
        setFeedback({ open: true, message: "Mitarbeiter erstellt!", severity: "success" });
        console.log("Adding new object:", savedObject);
        setUserArray([
          ...userArray,
          savedObject
        ]);
      } else {
        console.log("Updating existing object:", savedObject);
        setUserArray(userArray.map(x => x.id === savedObject.id ? savedObject : x));
      }
  
      setIsOpen(false);
    }
  };
  
  const checkUserPassword = (password:any) => {
    const feedback: IFeedback= {
        open: true,
        message: "",
        severity: "error"
    };

    const { password: pass, second_password: secondPass } = password;
    if (pass == ''){
        return true
    }

    // Check if passwords match
    if (pass !== secondPass) {
        feedback.message = "Passwörter stimmen nicht überein!";
        setFeedback(feedback);
        return false;
    }

    // Check minimum length
    if (pass.length < 8) {
        feedback.message = "Passwort muss mindestens 8 Zeichen lang sein!";
        setFeedback(feedback);
        return false;
    }

    // Check for uppercase letter
    if (!/[A-Z]/.test(pass)) {
        feedback.message = "Passwort muss mindestens einen Großbuchstaben enthalten!";
        setFeedback(feedback);
        return false;
    }

    // Check for lowercase letter
    if (!/[a-z]/.test(pass)) {
        feedback.message = "Passwort muss mindestens einen Kleinbuchstaben enthalten!";
        setFeedback(feedback);
        return false;
    }

    // Check for number
    if (!/[0-9]/.test(pass)) {
        feedback.message = "Passwort muss mindestens eine Zahl enthalten!";
        setFeedback(feedback);
        return false;
    }

    // Check for special symbol
    if (!/[\W_]/.test(pass)) { // \W is any non-word character, _ is for underscore
        feedback.message = "Passwort muss mindestens ein Sonderzeichen enthalten!";
        setFeedback(feedback);
        return false;
    }
    return true;
};

const handleSave = () => {
    setWasSuccessfully(true);
    if (!checkUserPassword(userPassword)) {
        return;
    }

    if (editWorker.id === 0) {
        uploadFetch("/user/", true, editWorker, handleAfterSave, setWasSuccessfully, () => {
        }, setIsLoading);
    } else {
        uploadFetch(`/user/${editWorker.id}`, false, editWorker, handleAfterSave, setWasSuccessfully, () => {
        }, setIsLoading);
    }
};


  const handleSubmit = (e: React.SyntheticEvent) => {
      e.preventDefault();
      handleSave();
  }

  useEffect(() => {
      if (!wasSuccessfully) {
          setFeedback({open: true, message: "Ein fehler ist aufgetretten!", severity: "error"})
          setIsLoading(false);
      }
  }, [wasSuccessfully])


  const handleSearchTerm = () => {
      if (userArray === undefined) {
          return [];
      }
      else if (searchTerm === "") {
          return userArray
      } else {
          return userArray.filter(x =>
              String(x.User.last_name).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
              || String(x.User.first_name).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
              || String(x.phone_number).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
              || String(x.mobile_number).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
              || String(x.User.email).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
          )
      }
  }


  if (
      (statusCodeUser !== null && statusCodeUser !== 200)
  ) {
      return <Alert severity="error">Fehler!</Alert>
  } else if (
      userArray === undefined
  ) {
      return <LinearProgress/>
  } else {
      return (
          <>
              <Typography variant="h5">
                  Rollenverwaltung
                  <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Mitarbeiter hinzufügen</Button>
              </Typography>

              <Collapse sx={{mt: 1}} in={isLoadgin}>
                  <LinearProgress/>
              </Collapse>
              <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

              <TextField
                  sx={{mt: 2, mb: 2}}
                  label="Suche"
                  fullWidth
                  value={searchTerm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
              />
              <Grid container spacing={2} sx={{mt: 2}}>
                  <Grid item sm={(isOpen) ? 6 : 12}>
                      <DataGrid
                          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                          rows={handleSearchTerm()}
                          columns={columns}
                          onRowClick={(params, event, details) => {
                              const target = event.target as HTMLElement;
                              const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                              if (isDeleteIconClicked) {
                                  event.stopPropagation();
                                  openDeleteConfirmationDialog(Number(params.id));
                              } else {
                                  handleChangeId(params)
                              }
                          }}
                          autoHeight
                      />
                  </Grid>
                  <Grid item sm={(isOpen) ? 6 : 0}>
                    <Collapse in={isOpen}>
                        <form onSubmit={handleSubmit}>
                            <Typography variant="h6">
                                Mitarbeiter
                                <ProfileEdit
                                    key={`editWorker-${editWorker.id}`}
                                    workerObject={editWorker}
                                    setWorkerObject={setEditWorker}
                                    setUserPassword={setUserPassword}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                    <Button sx={{ ml: 2 }} onClick={() => setIsOpen(false)} variant="outlined">
                                        Abbruch
                                    </Button>
                                    <Button sx={{ ml: 2 }} type="submit" variant="contained" disabled={isLoadgin}>
                                        Speichern
                                    </Button>

                                </Box>
                            </Typography>
                        </form>
                    </Collapse>
                </Grid>
              </Grid>
          </>
      )
  }
}