import {
    Button,
    Alert,
    Collapse,
    Grid,
    LinearProgress,
    Typography,
    TextField,
    IconButton,
    Dialog,
    DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import {DataGrid, GridColDef, deDE, GridRowParams, GridRenderCellParams} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {CustomSnackbar} from '../../components/core/Snackbar';
import {useFetch, uploadFetch, deleteFetch} from "../../hooks/useFetch";
import {CustomerEdit} from "../../components/customer/CustomerEdit";
//interfaces imports
import {IFeedback} from "../../interfaces/IFeedback";
import {ICustomer} from "../../interfaces/ICustomer";
import {IInsurance} from "../../interfaces/IInsurance";
import {IGender} from "../../interfaces/IGender";
import {ITitle} from "../../interfaces/ITitle";
import {IAddress, IAddressType} from "../../interfaces/IAddress";
import {getEmptyCustomer} from "../../components/customer/CustomerEdit";
import {IPropertyManagment} from "../../interfaces/IPropertyManagement";
import { Delete } from "@mui/icons-material";
import { usePermissions } from "../../contexts/permissions/PermissionContext";
import ValidationDialog from "../ValidationDialog";

export const getEmptyAddress = (addressType: number) => {
    return {
        id: -1,
        Address_Type: addressType,
        street: '',
        street_number: '',
        Postcode: null,
    } as IAddress
}

export const CustomerOverview: React.FC = () => {
    const [feedback, setFeedback] = useState<IFeedback>({open: false, message: '', severity: 'info'});
    const { permissions } = usePermissions();

    const [customerData, setCustomerData, statusCode] = useFetch<ICustomer[]>("/customer/");
    const [insuranceData, setInsuranceCodeData] = useFetch<IInsurance[]>("/insurance/");
    const [propertyManagementData, setPropertyManagementData] = useFetch<IPropertyManagment[]>("/property_management/");
    const [genderData, setGenderData] = useFetch<IGender[]>("/customer/gender/");
    const [titleData, setTitleData] = useFetch<ITitle[]>("/customer/academic-title/");
    const [addressTypeArray, setAddressTypeArray, statusCodeAddressType ] = useFetch<IAddressType[]>("/address/types/");

    const [editCustomer, setEditCustomer] = useState(getEmptyCustomer());
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [isLoadgin, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // Suche
    const [searchTerm, setSearchTerm] = useState("");

    //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [customerIdToDelete, setCustomerIdToDelete] = React.useState<number | null>(null);
    const is_superuser = localStorage.getItem("is_superuser")

    //RequiredFields
    const [areRequiredFieldsSet, setAreRequiredFieldsSet] = useState(false)
    const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
    const [proceedWithSave, setProceedWithSave] = useState(false);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [attemptingSave, setAttemptingSave] = useState(false);


    const handleValidationDialogClose = () => {
        setIsValidationDialogOpen(false);
        setProceedWithSave(false);
    };

    const handleValidationDialogProceed = () => {
        setIsValidationDialogOpen(false);
        setProceedWithSave(true);
        setAttemptingSave(true);
    };

    useEffect(() => {
        if (attemptingSave) {
            handleSave();
        }
    }, [attemptingSave]);

    const openDeleteConfirmationDialog = (projectId: number) => {
        setCustomerIdToDelete(projectId);
        setOpenDeleteDialog(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setCustomerIdToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleRemove = (customerId: number) => {
        if (is_superuser === "true"){
            deleteFetch("/customer/", customerId,setWasSuccessfully)
            setFeedback({ open: true,message: "Kunde erfolgreich gelöscht!", severity: "success" })
            const newArray = (customerData === undefined) ? "" : customerData.filter(customer => customer.id !== customerId);
            setCustomerData(newArray)
            console.log(customerId)
            closeDeleteConfirmationDialog();
        }else{
            console.log("You have no permission.")
        }   
    };
//END OF DELETE PATTERNS


    const columns: GridColDef[] = [
        {
            field: 'customerNumber',
            headerName: 'Nr.',
            valueGetter: (params) => params.row.id,
            width: 70
        },
        {
            field: 'firstname',
            headerName: 'Vorname',
            valueGetter: (params) => params.row.firstname,
            width: 200
        },
        {
            field: 'lastname',
            headerName: 'Name',
            valueGetter: (params) => params.row.lastname,
            width: 200
        },
        {
            field: 'telefon',
            headerName: 'Telefon',
            valueGetter: (params) => params.row.telefon,
            width: 200
        },
        {
            field: 'mobil',
            headerName: 'Mobil',
            valueGetter: (params) => params.row.mobil,
            width: 200
        },
        {
            field: 'email',
            headerName: 'Email',
            valueGetter: (params) => params.row.email,
            width: 250
        },
        { 
            field: 'action', 
            headerName: 'Aktionen', 
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return (
                    <> {(is_superuser == "true" || permissions.includes("delete_customer")) && <>
                        <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                            <Delete />
                        </IconButton>
                        <Dialog
                            open={openDeleteDialog && customerIdToDelete === params.row.id}
                            onClose={closeDeleteConfirmationDialog}
                        >
                            <DialogTitle>Bestätigung Löschung</DialogTitle>
                            <DialogContent>
                                Sind Sie sicher, dass Sie "{params.row["firstname"]} {params.row["lastname"]}" löschen wollen?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                    Abbruch
                                </Button>
                                <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                    Bestätigen
                                </Button>
                            </DialogActions>
                        </Dialog>
                        </>}
                    </>
                );
            },
            flex: 1
        },
    ]


    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = customerData?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditCustomer(getEmptyCustomer())
        } else {
            setEditCustomer(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditCustomer(getEmptyCustomer());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject: ICustomer) => {
        if (customerData !== undefined) {
            setFeedback({open: true, message: "Gespeichert!", severity: "success"})

            let testObject = customerData.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setCustomerData([
                    ...customerData,
                    savedObject
                ])
            } else {
                setCustomerData([
                    ...customerData.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }

    const handleSave = () => {
        if (!areRequiredFieldsSet && !proceedWithSave) {
            setIsValidationDialogOpen(true);
            return;
        }

        let uploadObject = {
            ...editCustomer,
            Address: (editCustomer.b2b) ? undefined : editCustomer.Address,
            Company: (editCustomer.b2b) ? editCustomer.Company : undefined,
        } as ICustomer;

        setWasSuccessfully(true);
        if (editCustomer.id === 0) {
            uploadFetch("/customer/", true, uploadObject, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading);
        } else {
            uploadFetch(`/customer/${editCustomer.id}`, false, uploadObject, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading);
        }

        // Reset proceedWithSave and attemptingSave after saving
        setProceedWithSave(false);
        setAttemptingSave(false);
    };


    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({open: true, message: "Ein fehler ist aufgetretten!", severity: "error"})
            setIsLoading(false);
        }
    }, [wasSuccessfully])


    const handleSearchTerm = () => {
        if (customerData === undefined) {
            return [];
        }
        else if (searchTerm === "") {
            return customerData
        } else {
            return customerData.filter(x =>
                String(x.lastname).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.firstname).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.telefon).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.mobil).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.email).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }


    if (
        (statusCode !== null && statusCode !== 200)
    ) {
        return <Alert severity="error">Fehler!</Alert>
    } else if (
        customerData === undefined
    ) {
        return <LinearProgress/>
    } else {
        return (
            <>
                <Typography variant="h5">
                    Kundenverzeichnis
                  {permissions.includes("add_customer") &&   <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Kunden hinzufügen</Button> }
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>


                <ValidationDialog
                    open={isValidationDialogOpen}
                    handleClose={handleValidationDialogClose}
                    handleProceed={handleValidationDialogProceed}
                    missingFields={missingFields}
                />

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />
                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={(isOpen) ? 6 : 12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={handleSearchTerm()}
                            columns={columns}
                            onRowClick={(params, event, details) => {
                                if(permissions.includes("change_customer")){
                                const target = event.target as HTMLElement; 
                                const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                                if (isDeleteIconClicked) {
                                    event.stopPropagation();
                                    openDeleteConfirmationDialog(Number(params.id));
                                } else {
                                    handleChangeId(params)
                                }
                            }}}
                            autoHeight
                        />
                    </Grid>
                    <Grid item sm={(isOpen) ? 6 : 0}>
                        <Collapse in={isOpen}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6">
                                    Kunde
                                    <Button sx={{float: "right", ml: 2}} type="submit" variant="contained"
                                            disabled={isLoadgin}>Speichern</Button>
                                    <Button sx={{float: "right"}} onClick={() => setIsOpen(false)}
                                            variant="outlined">Abbruch</Button>
                                </Typography>
                                <CustomerEdit
                                    key={`editCustomer-${editCustomer.id}`}
                                    insuranceArray={insuranceData || []}
                                    setInsuranceArray={setInsuranceCodeData}
                                    propertyManagementArray={propertyManagementData || []}
                                    setPropertyManagementArray={setPropertyManagementData}
                                    genderArray={genderData || []}
                                    titleArray={titleData || []}
                                    addressTypeData={addressTypeArray|| []}
                                    customerObject={editCustomer}
                                    setCustomerObject={setEditCustomer}
                                    areRequiredFieldsSet={areRequiredFieldsSet}
                                    setAreRequiredFieldsSet={setAreRequiredFieldsSet}
                                    setMissingFields={setMissingFields} //
                                />
                            </form>
                        </Collapse>
                    </Grid>
                </Grid>
            </>
        )
    }


}