import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

interface ValidationDialogProps {
    open: boolean;
    handleClose: () => void;
    handleProceed: () => void;
    missingFields: string[];
}

const ValidationDialog: React.FC<ValidationDialogProps> = ({ open, handleClose, handleProceed, missingFields }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Fehlende Pflichtfelder</DialogTitle>
            <DialogContent>
                <Typography>Bitte füllen Sie die folgenden Felder aus, bevor Sie fortfahren:</Typography>
                <ul>
                    {missingFields.map(field => (
                        <li key={field}>{field}</li>
                    ))}
                </ul>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleProceed} color="primary">
                    Trotzdem Speichern
                </Button>
                <Button onClick={handleClose} color="primary">
                    Abbruch
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ValidationDialog;
