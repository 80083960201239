import React, { useEffect, useState } from "react";
import { IHandoverProtocolPartner } from "../../../interfaces/IHandoverProtocolPartner";
import { ICustomer } from "../../../interfaces/ICustomer";
import { IHandoverApplicationDocument } from "../../../interfaces/IHandoverApplicationDocument";
import { Download, NoteAlt } from "@mui/icons-material";
import { IProject } from "../../../interfaces/IProject";
import { IProjectParticipant } from "../../../interfaces/IProjectParticipant";
import { Alert, AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, MenuItem, Slide, Toolbar, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { getFetch, uploadFetch } from "../../../hooks/useFetch";
import { IDocument } from "../../../interfaces/IDocument";
import { TransitionProps } from "react-transition-group/Transition";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { HandoverApplicationDocumentSigned } from "./HandoverApplicationDocumentSigned";
import { ITemplate } from "../../../interfaces/ITemplate";
import { base64Download } from "../../core/generic";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  




interface IProps {
    projectObject: IProject;
    handoverPartnerObject: IHandoverProtocolPartner;
    customerArray: ICustomer[];
    templateArray: ITemplate[];
    editHandoverDocumentArray: IHandoverApplicationDocument[];
    setEditHandoverDocumentArray: Function;
}

const getEmptyHandoverApplicationDocument = (handoverPartnerObject:IHandoverProtocolPartner, participantArray: IProjectParticipant[],idParticipant:number|null) => {
    return {
        id: -1,
        Customer: Number(participantArray.find(y => y.id === idParticipant)?.Customer),
        data_generate_at: new Date().toUTCString(),
        Document_Data: null,
        Document_Field_Customer: null,
        Document_Field_Partner: null,
        Document_Signed: null,
        HandoverApplicationDocumentAnswerArray: [],
        HandoverProtocolPartner: handoverPartnerObject.id,
        Templates: 3,
        note: ""
    }
}



export const HandoverApplicationDocumentMain:React.FC<IProps> = ({
    projectObject, customerArray, templateArray,
    handoverPartnerObject,
    editHandoverDocumentArray, setEditHandoverDocumentArray
})  => {
    const is_superuser = localStorage.getItem("is_superuser") === "true";
    //
    const [isOpenSelectCustomer,setIsOpenSelectCustomer] = useState(false);
    const [selectedCustomer,setSelectedCustomer] = useState<number | null>(() => {
        let firstParticipant = (projectObject.Participant.length > 0 ? projectObject.Participant[0].id : undefined)
        return (firstParticipant === undefined) ? null : firstParticipant
    });
    //
    const [isOpenSignedField,setIsOpenSignedField] = useState(false);
    //
    const [currentApplicationDocument,setCurrentApplicationDocument] = useState<IHandoverApplicationDocument>(
        getEmptyHandoverApplicationDocument(handoverPartnerObject, projectObject.Participant, selectedCustomer)
    );
    const [wasSuccessfullyLoad,setWasSuccessfullyLoad] = useState(true);
    const [isLoading,setIsLoading] = useState(true);
    const [isGenDocuments,setIsGenDocuments] = useState(false);


    useEffect(() => {
        if (currentApplicationDocument.id > 0) {
            let foundElement = editHandoverDocumentArray.find(x => x.id === currentApplicationDocument.id);

            if (foundElement) {
                setEditHandoverDocumentArray([
                    ...editHandoverDocumentArray.map(x => x.id === currentApplicationDocument.id ? currentApplicationDocument : x)

                ])
            }
            else {
                setEditHandoverDocumentArray([
                    ...editHandoverDocumentArray,
                    currentApplicationDocument

                ])
            }

        }
    },[currentApplicationDocument])




    const getJustDownloadTsx = (participant: IProjectParticipant) => {
        let enableJustDownload = (
            editHandoverDocumentArray.find(x => x.Customer === participant.Customer) !== undefined
            && editHandoverDocumentArray.find(x => x.Customer === participant.Customer)?.Document_Signed !== null
        )
        return enableJustDownload && <Typography variant="caption" sx={{ml: 2}}> Nur Download</Typography>
    }

    const getCustomerTextTsx = (participant: IProjectParticipant) => { 
        let foundCustomer = customerArray.find(customer => customer.id === participant.Customer);
        return (foundCustomer === undefined) ? <>Fehler!</> : <>{foundCustomer.firstname} {foundCustomer.lastname}</>
    }



    const afterGenPdf = (resDocumentData:IDocument) => {
        let uploadObject = getEmptyHandoverApplicationDocument(handoverPartnerObject, projectObject.Participant, selectedCustomer);
        setCurrentApplicationDocument({
            ...uploadObject,
            Document_Data: resDocumentData
        })
        setIsGenDocuments(false);
        setIsOpenSignedField(true);
    }


    const callGenPdf = () => {
        let uploadObject = getEmptyHandoverApplicationDocument(handoverPartnerObject, projectObject.Participant, selectedCustomer);
        setCurrentApplicationDocument(uploadObject);
        uploadFetch("/handoverprotocol/applications/document/generate_pdf/",true,uploadObject,afterGenPdf,setWasSuccessfullyLoad,() => {},setIsLoading)
    }
    


    const handleSelected = () => {
        setWasSuccessfullyLoad(true);


        if (selectedCustomer !== null) {
            setIsGenDocuments(true);

            let currentDocument = editHandoverDocumentArray.find(x => x.Customer === projectObject.Participant.find(y => y.id === selectedCustomer)?.Customer);

            if (currentDocument === undefined || currentDocument.Document_Data === null) {
                callGenPdf();
            } else {
                setCurrentApplicationDocument(currentDocument);
                //setIsOpenSignedField(true);
                if (currentDocument.Document_Signed === null) {
                    setIsGenDocuments(false);
                    callGenPdf();
                } else {
                    base64Download(currentDocument.Document_Signed);
                    setIsGenDocuments(false);
                }
                
            }
        }
    }


    const handleDownloadAll = () => {
        editHandoverDocumentArray.map(x => {
            if (x.Document_Signed !== null) {
                base64Download(x.Document_Signed);
            }
            else if (x.Document_Data !== null) {
                base64Download(x.Document_Data);
            }
        })
    }


    const afterResetDocument = (resDocumentData:IDocument) => {
        setEditHandoverDocumentArray([
            ...editHandoverDocumentArray.filter(x => x.id !== currentApplicationDocument.id)
        ])
        setCurrentApplicationDocument({
            ...currentApplicationDocument,
            id: -1,
            Document_Data: resDocumentData,
            Document_Field_Customer: null,
            Document_Field_Partner: null,
            Document_Signed: null,
            HandoverApplicationDocumentAnswerArray: []
        })
        setIsGenDocuments(false);
        setIsOpenSignedField(true);
    }


    const callResetDocument = () => {
        let foundApplication = editHandoverDocumentArray.find(x => x.Customer === projectObject.Participant.find(y => y.id === selectedCustomer)?.Customer)

        if (foundApplication !== undefined) {
            let uploadObject = getEmptyHandoverApplicationDocument(handoverPartnerObject, projectObject.Participant, selectedCustomer);
            setCurrentApplicationDocument(uploadObject)
            setIsGenDocuments(true);
            getFetch("/handoverprotocol/applications/document/reset/",foundApplication.id,afterResetDocument)
        }
    }



    return(
        <>
            <Dialog
                fullScreen
                open={isOpenSignedField}
                onClose={() => setIsOpenSignedField(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setIsOpenSignedField(false)}
                        aria-label="close"
                    >
                    <GridCloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Kundenansicht
                    </Typography>
                </Toolbar>
                </AppBar>
                <Box sx={{m: 5}}>
                    <HandoverApplicationDocumentSigned
                        key={`currentApplicationDocument-${currentApplicationDocument.id}`}
                        currentApplicationDocument={currentApplicationDocument}
                        setCurrentApplicationDocument={setCurrentApplicationDocument}
                        setIsOpenSignedField={setIsOpenSignedField}
                        templateObject={templateArray.find(x => x.id === 3)}
                    />
                </Box>
            </Dialog>




            <Dialog open={isGenDocuments} onClose={() => (!wasSuccessfullyLoad || true) && setIsGenDocuments(false)}>
                <DialogContent>
                    {(wasSuccessfullyLoad)
                        ? <><Typography>Dokumente werden generiert...</Typography><CircularProgress/></>
                        : <Alert severity="error">Es ist ein Fehler aufgetretten!</Alert>
                    }
                </DialogContent>
            </Dialog>



            <Dialog open={isOpenSelectCustomer} onClose={() => setIsOpenSelectCustomer(false)} maxWidth="xs" fullWidth>
                <DialogContent>
                    {/*JSON.stringify(editHandoverDocumentArray.map(x => x.Customer))*/}
                    <CustomeTextField
                        key="select-handover-participant"
                        label="Beteiligter"
                        attr="selectedCustomer"
                        object={{"selectedCustomer" : selectedCustomer}as any}
                        setObject={(x:{selectedCustomer : number}) => setSelectedCustomer(x.selectedCustomer)}
                        type="select"
                    >
                        {projectObject.Participant.map(participant => 
                            <MenuItem key={`handover-participant-${participant.id}`} value={participant.id}>

                                {getCustomerTextTsx(participant)}
                                {getJustDownloadTsx(participant)}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </DialogContent>
                <DialogActions>
                    {(is_superuser) && 
                        <Button 
                            variant="outlined" 
                            onClick={callResetDocument}
                            disabled={editHandoverDocumentArray.find(
                                    x => x.Customer === projectObject.Participant.find(y => y.id === selectedCustomer)?.Customer
                                )?.Document_Signed == undefined
                        }
                        >Zurücksetzen</Button>
                    }
                    <Button variant="outlined" onClick={() => setIsOpenSelectCustomer(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSelected}>Öffnen</Button>
                </DialogActions>
            </Dialog>

            <IconButton onClick={() => setIsOpenSelectCustomer(true)}><NoteAlt/></IconButton>
            <IconButton onClick={handleDownloadAll}><Download/></IconButton>
        </>
    )
}