import React, { useEffect, useState } from "react";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";
import { IPartner } from "../../../interfaces/IPartner";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import { IOffer } from "../../../interfaces/IOffer";
import { IUnit } from "../../../interfaces/IUnit";
import { IHandoverProtocolPartner } from "../../../interfaces/IHandoverProtocolPartner";
import { CustomeTextField } from "../../core/CustomeTextField";


interface IProps {
    offerPosObject: IOfferPosition;
    offerArray: IOffer[];
    offerPosArray: IOfferPosition[];
    setOfferPosArray: Function;
    partnerArray: IPartner[];
    handoverPartnerArray: IHandoverProtocolPartner[];
    unitArray: IUnit[];
}


export const HandoverPartnerPosRow:React.FC<IProps> = ({offerPosObject, offerArray,offerPosArray, setOfferPosArray, partnerArray, handoverPartnerArray, unitArray}) => {
    const [editOfferPosition,setEditOfferPosition] = useState(offerPosObject);

    /*
    useEffect(() => {
        if (editOfferPosition.Partner === null && handoverPartnerArray.length > 0) {
            setEditOfferPosition({...editOfferPosition, Partner: handoverPartnerArray[0].Partner})

        }
    },[handoverPartnerArray])
    */

    /// ueseEffect: Aktualiseren bei Veränderung
    useEffect(() => {
        let updatedPos = {...editOfferPosition}
        setOfferPosArray([
            ...offerPosArray.map(x => x.id === updatedPos.id ? updatedPos : x)
        ])
    },[editOfferPosition])


    return(
        <>
            <TableRow>
                <TableCell>{offerArray.find(x => x.id === offerPosObject.Offer)?.title}</TableCell>
                <TableCell>{offerPosObject.articelNumber}</TableCell>
                <TableCell>
                    <CustomeTextField
                        label="Partner"
                        attr="Partner"
                        object={(editOfferPosition.Partner === null ? {...editOfferPosition, Partner: -1} : editOfferPosition)}
                        setObject={
                            (localOfferPos:IOfferPosition) => localOfferPos.Partner === -1 
                            ? setEditOfferPosition({...localOfferPos, Partner: null})
                            : setEditOfferPosition(localOfferPos)
                        }
                        type="select"
                        size="small"
                        required
                        null
                    >
                        {
                            handoverPartnerArray.map(offerPartner => {
                                let currentParter = partnerArray.find(x => x.id === offerPartner.Partner);

                                if (currentParter === undefined) {
                                    return(
                                        <MenuItem key={`partner-null`} value={-1}>
                                            <i>Kein Partner ausgewählt</i>
                                        </MenuItem>
                                    )

                                }
                                else{
                                    return(
                                        <MenuItem key={`partner-${currentParter.id}`} value={currentParter.id}>
                                            {currentParter.Contact_Person.Company?.company_name}
                                        </MenuItem>
                                    )

                                }
                            })
                        }
                    </CustomeTextField>
                </TableCell>
                <TableCell>{offerPosObject.title}</TableCell>
                <TableCell>{unitArray.find(x => x.id === offerPosObject.Unit)?.unit}</TableCell>
                <TableCell>{offerPosObject.units}</TableCell>
                
            </TableRow>
        </>
    )
}