import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId, GridRowParams, deDE } from "@mui/x-data-grid";
import { IPartner } from "../../../interfaces/IPartner";
import { BillPartnerTableRow } from "./BillPartnerTableRow";
import { IBill } from "../../../interfaces/IBill";
import { IBillPartner } from "../../../interfaces/IBillPartner";

const columns: GridColDef[] = [
    { 
        field: 'id', 
        headerName: 'Nr.', 
        width: 70 
    },
    { 
        field: 'selling_price', 
        headerName: 'Stundenpreis', 
        renderCell: (params: GridRenderCellParams<any, IPartner>) => params.row.selling_price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
        width: 150
    },
    { 
        field: 'Company', 
        headerName: 'Firma', 
        renderCell: (params: GridRenderCellParams<any, IPartner>) => params.row.Contact_Person.Company.company_name,
        flex: 1
    },
    { 
        field: 'Ansprechpartner', 
        headerName: 'Ansprechpartner', 
        renderCell: (params: GridRenderCellParams<any, IPartner>) => `${params.row.Contact_Person.firstname} ${params.row.Contact_Person.lastname}`,
        flex: 1
    },
];

interface IProps {
    billObject: IBill;
    partnerArray: IPartner[];
    billPartner: IBillPartner[];
    setBillPartner: Function;
}

export const BillPartnerTable: React.FC<IProps> = ({ billObject, partnerArray, billPartner, setBillPartner }) => {
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [addNewPartner, setAddNewPartner] = useState<GridRowId[]>([]);

    const handleAddNew = () => {
        setAddNewPartner([]);
        setIsOpenAdd(true);
    }

    const handleAddBtn = () => {
        let localBillPartnerArray = [...billPartner];

        addNewPartner.map(x => {
            let testObject = partnerArray.find(y => y.id === Number(x));

            if (testObject !== undefined) {
                localBillPartnerArray.push({
                    Bill: -1,
                    Partner: testObject.id,
                    selling_price_bill: testObject.selling_price
                })
            }
        })

        setBillPartner([...localBillPartnerArray]);
        setIsOpenAdd(false);
    }

    return (
        <>
            <Dialog open={isOpenAdd} onClose={() => setIsOpenAdd(false)} maxWidth="md" fullWidth>
                <DialogTitle>Partner hinzufügen</DialogTitle>
                <DialogContent>
                    <DataGrid
                        sx={{ mt: 2 }}
                        autoHeight
                        checkboxSelection
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={partnerArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setAddNewPartner([
                                ...newRowSelectionModel
                            ])
                        }}
                        rowSelectionModel={addNewPartner}
                        columns={columns}
                        isRowSelectable={(params: GridRowParams) => billPartner.map(x => x.Partner).indexOf(Number(params.id)) === -1 }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenAdd(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleAddBtn}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>

            <Typography variant="h6">
                Zugeordnete Partner
                <Button variant="outlined" sx={{ float: "right" }} onClick={handleAddNew}>Partner zuordnen</Button>
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 50 }}></TableCell>
                        <TableCell>Partner</TableCell>
                        <TableCell sx={{ width: 250 }}>Originaller Stundenpreis</TableCell>
                        <TableCell sx={{ width: 250 }}>Gesetzter Stundenpreis</TableCell>
                        <TableCell sx={{ width: 50 }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {billPartner.map(x =>
                        <BillPartnerTableRow
                            billObject={billObject}
                            key={`billpartner-${x.Partner}`}
                            billPartner={x}
                            partnerArray={partnerArray}
                            billPartnerArray={billPartner}
                            setBillPartnerArray={setBillPartner}
                        />
                    )}
                </TableBody>
            </Table>
        </>
    )
}
