import React, { useEffect, useState } from "react";
import { IInsurance } from "../../../interfaces/IInsurance";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { IPostcode } from "../../../interfaces/IPostcode";
import { getFetch } from "../../../hooks/useFetch";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { IPropertyManagment } from "../../../interfaces/IPropertyManagement";



interface IProps {
    is_tened:boolean;
    propertyManagement: number | null;
    propertyManagementArray: IPropertyManagment[];
}



export const PropertyManagementContactCard:React.FC<IProps> = ({is_tened, propertyManagement, propertyManagementArray}) => {
    const [propertyManagementObject,setPropertyManagementObject] = useState(propertyManagementArray.find(x => x.id === propertyManagement));
    const [postcodeObject,setPostcodeObject] = useState<IPostcode | undefined>();


    useEffect(() => {
        console.log("UPDATZE!")
        console.log(propertyManagementArray.find(x => x.id === propertyManagement))
        setPropertyManagementObject(propertyManagementArray.find(x => x.id === propertyManagement))
    },[propertyManagement])

    useEffect(() => {
        if (propertyManagementObject?.Contact_Person?.Company?.Address?.Postcode !== undefined) {
            getFetch("/postcode/",propertyManagementObject?.Contact_Person?.Company?.Address?.Postcode,setPostcodeObject)
        }
    },[propertyManagementObject?.Contact_Person?.Company?.Address?.Postcode])

    const checkNoneString = (text:string|undefined|null)  => {
        if (text === undefined || text === null) {
            return <>-</>
        } else {
            return text
        }
    }


    if(propertyManagementObject === undefined) {
        return(
            <>
            <Accordion disabled>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    id="panel1-header"
                >
                    Kontaktinformationen der Hausverwaltung
                </AccordionSummary>
            </Accordion>                
            </>
        )
    }
    else {
        return(
            <Accordion>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    id="panel1-header"
                >
                    Kontaktinformationen der Hausverwaltung
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Card variant="outlined">
                            <CardContent>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2}><b>{propertyManagementObject.Contact_Person.Company?.company_name}</b></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"50%"}><b>Tel.:</b> <a href={`tel:${propertyManagementObject.Contact_Person.Company?.telefon}`}>{propertyManagementObject.Contact_Person.Company?.telefon}</a></TableCell>
                                                <TableCell><b>E-Mail:</b> <a href={`mailto:${propertyManagementObject.Contact_Person.Company?.email}`}>{propertyManagementObject.Contact_Person.Company?.email}</a></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <b>Adresse:</b> {checkNoneString(propertyManagementObject.Contact_Person.Company?.Address?.street)} {checkNoneString(propertyManagementObject.Contact_Person.Company?.Address?.street_number)}, {checkNoneString(postcodeObject?.postcode)} {checkNoneString(postcodeObject?.city)}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>



                                    <Table size="small" sx={{mt: 3}}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2}><b>Ansprechpartner</b> {propertyManagementObject.Contact_Person.firstname} {propertyManagementObject.Contact_Person.lastname}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"50%"}><b>Tel.:</b> <a href={`tel:${propertyManagementObject.Contact_Person?.telefon}`}>{propertyManagementObject.Contact_Person?.telefon}</a></TableCell>
                                                <TableCell><b>E-Mail:</b> <a href={`mailto:${propertyManagementObject.Contact_Person?.email}`}>{propertyManagementObject.Contact_Person?.email}</a></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                            </CardContent>
                        </Card>
                    </Box>
                </AccordionDetails>
            </Accordion>
        )
    }

}