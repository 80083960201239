import React, { useEffect, useState } from "react";
import { IHandoverProtocolPosition } from "../../../interfaces/IHandoverProtocolPosition";
import { IUnit } from "../../../interfaces/IUnit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { Delete } from "@mui/icons-material";


interface IProps {
    handoverPosition: IHandoverProtocolPosition;
    handoverPositionArray: IHandoverProtocolPosition[];
    setHandoverPositionArray: Function;
    unitArray: IUnit[];
}


export const HandoverPositionRow:React.FC<IProps> = ({handoverPosition, handoverPositionArray, setHandoverPositionArray, unitArray}) => {
    const [editHandoverPosition,setEditHandoverPosition] = useState(handoverPosition);


    useEffect(() => {
        setHandoverPositionArray([
            ...handoverPositionArray.map(x => x.id === editHandoverPosition.id ? editHandoverPosition : x)
        ])
    },[editHandoverPosition])

    const handleRemoveMe = () => {
        setHandoverPositionArray([
            ...handoverPositionArray.filter(x => x.id !== editHandoverPosition.id)
        ])
    }


    return(
        <>
            <TableRow>
                <TableCell>{editHandoverPosition.articelNumber}</TableCell>
                <TableCell>{editHandoverPosition.title}</TableCell>
                <TableCell>{editHandoverPosition.units}</TableCell>
                <TableCell>{unitArray.find(x => editHandoverPosition.Unit)?.unit}</TableCell>
                <TableCell>
                    <CustomeTextField
                        label="IST-Mente"
                        type="number"
                        attr="real_units"
                        object={editHandoverPosition}
                        setObject={setEditHandoverPosition}
                    />
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleRemoveMe}><Delete/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}