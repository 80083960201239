import React, { useEffect, useState } from "react";
import { deleteFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import { IMaterial } from "../../interfaces/IMaterial";
import {Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, TextField, Typography, useScrollTrigger} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, deDE } from "@mui/x-data-grid";
import { IMaterialCategory } from "../../interfaces/IMaterialCategory";
import { IUnit } from "../../interfaces/IUnit";
import { MaterialEdit } from "../../components/material/MaterialEdit";
import { CustomSnackbar } from "../../components/core/Snackbar";
import { IFeedback } from "../../interfaces/IFeedback";
import { Delete } from "@mui/icons-material";
import { usePermissions } from "../../contexts/permissions/PermissionContext";
import { IPartner } from "../../interfaces/IPartner";
import { IInsurance } from "../../interfaces/IInsurance";
import { IPropertyManagment } from "../../interfaces/IPropertyManagement";
import { ITitle } from "../../interfaces/ITitle";
import { IGender } from "../../interfaces/IGender";
import { IAddressType } from "../../interfaces/IAddress";
import { IMaterialSubcategory } from "../../interfaces/IMaterialSubcategory";
import { IMaterialDocumentCategory } from "../../interfaces/IMaterialDocumentCategory";
import { IMIMEType } from "../../interfaces/IMIMEType";

const getEmptyMaterial = () => {
    return {
        id: -1,
        title: "",
        MaterialCategory: 1,
        MaterialSubcategory: 1,
        articelNumber: "0",
        europeanArticleNumber: null,
        stockKeepingUnit: null,
        description: "",
        minimumOrder: 0,
        purchasing_price:0,
        retail_price:0,
        margin:0,
        Merchant: [],
        amount: 0,
        Unit: 1,
        MaterialSupplierArray: []
    } as IMaterial
}

export const MaterialOverview:React.FC = () => {
    const [materialArray, setMaterialArray, statusCodeMaterial] = useFetch<IMaterial[]>("/material/");
    const [materialCategoryArray, setMaterialCategoryArray, statusCodeMaterialCategory] = useFetch<IMaterialCategory[]>("/material/category/");
    const [materialSubcategoryArray, setMaterialSubcategoryArray, statusCodeMaterialSubcategory] = useFetch<IMaterialSubcategory[]>("/material/subcategory/");
    
    const [mimeTypeArray, setMimeTypeArray, statusCodeMimeType] = useFetch<IMIMEType[]>("/document/mimetype/");
    const [materialDocumentCategoryArray, setMaterialDocumentCategory, statusCodematerialDocumentCategory] = useFetch<IMaterialDocumentCategory[]>("/material/document/category/");
    //
    const [partnerArray, setPartnerArray, statusCodePartner] = useFetch<IPartner[]>("/partner/");

    const [unitArray, setUnitArray, statusCodeUnit] = useFetch<IUnit[]>("/unit/");
    const [addressTypeArray, setAddressTypeArray, statusCodeAddressType ] = useFetch<IAddressType[]>("/address/types/");
    const [genderData, setGenderData, statusCodeGender ] = useFetch<IGender[]>("/customer/gender/");
    const [titleData, setTitleData, statusCodeAcademicTitle ] = useFetch<ITitle[]>("/customer/academic-title/");
    const [insuranceArray, setInsuranceData, statusCodeInsurance ] = useFetch<IInsurance[]>("/insurance/")
    const [propertyManagementArray, setPropertyManagementArray, statusCodePropertyManagement] = useFetch<IPropertyManagment[]>("/property_management/");
    //
    const [editMaterial,setEditMaterial] = useState(getEmptyMaterial());
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoadgin,setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    //
    const [feedback,setFeedback] = useState<IFeedback>({ open: false,message: "Gespeichert!", severity: "success" } as IFeedback)

    const [searchTerm, setSearchTerm] = useState("");

    //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [materialIdToDelete, setMaterialIdToDelete] = React.useState<number | null>(null);
    const is_superuser = localStorage.getItem("is_superuser")
    const { permissions } = usePermissions();

    const openDeleteConfirmationDialog = (materialId: number) => {
        setMaterialIdToDelete(materialId);
        setOpenDeleteDialog(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setMaterialIdToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleRemove = (materialId: number) => {
        if (is_superuser === "true"){
            deleteFetch("/material/", materialId,setWasSuccessfully)
            setFeedback({ open: true,message: "Material erfolgreich gelöscht!", severity: "success" })
            const newArray = (materialArray === undefined) ? "" : materialArray.filter(material => material.id !== materialId);
            setMaterialArray(newArray)
            closeDeleteConfirmationDialog();
        }else{
            console.log("You have no permission.")
        }   
    };
//END OF DELETE PATTERNS

    const columns: GridColDef[] = [
        { 
            field: 'articelNumber', 
            headerName: 'Art.Nr.', 
            width: 100 
        },
        { 
            field: 'MaterialCategory', 
            headerName: 'Kategorie', 
            valueGetter: (params) => (materialCategoryArray !== undefined) && materialCategoryArray.find(x => x.id === params.row.MaterialCategory)?.materialCategory,
            width: 200 
        },
        { 
            field: 'MaterialSubcategory', 
            headerName: 'Unterkategorie', 
            valueGetter: (params) => (materialSubcategoryArray !== undefined) && materialSubcategoryArray.find(x => x.id === params.row.MaterialSubcategory)?.materialSubcategory,
            width: 200 
        },
        { 
            field: 'Unit', 
            headerName: 'Einheit', 
            valueGetter: (params) => (unitArray !== undefined) && unitArray.find(x => x.id === params.row.Unit)?.unit,
            width: 90 
        },
        { 
            field: 'amount', 
            headerName: 'Menge',
            width: 90 
        },
        { 
            field: 'title', 
            headerName: 'Bezeichnung', 
            flex: 1
        },
        { 
            field: 'action', 
            headerName: 'Aktionen', 
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return (
                    <> {(is_superuser == "true" || permissions.includes("delete_material")) && <>
                        <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                            <Delete />
                        </IconButton>
                        <Dialog
                            open={openDeleteDialog && materialIdToDelete === params.row.id}
                            onClose={closeDeleteConfirmationDialog}
                        >
                            <DialogTitle>Bestätigung Löschung</DialogTitle>
                            <DialogContent>
                                Sind Sie sicher, dass Sie das Material in folgender Kategorie "{params.row.MaterialCategory}" & Artikelnummer "{params.row.articelNumber}" löschen wollen?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                    Abbruch
                                </Button>
                                <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                    Bestätigen
                                </Button>
                            </DialogActions>
                        </Dialog></>}
                    </>
                );
            },
            width: 90 
        },

    ]
    

    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = materialArray?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditMaterial(getEmptyMaterial())
        } else {
            setEditMaterial(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditMaterial(getEmptyMaterial());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject:IMaterial) => {
        if (materialArray !== undefined) {
            setFeedback({ open: true,message: "Gespeichert!", severity: "success" } )
        
            let testObject = materialArray.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setMaterialArray([
                    ...materialArray,
                    savedObject
                ])
            }
            else {
                setMaterialArray([
                    ...materialArray.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }

    
    const handleSave = () => {
        setWasSuccessfully(true);
        console.log(editMaterial)
        if (editMaterial.id < 0) {
            uploadFetch("/material/",true,editMaterial,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        } else {
            uploadFetch(`/material/${editMaterial.id}`,false,editMaterial,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({ open: true,message: "Ein fehler ist aufgetretten!", severity: "error" } )
            setIsLoading(false);
        }
    },[wasSuccessfully])

    const handleSearchTerm = () => {
        if (materialArray === undefined) {
            return [];
        }
        else if (searchTerm === "") {
            return materialArray
        } else {
            return materialArray.filter(x =>
                String(x.description).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }



    if (
        (statusCodeAcademicTitle !== null && statusCodeAcademicTitle !== 200)
        || (statusCodeMaterial !== null && statusCodeMaterial !== 200)
        || (statusCodeMaterialCategory !== null && statusCodeMaterialCategory !== 200)
        || (statusCodeMaterialSubcategory !== null && statusCodeMaterialSubcategory !== 200)
        || (statusCodePartner !== null && statusCodePartner !== 200)

        || (statusCodeAddressType !== null && statusCodeAddressType !== 200)
        || (statusCodeGender !== null && statusCodeGender !== 200)
        
        || (statusCodeInsurance !== null && statusCodeInsurance !== 200)
        || (statusCodePropertyManagement!== null && statusCodePropertyManagement !== 200)

        || (statusCodeUnit !== null && statusCodeUnit !== 200)

        || (statusCodematerialDocumentCategory !== null && statusCodematerialDocumentCategory !== 200)
        || (statusCodeMimeType !== null && statusCodeMimeType !== 200)
    ) { return <Alert severity="error">Fehler!</Alert> }
    
    else if (
        genderData === undefined
        || materialArray === undefined
        || materialCategoryArray === undefined
        || materialSubcategoryArray === undefined

        || partnerArray === undefined

        || titleData === undefined
        || addressTypeArray === undefined
        || insuranceArray === undefined
        || propertyManagementArray === undefined

        || unitArray === undefined

        || materialDocumentCategoryArray === undefined
        || mimeTypeArray === undefined
    ) { return <LinearProgress/> }
    else {
        return(
            <>
                <Typography variant="h5">
                    Materialverzeichnis
                   {permissions.includes("add_material") && <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Neues Material</Button> }
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />


                <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md" fullWidth>
                    <DialogContent>
                        <MaterialEdit
                            key={`editMaterial-${editMaterial.id}`}
                            materialObject={editMaterial}
                            setMaterialObject={setEditMaterial}
                            materialCategoryArray={materialCategoryArray}
                            materialSubcategoryArray={materialSubcategoryArray}
                            unitArray={unitArray}
                            partnerArray={partnerArray}
                            setPartnerArray={setPartnerArray}
                            gegenderArray={genderData}
                            setPropertyManagementArray={setPropertyManagementArray}
                            setInsuranceArray={setInsuranceData}
                            titleData={titleData}
                            addressTypeArray={addressTypeArray}
                            insuranceArray={insuranceArray}
                            propertyManagementArray={propertyManagementArray}

                            mimeTypeArray={mimeTypeArray}
                            materialDocumentCategory={materialDocumentCategoryArray}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{float: "right"}} onClick={() => setIsOpen(false)} variant="outlined">Abbruch</Button>
                        <Button sx={{float: "right", ml: 2}} onClick={handleSave} variant="contained" disabled={isLoadgin}>Speichern</Button>
                        
                    </DialogActions>
                </Dialog>

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={handleSearchTerm()}
                            columns={columns}
                            //getRowId={(row) => row.id}
                            onRowClick={(params, event, details) => {
                                if(permissions.includes("change_material")){
                                const target = event.target as HTMLElement;
                                const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                                if (isDeleteIconClicked) {
                                    event.stopPropagation();
                                    openDeleteConfirmationDialog(Number(params.id));
                                } else {
                                    handleChangeId(params)
                                }
                            }}}
                            autoHeight
                        />
                    </Grid>
                </Grid>
            </>
        )
    }


}
