import React, { useEffect, useState } from "react";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";
import { IPartner } from "../../../interfaces/IPartner";
import { IService } from "../../../interfaces/IService";
import { IServiceCategory } from "../../../interfaces/IServiceCategory";
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Table, TableCell, TableRow } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { IUnit } from "../../../interfaces/IUnit";
import { ArrowDownward, ArrowUpward, Delete, Edit, RestartAlt, Warning } from "@mui/icons-material";
import { IOfferPartner } from "../../../interfaces/IOfferPartner";
import { IOffer } from "../../../interfaces/IOffer";


interface IProps {
    offerObject: IOffer;
    offerPositionObject: IOfferPosition;
    offerPositionArray: IOfferPosition[];
    setOfferPosition: Function;
    offerPartnerArray: IOfferPartner[];
    partnerArray: IPartner[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
    unitArray: IUnit[];
}


export const OfferPositionRow:React.FC<IProps> = ({
    offerObject,
    offerPositionObject, 
    offerPositionArray, 
    setOfferPosition, 
    offerPartnerArray, 
    partnerArray, 
    serviceArray, 
    serviceCategoryArray,
    unitArray
})  => {
    const [editOfferPosition,setEditOfferPosition] = useState(offerPositionObject);
    const [currentPostition,setCurrentPostition] = useState(offerPositionObject.position);
    //
    const [hasWarning,setHasWarning] = useState(false);
    const [hasWarningPartner, setHasWarningPartner] = useState(false);
    //
    const [isOpenEditDescription, setIsOpenEditDescription] = useState(false);
    
    


    const calcPrice = (returnAsString:boolean = true, returnAsObject : boolean = false) => {
        let normalPrice = editOfferPosition.price_per_hour * editOfferPosition.timeFactor;

        if (editOfferPosition.wasChangedPrice) {
            normalPrice = editOfferPosition.price
        }

        if (returnAsString) {
            return normalPrice.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
        }
        else {
            if (returnAsObject) {
                console.log("Object")
                let returnObject:IOfferPosition = {
                    ...editOfferPosition,
                    price: normalPrice
                }
                console.log(returnAsObject)
                return returnObject;
            } else {
                return normalPrice;
            }
            
        }
    }


    const calcTotalPrice = () => {
        let normalPrice = calcPrice(false);
        let totalPrice = Number(normalPrice) * editOfferPosition.units;
        return totalPrice.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
    }


    /// ueseEffect: Aktualiseren bei Veränderung
    useEffect(() => {
        let updatedPos = {...editOfferPosition, position: currentPostition}
        setOfferPosition([
            ...offerPositionArray.map(x => x.id === updatedPos.id ? updatedPos : x)
        ])
    },[editOfferPosition])


    // Zusammenfassen aller Warning
    useEffect(() => {
        setHasWarning(
            hasWarningPartner 
            || editOfferPosition.wasChangedPricePerHour 
            || editOfferPosition.wasChangedTimeFactor 
            || editOfferPosition.wasChangedTitle
            || editOfferPosition.wasChangedPrice
        )
    },[hasWarningPartner,editOfferPosition.wasChangedPricePerHour,editOfferPosition.wasChangedTimeFactor, editOfferPosition.wasChangedTitle, editOfferPosition.wasChangedPrice])


    // Warning bzgl. PArtner
    useEffect(() => {
        setHasWarningPartner(offerPartnerArray.map(x => x.Partner).indexOf(editOfferPosition.Partner) <= -1)
    },[offerPartnerArray])

    //

    useEffect(() => {
        if (editOfferPosition.wasChangedPricePerHour === false) {
            let foundOfferPartner = offerPartnerArray.find(x => x.Partner === editOfferPosition.Partner);

            if (foundOfferPartner !== undefined) {
                setEditOfferPosition({
                    ...editOfferPosition,
                    price_per_hour: foundOfferPartner.selling_price_offer
                } as IOfferPosition)
            }
        }
    },[editOfferPosition.Partner,offerPartnerArray])


    const getIsMax = () => {
        let posFromArray = Math.max(...offerPositionArray.map(x => x.position));
        return (editOfferPosition.position === posFromArray);
    }


    const handleUp = () => {
        let currentArray = [...offerPositionArray];
        let changedObject = currentArray.find(x => x.position === editOfferPosition.position-1)
        let localEditOfferPosition = {...editOfferPosition}

        if (changedObject !== undefined) {
            changedObject = {...changedObject, position: localEditOfferPosition.position}
            localEditOfferPosition = {...localEditOfferPosition, position: localEditOfferPosition.position-1}
            setCurrentPostition(localEditOfferPosition.position-1);
            setOfferPosition([
                ...currentArray
                    .map(x => x.id === changedObject!.id ? changedObject : x.id === localEditOfferPosition.id ? localEditOfferPosition : x)
            ])
        }

    }


    const handleDown= () => {
        let currentArray = [...offerPositionArray];
        let changedObject = currentArray.find(x => x.position === editOfferPosition.position+1)
        let localEditOfferPosition = {...editOfferPosition}

        if (changedObject !== undefined) {
            changedObject = {...changedObject, position: localEditOfferPosition.position}
            localEditOfferPosition = {...localEditOfferPosition, position: localEditOfferPosition.position+1}
            setCurrentPostition(localEditOfferPosition.position+1);
            setOfferPosition([
                ...currentArray
                    .map(x => x.id === changedObject!.id ? changedObject : x.id === localEditOfferPosition.id ? localEditOfferPosition : x)
            ])
        }

    }


    const handleRemoveMe = () => {
        setOfferPosition([
            ...offerPositionArray
                .filter(x => x.id !== editOfferPosition.id)
                .map(x => x.position > editOfferPosition.position ? {...x, position: x.position-1 } : x)
        ])
    }


    const resetChangedValues = () => {
        let foundOfferPartner = offerPartnerArray.find(x => x.Partner === editOfferPosition.Partner);
        let foundService = serviceArray.find(x => x.id === editOfferPosition.Service);
        let localEditObject = {...editOfferPosition}

        if (foundOfferPartner !== undefined && foundService !== undefined) {
            localEditObject = {
                ...localEditObject,
                timeFactor: foundService.timeFactor,
                price_per_hour: foundOfferPartner.selling_price_offer,
                price: 0,
                title: foundService.title,
                wasChangedPricePerHour: false,
                wasChangedTimeFactor: false,
                wasChangedTitle: false,
                wasChangedPrice: false
            }
            setEditOfferPosition({...localEditObject});
        }
    }

    
    return(
        <>
            <Dialog open={isOpenEditDescription} onClose={() => setIsOpenEditDescription(false)} maxWidth="md" fullWidth> 
                <DialogTitle>Erweiterte Einstellungen</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}/>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Partner"
                                attr="Partner"
                                object={(editOfferPosition.Partner === null ? {...editOfferPosition, Partner: -1} : editOfferPosition)}
                                setObject={
                                    (localOfferPos:IOfferPosition) => localOfferPos.Partner === -1 
                                    ? setEditOfferPosition({...localOfferPos, Partner: null})
                                    : setEditOfferPosition(localOfferPos)
                                }
                                type="select"
                                size="small"
                                required
                                disabled={offerObject.OfferState > 2}
                                null
                            >
                                {
                                    offerPartnerArray.map(offerPartner => {
                                        let currentParter = partnerArray.find(x => x.id === offerPartner.Partner);

                                        if (currentParter === undefined) {
                                            return(
                                                <MenuItem key={`partner-null`} value={-1}>
                                                    <i>Kein Partner ausgewählt</i>
                                                </MenuItem>
                                            )

                                        }
                                        else{
                                            return(
                                                <MenuItem key={`partner-${currentParter.id}`} value={currentParter.id}>
                                                    {currentParter.Contact_Person.Company?.company_name}
                                                </MenuItem>
                                            )

                                        }
                                    })
                                }
                            </CustomeTextField>
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Stundenpreis"
                                attr="price_per_hour"
                                object={editOfferPosition}
                                setObject={(editedObject:IOfferPosition) => setEditOfferPosition({
                                    ...editedObject,
                                    wasChangedPricePerHour: true
                                })}
                                type="number"
                                size="small"
                                required
                                disabled={offerObject.OfferState > 2}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Beschreibung"
                                attr="description"
                                object={editOfferPosition}
                                setObject={setEditOfferPosition}
                                type="string"
                                size="small"
                                required
                                disabled={offerObject.OfferState > 2}
                                rows={10}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpenEditDescription(false)}>Okay</Button>
                </DialogActions>
            </Dialog>


            <TableRow sx={{backgroundColor: (hasWarning) ? "#ffffe7" : "#f8f8f8"}}>
                <TableCell>
                    <Collapse in={editOfferPosition.wasChangedPrice}>
                        <span title="Der Preis wurde händisch angepasst!">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>
                    <Collapse in={editOfferPosition.wasChangedTitle}>
                        <span title="Der Titel wurde händisch angepasst!">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>
                    <Collapse in={hasWarningPartner}>
                        <span title="Der Parnter kann nicht bestimmt werden">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>

                    <Collapse in={editOfferPosition.wasChangedPricePerHour || editOfferPosition.wasChangedTimeFactor}>
                        <span title="Der Zeitfaktor, der Stundenpreis und/oder der Titel wurden händisch angepasst!">
                            <Warning sx={{color: "warning.main"}} fontSize="small"/>
                        </span>
                    </Collapse>
                </TableCell>

                <TableCell sx={{p: 0}}>{editOfferPosition.articelNumber}</TableCell>


                {/*
                <TableCell>
                    <CustomeTextField
                        label="Partner"
                        attr="Partner"
                        object={editOfferPosition}
                        setObject={setEditOfferPosition}
                        type="select"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                    >
                        {
                            partnerArray
                                .filter(x => offerPartnerArray.map(x => x.Partner).indexOf(x.id) > -1)
                                .map(x =>
                                    <MenuItem key={`partner-${x.id}`} value={x.id}>
                                        {x.Contact_Person.Company?.company_name}
                                    </MenuItem>
                                )
                        }
                    </CustomeTextField>
                </TableCell>
                 */}



                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Position"
                        attr="title"
                        object={editOfferPosition}
                        setObject={(editedObject:IOfferPosition) => setEditOfferPosition({
                            ...editedObject,
                            wasChangedTitle: true
                        })}
                        type="string"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                        //rows={3}
                    />
                </TableCell>


                <TableCell sx={{p: 0}}>
                    <Collapse in={offerObject.OfferState <= 2}>
                        <Box sx={{display: "flex"}}>
                            {/*
                            <IconButton title="Position nach oben" onClick={handleUp} size="small" disabled={currentPostition === 1}><ArrowUpward/></IconButton>
                            <IconButton title="Position nach unten" onClick={handleDown} size="small" disabled={getIsMax()}><ArrowDownward/></IconButton>
                             */}

                        </Box>

                            <IconButton title="Alle Werte zurücksetzen" onClick={resetChangedValues} size="small" disabled={editOfferPosition.wasChangedPricePerHour === false && editOfferPosition.wasChangedTimeFactor === false && editOfferPosition.wasChangedTitle === false && editOfferPosition.wasChangedPrice === false}><RestartAlt/></IconButton>
                            <IconButton title="Beschreibungstext anpassen" onClick={() => setIsOpenEditDescription(true)} size="small"><Edit/></IconButton>

                    </Collapse>
                </TableCell>


                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Zeitfaktor"
                        attr="timeFactor"
                        object={editOfferPosition}
                        setObject={(editedObject:IOfferPosition) => setEditOfferPosition({
                            ...editedObject,
                            wasChangedTimeFactor: true
                        })}
                        type="number"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                    />
                </TableCell>

                {/*
                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Stundenpreis"
                        attr="price_per_hour"
                        object={editOfferPosition}
                        setObject={(editedObject:IOfferPosition) => setEditOfferPosition({
                            ...editedObject,
                            wasChangedPricePerHour: true
                        })}
                        type="number"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                    />
                </TableCell>
                 */}



                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Einheiten"
                        attr="units"
                        object={editOfferPosition}
                        setObject={setEditOfferPosition}
                        type="number"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                    />
                </TableCell>


                <TableCell sx={{p: 0}}>{unitArray.find(x => x.id === editOfferPosition.Unit)?.unit}</TableCell>

                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Preis"
                        attr="price"
                        object={ {...editOfferPosition, price: calcPrice(false, false)} }
                        setObject={(editedObject:IOfferPosition) => setEditOfferPosition({
                            ...editedObject,
                            wasChangedPrice: true
                        })}
                        type="euro"
                        size="small"
                        required
                        disabled={offerObject.OfferState > 2}
                    />
                </TableCell>

                {/*<TableCell>{calcPrice()}</TableCell>*/}

                <TableCell sx={{p: 0}}>{calcTotalPrice()}</TableCell>

                <TableCell sx={{p: 0}}>
                    <Collapse in={offerObject.OfferState <= 2}>
                        <Box sx={{display: "flex"}}>
                            <IconButton size="small" onClick={handleRemoveMe}><Delete/></IconButton>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}