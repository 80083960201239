import { createContext, useContext, Dispatch, SetStateAction } from 'react';

interface PermissionsContextType {
  permissions: string[];
  setPermissions: Dispatch<SetStateAction<string[]>>;
  loading: boolean;
}

const defaultPermissionContext: PermissionsContextType = {
  permissions: [],
  setPermissions: () => {},
  loading: true,
};

const PermissionsContext = createContext<PermissionsContextType>(defaultPermissionContext);

const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};

export { PermissionsContext, usePermissions };
