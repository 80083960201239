export const getTmpId = (tmpArray:any[] | undefined = undefined) => {
    let tmpId = -1;

    if (tmpArray !== undefined && tmpArray.length > 0) {
        let tmpIdArray = Math.min(...tmpArray.map(x => x["id"])) -1;

        if (tmpIdArray <= tmpId) {
            tmpId = tmpIdArray -1;
        }

    }

    return tmpId;
}


export const insertIntoArray = (currentObject:any,currentArray:any[]) => {
    let tmpObject = currentArray.find(x => x.id === currentObject.id);
    let tmpArray = [...currentArray]

    if (tmpObject === undefined) {
        console.log("NEU")
        tmpArray = [...tmpArray,currentObject]
    }
    else {
        tmpArray = tmpArray.map(x => x.id === currentObject.id ? currentObject : x)
    }
    console.log("???")
    console.log(currentObject)
    console.log(tmpArray)
    return tmpArray
}