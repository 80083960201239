import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId, GridRowParams, deDE } from "@mui/x-data-grid";
import { IProject } from "../../../interfaces/IProject";
import { uploadFetch } from "../../../hooks/useFetch";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";
import { IProfile, IUser } from "../../../interfaces/IUser";
import { WorkerTableRow } from "./WorkerTableRow";
import { getEmptyWorker } from "../../../sites/RoleManagement/RoleManagementOverview";


const columns: GridColDef[] = [
  { 
      field: 'number', 
      headerName: 'Nr.',
      valueGetter: (params) => params.row.id,
      width: 70 
  },
  { 
    field: 'username', 
    headerName: 'Benutzername',
    valueGetter: (params) => params.row.User.username,
    width: 200 
    },
  { 
      field: 'first_name', 
      headerName: 'Vorname', 
      valueGetter: (params) => params.row.User.first_name,
      width: 200 
  },
  { 
      field: 'last_name', 
      headerName: 'Name', 
      valueGetter: (params) => params.row.User.last_name,
      width: 200 
  },
]

interface IProps {
    projectObject: IProject;
    setProjectObject: Function;
    workerArray: IProfile[]
    worker: number[];
}


export const WorkerTable: React.FC<IProps> = ({
    projectObject,
    setProjectObject,
    workerArray,
    worker,
}) => {

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenNewWorker, setIsOpenNewWorker] = useState(false);

  const [addNewWorker, setAddNewWorker] = useState<number[]>([]);
  const [editWorker, setEditWorker] = useState(getEmptyWorker());

  const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 
  const [wasSuccessfully,setWasSuccessfully] = useState(true);
  const [isLoadgin, setIsLoading] = useState(false);


  useEffect(() => {
    if (!wasSuccessfully) {
        setFeedback({ open: true,message: "Ein fehler ist aufgetretten!", severity: "error" } )
        setIsLoading(false);
    }
},[wasSuccessfully])

const handleAddNew = () => {
    setAddNewWorker([]);
    setIsOpenAdd(true);
  };

const handleAddBtn = () => {
    const newWorker = [
        ...projectObject.Worker,
        ...addNewWorker.filter((x) => !projectObject.Worker.includes(x))
    ];
    setWorkerArray(newWorker, "add")
    setIsOpenAdd(false);
};

const handleRowSelectionChange = (newRowSelectionModel: GridRowId[]) => {
    const newSelectedIds = newRowSelectionModel.map(id => Number(id));
    setAddNewWorker(newSelectedIds);
  };

// Here all the magic happens: all Ids, will be inserted into the projectObject via their Ids.
const setWorkerArray =  (newWorkerIds: number | number[], action: 'add' | 'remove' | 'update') => {
    if(action==="add" && Array.isArray(newWorkerIds)){
        setProjectObject({
            ...projectObject,
            Worker: [
                ...projectObject.Worker,
                ...newWorkerIds.filter(id => !projectObject.Worker.includes(id))
            ]
        }as IProject)
        console.log(projectObject)
    } else if(action==="add" && typeof newWorkerIds === "number"){
        console.log("I am adding somebody to the Worker Array!")
        setProjectObject({
            ...projectObject,
            Worker: [
                ...projectObject.Worker,
                newWorkerIds
            ]
        }as IProject)
        console.log(projectObject)
    } else if (action === "remove" && typeof newWorkerIds === "number") {
        const filteredWorkers= projectObject.Worker.filter(id => id !== newWorkerIds);
        setProjectObject({
            ...projectObject,
            Worker: filteredWorkers
        });
        console.log(projectObject)
    } else {
        console.error('Invalid action or participant ID format:', action, newWorkerIds);
      }
};

//functions for new Customer, first handleSave, then handleAfterSave. 
//handleAddNewCustomer opens the Dialog for the EditCustomer Component, there you can add a new customer.
const handleAddNewCustomer = ()=>{
    setIsOpenNewWorker(true)
  }

const handleSave = async (e: React.SyntheticEvent) => {
    try {
        uploadFetch("/user/", true, editWorker, (e:any)=>{handleAfterSave(e,'add')}, setWasSuccessfully, () => {}, setIsLoading);
    } catch (error) {
        console.error("Failed to save customer:", error);
        setWasSuccessfully(false);
    } finally {
        setIsLoading(false);
        setIsOpenNewWorker(false)
    }

};

const handleAfterSave = (savedWorker: IUser, action: 'add' | 'update') => {
    if(action === "add"){ 
        setWorkerArray(savedWorker.id, "add");
    } else if (action === "update"){
        console.log("Something went sideways!")
    }
    else{
        console.log("Something went sideways!")
    }
};

  return (
      <>
      <CustomSnackbar feedback={feedback} setFeedback={setFeedback} />
      <Dialog open={isOpenAdd} onClose={() => setIsOpenAdd(false)} maxWidth="md" fullWidth>
      <DialogTitle>Mitarbeiter hinzufügen</DialogTitle>
      <DialogContent>
          <DataGrid
              sx={{mt: 2}}
              autoHeight
              checkboxSelection 
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              rows={workerArray}
              onRowSelectionModelChange={handleRowSelectionChange}
              rowSelectionModel={addNewWorker}
              columns={columns}
              isRowSelectable={(params: GridRowParams) => !worker.includes(params.id as number)}
              initialState={{
                  pagination: {
                      paginationModel: {
                          pageSize: 50,
                      },
                  },
              }}
          />
      </DialogContent>
      <DialogActions>
          <Button variant="outlined" onClick={() => setIsOpenAdd(false)}>Abbruch</Button>
          <Button variant="contained" onClick={handleAddBtn}>Hinzufügen</Button>
      </DialogActions>
  </Dialog>


  <Typography variant="h6">
     Zugeordnete Mitarbeiter
    <Button variant="outlined" sx={{float: "right"}} onClick={handleAddNew}>Mitarbeiter zuordnen</Button>
   {/*  <Button variant="outlined" sx={{float: "right", marginRight: 2}} onClick={handleAddNewCustomer}>Mitarbeiter erstellen</Button>*/}

  </Typography>
          <Table>
              <TableHead>
                  <TableRow>
                        <TableCell>Nr.</TableCell>
                      <TableCell>Mitarbeiter</TableCell>
                      <TableCell>Mobil</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell></TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {projectObject.Worker.map(workerId => {

                      const worker = workerArray.find(c => c.id === workerId);
                      if (worker === undefined) { return <></> }
                      else {
                        return(
                            <WorkerTableRow
                                key={`worker-${workerId}`}
                                worker={worker}
                                workerArray={projectObject.Worker.map(id => workerArray.find(c => c.id === id)!)}
                                setWorkerArray={setWorkerArray}
                                setUpdateParticipant={handleAfterSave}
                            />
                        )
                      }
                  })}
              </TableBody>
          </Table>
         {/* <Dialog open={isOpenNewWorker} onClose={() => setIsOpenNewWorker(false)} maxWidth="md" fullWidth>
            <DialogTitle>Neuen Mitarbeiter anlegen & zuordnen</DialogTitle>
                <DialogContent>
                    <WorkerEdit
                        key={`editWorker${editWorker.id}`}
                        workerObject={editWorker}
                        setWorkerObject={setEditWorker}
                    />
                </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setIsOpenNewWorker(false)}>Abbruch</Button>
                <Button variant="contained" onClick={handleSave}>Hinzufügen</Button>
            </DialogActions>
        </Dialog>*/}
      </>
  );
};