import React, { useEffect, useState } from "react";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { IPartner } from "../../../interfaces/IPartner";
import { CustomeTextField } from "../../core/CustomeTextField";
import { Delete, Warning } from "@mui/icons-material";
import { IBill } from "../../../interfaces/IBill";
import { IBillPartner } from "../../../interfaces/IBillPartner";


interface IProps {
    billObject: IBill;
    billPartner: IBillPartner;
    partnerArray: IPartner[];
    billPartnerArray: IBillPartner[];
    setBillPartnerArray: Function;
}

export const BillPartnerTableRow: React.FC<IProps> = ({ billObject, billPartner, partnerArray, billPartnerArray, setBillPartnerArray }) => {
    const [editBillPartner, setEditBillPartner] = useState(billPartner);
    const [partner, setPartner] = useState(partnerArray.find(x => x.id === billPartner.Partner));
    const [hasWarningSellingPrice, setHasWarningSellingPrice] = useState(false);

    useEffect(() => {
        setBillPartnerArray([
            ...billPartnerArray.map(x => x.Partner === editBillPartner.Partner ? editBillPartner : x)
        ])
    }, [editBillPartner])

    useEffect(() => {
        setHasWarningSellingPrice(partner === undefined ? false : partner?.selling_price != editBillPartner.selling_price_bill)
    }, [partner, editBillPartner])

    const handleRemovePartner = () => {
        setBillPartnerArray([
            ...billPartnerArray.filter(x => x.Partner !== editBillPartner.Partner)
        ])
    }

    return (
        <>
            <TableRow sx={{ backgroundColor: (hasWarningSellingPrice) ? "#ffffe7" : undefined }}>
                <TableCell>
                    <Collapse in={hasWarningSellingPrice}>
                        <span title="Der gesetzte Stundenpreis entspricht nicht dem Stundenpreis des Partners">
                            <Warning sx={{ color: "warning.main" }} fontSize="small" />
                        </span>
                    </Collapse>
                </TableCell>
                <TableCell>
                    {partner === undefined ? <i>Kein Partner ausgewählt</i> : `${partner.Contact_Person.Company?.company_name}: ${partner.Contact_Person.firstname} ${partner.Contact_Person.lastname}`}
                </TableCell>
                <TableCell>{partner === undefined ? "k.A." : partner.selling_price.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                <TableCell>
                    <CustomeTextField
                        label="Ges. P.p.H."
                        attr="selling_price_bill"
                        object={editBillPartner}
                        setObject={setEditBillPartner}
                        type="number"
                        disabled={billObject.BillState > 20}
                    />
                </TableCell>
                <TableCell>
                    <Collapse in={billObject.BillState <= 20}>
                        <IconButton size="small" onClick={handleRemovePartner}><Delete /></IconButton>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}
