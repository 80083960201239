import React, { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Radio, TableCell, TableRow } from "@mui/material";
import { Delete, Create  } from "@mui/icons-material";
import { ICustomer } from "../../../interfaces/ICustomer";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomerEdit, getEmptyCustomer } from "../CustomerEdit";
import { CustomSnackbar } from "../../core/Snackbar";
import { IGender } from "../../../interfaces/IGender";
import { ITitle } from "../../../interfaces/ITitle";
import { uploadFetch } from "../../../hooks/useFetch";
import { IInsurance } from "../../../interfaces/IInsurance";
import { IPropertyManagment } from "../../../interfaces/IPropertyManagement";
import { IAddressType } from "../../../interfaces/IAddress";
import { IProjectParticipant } from "../../../interfaces/IProjectParticipant";
import ValidationDialog from "../../../sites/ValidationDialog";


interface IProps {
    customer: ICustomer;
    participant: IProjectParticipant;
    
    genderArray: IGender[];
    titleArray: ITitle[];
    insuranceArray: IInsurance[];
    addressTypeArray: IAddressType[];
    propertyManagementArray: IPropertyManagment[];
    setPropertyManagementArray: Function;
    setInsuranceArray: Function;

    customerArray: ICustomer[];
    setCustomerArray: Function;

    participantArray: IProjectParticipant[];
    setParticipantArray: Function;
}


export const ParticipantsTableRow: React.FC<IProps> = ({
  customer,
  participant,
  genderArray,
  titleArray,
  insuranceArray,
  addressTypeArray,
  setInsuranceArray,
  propertyManagementArray,
  setPropertyManagementArray,

  customerArray,
  setCustomerArray,

  participantArray,
  setParticipantArray,
}) => {
  const [editParticipant, setEditParticipant] = useState(participant);
  const [participants, setParticipants] = useState<ICustomer | undefined>(
    customerArray.find(x => x.id === customer.id)
  );

  //new Feature Edit Customer
  const [isOpenEditCustomer, setIsOpenEditCustomer] = useState(false);
  const [editCustomer, setEditCustomer] = useState(participants || getEmptyCustomer());
  //const [disabledEffectEditCustomer,setDisabledEffectEditCustomer] = useState()

  const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 
  const [wasSuccessfully,setWasSuccessfully] = useState(true);
  const [isLoadgin, setIsLoading] = useState(false);


  //RequiredFields
  const [areRequiredFieldsSet, setAreRequiredFieldsSet] = useState(false)
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
  const [proceedWithSave, setProceedWithSave] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [attemptingSave, setAttemptingSave] = useState(false);

  const handleValidationDialogClose = () => {
      setIsValidationDialogOpen(false);
      setProceedWithSave(false);
  };

  const handleValidationDialogProceed = () => {
      setIsValidationDialogOpen(false);
      setProceedWithSave(true);
      setAttemptingSave(true);
  };


  useEffect(() => {
    setParticipantArray([
      ...participantArray.map(x => x.id === editParticipant.id 
        ? { ...editParticipant, is_contact_person: x.is_contact_person, is_payee: x.is_payee} // Immer den Stand im Array
        : x
      )
    ])
  },[editParticipant])


  const handleEditCustomer = () =>{
    setIsOpenEditCustomer(true)
  }

  const handleAfterSave = (savedObject:ICustomer) => {
    //setParticipants(savedObject)
    //setUpdateParticipant(savedObject, "update")

    setCustomerArray([
      ...customerArray.map(x => x.id === savedObject.id ? savedObject : x)
    ])
    setParticipants(savedObject);
    setEditCustomer(savedObject);
    setIsOpenEditCustomer(false)
}

  const handleSave = () => {
      if (!areRequiredFieldsSet && !proceedWithSave) {
        setIsValidationDialogOpen(true);
        return;
      }
      let uploadObject = {
          ...editCustomer,
          Address: (editCustomer?.b2b) ? undefined : editCustomer?.Address,
          Company: (editCustomer?.b2b) ? editCustomer.Company : undefined,
      } as ICustomer

      setWasSuccessfully(true);
          uploadFetch(`/customer/${editCustomer?.id}`,false,uploadObject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
  }

  useEffect(() => {
    if (attemptingSave) {
        handleSave();
    }
}, [attemptingSave]);


  const handleRemoveParticipant= () => {
    setParticipantArray([
      ...participantArray.filter(x => x.id !== editParticipant.id)
    ])
  };

  

  if (!participants) {
      return (
          <TableRow>
              <TableCell colSpan={6}><i>Kunden nicht bestimmen</i></TableCell>
          </TableRow>
      );
  } else {
      return (
        <>

          <ValidationDialog
                open={isValidationDialogOpen}
                handleClose={handleValidationDialogClose}
                handleProceed={handleValidationDialogProceed}
                missingFields={missingFields}
            />

          <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>{`${participants.id}`}</TableCell>
              <TableCell>{`${participants.firstname} ${participants.lastname}`}</TableCell>

              <TableCell>
                <Radio
                  checked={ participantArray.find(x => x.is_contact_person == true)?.id ===  editParticipant.id}
                  onClick={() => {
                      setParticipantArray([
                        ...participantArray.map(x => x.id === editParticipant.id
                          ? {...editParticipant,  is_contact_person: true, is_payee: x.is_payee}
                          : {...x, is_contact_person: false}
                        )
                      ]);
                    }
                  }
                  name={participant.Customer.toString()}
                />
                </TableCell>
              <TableCell>
                <Radio
                  checked={ participantArray.find(x => x.is_payee == true)?.id ===  editParticipant.id}
                  onClick={() => {
                    setParticipantArray([
                      ...participantArray.map(x => x.id === editParticipant.id
                        ? {...editParticipant,is_contact_person: x.is_contact_person,  is_payee: true}
                        : {...x, is_payee: false}
                      )
                    ]);
                  }
                }
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={editParticipant.enable_pk}
                  onChange={() => setEditParticipant({...editParticipant, enable_pk: !editParticipant.enable_pk})}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={editParticipant.enable_hv}
                  onChange={() => setEditParticipant({...editParticipant, enable_hv: !editParticipant.enable_hv})}
                />
                </TableCell>

              <TableCell>{participants.mobil}</TableCell>
              <TableCell>{participants.email}</TableCell>
              <TableCell>
                    <IconButton size="small" onClick={handleEditCustomer}>
                      <Create />
                    </IconButton>
                    <IconButton size="small" onClick={handleRemoveParticipant}>
                        <Delete />
                    </IconButton>
              </TableCell>
          </TableRow>



          <CustomSnackbar feedback={feedback} setFeedback={setFeedback} />
            <Dialog open={isOpenEditCustomer} onClose={() => setIsOpenEditCustomer(false)} maxWidth="md" fullWidth>
            <DialogTitle>Kunden Aktualisieren</DialogTitle>
            <DialogContent>
               <CustomerEdit
                    key={`editCustomer-${participants.id}`}
                    insuranceArray={insuranceArray || []}
                    setInsuranceArray={setInsuranceArray}
                    propertyManagementArray={propertyManagementArray|| []}
                    setPropertyManagementArray={setPropertyManagementArray} 
                    addressTypeData={addressTypeArray|| []}
                    genderArray={genderArray || []}
                    titleArray={titleArray || []}
                    customerObject={editCustomer}
                    setCustomerObject={setEditCustomer}
                    areRequiredFieldsSet={areRequiredFieldsSet}
                    setAreRequiredFieldsSet={setAreRequiredFieldsSet}
                    setMissingFields={setMissingFields} //
               />
            </DialogContent>
            <DialogActions>
                <Button  variant="outlined" onClick={() => setIsOpenEditCustomer(false)}>Abbruch</Button>
                <Button variant="contained" onClick={handleSave}>Aktualisieren</Button>
            </DialogActions>
        </Dialog>

          </>
      );
  }
};