import React, { useEffect, useState } from "react";
import { IMaterial } from "../../../interfaces/IMaterial";
import { IPartner } from "../../../interfaces/IPartner";
import { ITitle } from "../../../interfaces/ITitle";
import { IGender } from "../../../interfaces/IGender";
import { IAddressType } from "../../../interfaces/IAddress";
import { IInsurance } from "../../../interfaces/IInsurance";
import { IPropertyManagment } from "../../../interfaces/IPropertyManagement";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CustomSnackbar } from "../../core/Snackbar";
import { IFeedback } from "../../../interfaces/IFeedback";
import { MaterialSuppierRow } from "./MaterialSuppierRow";
import { IMaterialSupplier } from "../../../interfaces/IMaterialSupplier";
import { getTmpId } from "../../core/generic_helpders";
import { MaterialSupplierAddPartner } from "./MaterialSupplierAddPartner";


interface IProps {
    materialObject: IMaterial;
    setMaterialObject: Function;
    partnerArray: IPartner[];
    setPartnerArray: Function;

    titleData: ITitle[];
    gegenderArray: IGender[];
    addressTypeArray: IAddressType[];
    insuranceArray: IInsurance[];
    setInsuranceArray:Function;
    propertyManagementArray: IPropertyManagment[];
    setPropertyManagementArray: Function;
}


export const getEmptyMaterialSupplier = (partnerId:number,materialId:number,materialSupplierArray:IMaterialSupplier[]) => {
    let minId = -1;

    if (materialSupplierArray.length > 0) {
        let minIdArray = Math.min(...materialSupplierArray.map(x => x.id)) -1;

        if (minIdArray <= minId) {
            minId = minIdArray -1;
        }
    } 


    let newSupplier:IMaterialSupplier = {
        id: getTmpId(materialSupplierArray),
        Partner: partnerId,
        Material: materialId,
        link: "",
        product_number: null,
        purchasing_price: 0,
        selling_price: 0
    }
    return newSupplier

}




export const MaterialSuppierOverview:React.FC<IProps> = ({
    materialObject, setMaterialObject, 
    partnerArray, setPartnerArray,
    titleData, gegenderArray, addressTypeArray, insuranceArray, setInsuranceArray, propertyManagementArray, setPropertyManagementArray
}) => {
    const [materialSupplierArray,setMaterialSupplierArray] = useState(materialObject.MaterialSupplierArray);
    //
    const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 


    useEffect(() => {
        setMaterialObject({
            ...materialObject,
            MaterialSupplierArray: materialSupplierArray
        })
    },[materialSupplierArray])


    const handleAddSupplier = () => {
        let minId = -1;

        if (materialSupplierArray.length > 0) {
            let minIdArray = Math.min(...materialSupplierArray.map(x => x.id)) -1;

            if (minIdArray <= minId) {
                minId = minIdArray -1;
            }
        } 

        if (partnerArray.length > 0) {
            let newSupplier = getEmptyMaterialSupplier(partnerArray[0].id,materialObject.id,materialSupplierArray);
            setMaterialSupplierArray([
                ...materialSupplierArray,
                newSupplier
            ])
        }


    }


    return(
        <Box sx={{mt: 5}}>
            <Typography variant="h5">
                Bezugsquellen
            </Typography>
            <Button variant="outlined" sx={{float: "right", ml: 2}} onClick={handleAddSupplier}>Neu</Button>
            <MaterialSupplierAddPartner
                materialObject={materialObject}
                materialSupplierArray={materialSupplierArray}
                setMaterialSupplier={setMaterialSupplierArray}
                addressTypeArray={addressTypeArray}
                genderArray={gegenderArray}
                partnerArray={partnerArray}
                setPartnerArray={setPartnerArray}
                titleArray={titleData}
            />

            <CustomSnackbar feedback={feedback} setFeedback={setFeedback} />


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell sx={{width: 150}}>Prod.Nr.</TableCell>
                        <TableCell sx={{width: 100}}>EK</TableCell>
                        <TableCell sx={{width: 100}}>VK</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {materialSupplierArray.map(x =>
                        <MaterialSuppierRow
                            key={`materialsupplier-${x.id}`}
                            materialSupplierObject={x}
                            materialSupplierArray={materialSupplierArray}
                            setMaterialSupplierArray={setMaterialSupplierArray}
                            partnerArray={partnerArray}
                        />

                    )}
                </TableBody>
            </Table>
        </Box>
    )
}