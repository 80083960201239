import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IOfferApplicationDocument } from "../interfaces/IOfferApplicationDocument";
import { useFetch } from "../hooks/useFetch";
import { Alert, Box } from "@mui/material";
import { CustomCircularProgress } from "../components/core/CustomCircularProgress";
import { OfferSignedPage } from "../components/offer/OfferSignedPage";


export const OfferSignedPublic:React.FC = () => {
    const params = useParams();
    //
    const [editOfferDocument,setEditOfferDocument,statusCode] = useFetch<IOfferApplicationDocument>("/offer/applications/public/signed/",params.token);
    const [wasSigend,setWasSigend] = useState(false);
    editOfferDocument: ;
    const [] = useState<IOfferApplicationDocument|undefined>();

    useEffect(() => {},[])

    if (statusCode !== null && statusCode !== 200) {
        return(
            <Box sx={{m: 5}}>
                <Alert severity="warning">
                    Der Link konnte nicht gefunden werden oder ist bereits abgelaufen!
                    <br/>Bitte melden Sie sich bei Ihrem Projektbetreuer.
                </Alert>
            </Box>
        )
    }
    else if (editOfferDocument === undefined) {
        return(
            <>
                <CustomCircularProgress/>
            </>
        )
    }
    else if (wasSigend) {
        return(
            <Box sx={{m: 5}}>
                <Alert severity="success">
                    <b>Vielen Dank!</b>
                    <br/> Sie haben das Angebot erfolgreich angenommen. Sie erhalten das unterschriebene Angebot zeitnah als E-Mail. 
                </Alert>
            </Box>
        )
    }
    else {
        return(
            <Box sx={{m: 5}}>
                <OfferSignedPage
                    editOfferDocument={editOfferDocument}
                    setCurrentOfferApplicationDocument={setEditOfferDocument}
                    setWasSigend={setWasSigend}
                />
            </Box>
        )
    }


}