import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, MenuItem, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IPostcode } from "../../interfaces/IPostcode";
import { IAddressType } from "../../interfaces/IAddress";
import { ICompany } from "../../interfaces/ICompany";
import { getEmptyAddress } from "../../sites/CustomerManagment/CustomerOverview";
import PostcodeSearchV2 from "../core/PostcodeSearchV2";

export const getEmptyCompany = () => {
    return {
        Address: getEmptyAddress(5),
        company_name: "",
        email: "",
        homepage: "",
        tax_id: "",
        telefon: "",
        vat_id: "",
    } as ICompany
}


interface IProps {
    addressTypeData: IAddressType[],
    companyObject: ICompany;
    setCompanyObject: Function;
}

export const CompanyEdit:React.FC<IProps> = ({addressTypeData, companyObject, setCompanyObject}) => {
    const [addressObject,setAddressObject] = useState(companyObject.Address);
    useEffect(() => {
        console.log("UPDATE Addres/Company")
      setCompanyObject({
            ...companyObject,
            Address: { ...addressObject}
        } as ICompany)
    },[addressObject])

    return(
        <>
            <Card variant="outlined" sx={{mt: 5, mb: 2}}>
                <CardContent>
                <Typography>Firma</Typography>
                <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item sm={12}>
                        <CustomeTextField
                            label="Firma"
                            attr="company_name"
                            type="string"
                            object={companyObject}
                            setObject={setCompanyObject}
                            required
                        />
                    </Grid>
                    <Grid item sm={8}>
                        <CustomeTextField
                            label="Straße"
                            attr="street"
                            type="string"
                            object={addressObject}
                            setObject={setAddressObject}
                            required
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <CustomeTextField
                            label="Nr."
                            attr="street_number"
                            type="string"
                            object={addressObject}
                            setObject={setAddressObject}
                            required
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <PostcodeSearchV2
                            idPostcode={addressObject.Postcode}
                            setIdPostcode={(idPostcode:number) => setAddressObject({...addressObject, Postcode: idPostcode})}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <CustomeTextField
                            label="Website"
                            attr="homepage"
                            type="string"
                            object={companyObject}
                            setObject={setCompanyObject}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Telefon"
                            attr="telefon"
                            type="string"
                            object={companyObject}
                            setObject={setCompanyObject}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Email"
                            attr="email"
                            type="string"
                            object={companyObject}
                            setObject={setCompanyObject}
                        />
                    </Grid>
                </Grid>
                </CardContent>
            </Card>
        </>
    )
}