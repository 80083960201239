import React, { useEffect, useState } from "react";
import { IMaterial } from "../../interfaces/IMaterial";
import { IMaterialCategory } from "../../interfaces/IMaterialCategory";
import { IUnit } from "../../interfaces/IUnit";
import { Box, Button, Card, CardContent, Grid, IconButton, MenuItem, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { Delete, Edit } from "@mui/icons-material";
import { CustomSnackbar } from "../core/Snackbar";
import { IFeedback } from "../../interfaces/IFeedback";
import { IPartner } from "../../interfaces/IPartner";
import { MerchantsTable } from "./Childs/MerchantsTable";
import { ITitle } from "../../interfaces/ITitle";
import { IGender } from "../../interfaces/IGender";
import { IAddressType } from "../../interfaces/IAddress";
import { IInsurance } from "../../interfaces/IInsurance";
import { IPropertyManagment } from "../../interfaces/IPropertyManagement";
import { IMaterialSubcategory } from "../../interfaces/IMaterialSubcategory";
import { IMaterialSupplier } from "../../interfaces/IMaterialSupplier";
import { MaterialSuppierOverview } from "./Childs/MaterialSuppierOverview";
import { useFetch } from "../../hooks/useFetch";
import { IMaterialDocument } from "../../interfaces/IMaterialDocument";
import { CustomCircularProgress } from "../core/CustomCircularProgress";
import { MaterialDocumentTable } from "./MaterialDocument/MaterialDocumentTable";
import { IMaterialDocumentCategory } from "../../interfaces/IMaterialDocumentCategory";
import { IMIMEType } from "../../interfaces/IMIMEType";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
















interface IProps {
    materialObject: IMaterial;
    setMaterialObject: Function;
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
    unitArray: IUnit[];
    partnerArray: IPartner[];
    setPartnerArray: Function;

    titleData: ITitle[];
    gegenderArray: IGender[];
    addressTypeArray: IAddressType[];
    insuranceArray: IInsurance[];
    setInsuranceArray:Function;
    propertyManagementArray: IPropertyManagment[];
    setPropertyManagementArray: Function;

    mimeTypeArray: IMIMEType[];
    materialDocumentCategory: IMaterialDocumentCategory[];
}


export const MaterialEdit:React.FC<IProps> = ({
    materialObject, setMaterialObject, 
    materialCategoryArray, materialSubcategoryArray, 
    partnerArray,setPartnerArray,
    unitArray, 
    titleData, gegenderArray, addressTypeArray, insuranceArray, setInsuranceArray, propertyManagementArray, setPropertyManagementArray,
    mimeTypeArray, materialDocumentCategory
}) => {
    const [materialDocumentArray,setMaterialDocumentArray, statusCodeDocunebtArray] = useFetch<IMaterialDocument[]>("/material/document/byMaterial/",materialObject.id);

    const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return(
        <>  

            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Aritkelnummer"
                        attr="articelNumber"
                        type="string"
                        object={materialObject}
                        setObject={setMaterialObject}
                        disabled
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="Kategorie"
                        attr="MaterialCategory"
                        type="select"
                        disabled={materialObject.id > 0}
                        object={materialObject}
                        setObject={(updatedObject:IMaterial) =>setMaterialObject({
                            ...updatedObject,
                            MaterialSubcategory: materialSubcategoryArray.filter(x => x.MaterialCategory === updatedObject.MaterialCategory)[0].id
                        })}
                    >
                        {materialCategoryArray.map(x =>
                            <MenuItem key={`materialarray-${x.id}`} value={x.id}>{x.materialCategory}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Unterkategorie"
                        attr="MaterialSubcategory"
                        type="select"
                        disabled={materialObject.id > 0}
                        object={materialObject}
                        setObject={setMaterialObject}
                    >
                        {materialSubcategoryArray.filter(x => x.MaterialCategory === materialObject.MaterialCategory).map(x =>
                            <MenuItem key={`materialsubcatgory-${x.id}`} value={x.id}>{x.materialSubcategory}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Bezeichnung"
                        attr="title"
                        type="string"
                        object={materialObject}
                        setObject={setMaterialObject}
                        null
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="EAN"
                        attr="europeanArticleNumber"
                        type="string"
                        object={materialObject}
                        setObject={setMaterialObject}
                        null
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="SKU"
                        attr="stockKeepingUnit"
                        type="string"
                        object={materialObject}
                        setObject={setMaterialObject}
                        null
                    />
                </Grid>


                <Grid item sm={2}>
                    <CustomeTextField
                        label="Einheit"
                        attr="Unit"
                        type="select"
                        object={materialObject}
                        setObject={setMaterialObject}
                    >
                        {unitArray.map(x =>
                            <MenuItem key={`unit-${x.id}`} value={x.id}>{x.unit}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={5}>
                    <CustomeTextField
                        label="UVP"
                        attr="retail_price"
                        type="number"
                        object={materialObject}
                        setObject={setMaterialObject}
                        required
                    />
                </Grid>
                <Grid item sm={5}>
                    <CustomeTextField
                        label="Marge in %"
                        attr="margin"
                        type="number"
                        object={materialObject}
                        setObject={setMaterialObject}
                        required
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="Mindestabnahme"
                        attr="minimumOrder"
                        type="number"
                        object={materialObject}
                        setObject={setMaterialObject}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Menge"
                        attr="amount"
                        type="number"
                        object={materialObject}
                        setObject={setMaterialObject}
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Beschreibung"
                        attr="description"
                        type="string"
                        object={materialObject}
                        setObject={setMaterialObject}
                        rows={5}
                    />
                </Grid>
            </Grid>

            {(materialObject.id < 0) ?
                <Card sx={{mt: 5}} variant="outlined"><CardContent>Bezugsquellen und Dokumente sind nach dem Anlegen der Materials verfügbar!</CardContent></Card>
                :  
                <Box sx={{ width: '100%', mt: 5 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Bezugsquellen" {...a11yProps(0)} />
                            <Tab label="Dokumente" {...a11yProps(1)}/>
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={value} index={0}>
                        <MaterialSuppierOverview
                            key={`material-materialsupplier-${materialObject.id}`}
                            materialObject={materialObject}
                            setMaterialObject={setMaterialObject}
                            partnerArray={partnerArray}
                            setPartnerArray={setPartnerArray}
                            gegenderArray={gegenderArray}
                            setPropertyManagementArray={setPropertyManagementArray}
                            setInsuranceArray={setInsuranceArray}
                            titleData={titleData}
                            addressTypeArray={addressTypeArray}
                            insuranceArray={insuranceArray}
                            propertyManagementArray={propertyManagementArray}
                        /> 
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>{
                        (statusCodeDocunebtArray !== null && statusCodeDocunebtArray !== 200) ? <>Fehler!</>
                        : (materialDocumentArray === undefined) ? <CustomCircularProgress/> 
                        : <MaterialDocumentTable
                            materialId={materialObject.id}
                            materialDocumentArray={materialDocumentArray}
                            setMaterialDocumentArray={setMaterialDocumentArray}
                            materialDocumentCategory={materialDocumentCategory}
                            mimeTypeArray={mimeTypeArray}
                        />


                    }</CustomTabPanel>

 
                </Box>
                
 

            }
  
        </>
    )
}
