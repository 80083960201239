import { useState, useEffect, useCallback } from 'react';
import { useSessionFetch } from '../../hooks/useFetch';


const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult, statusCode, fetchLoading] = useSessionFetch("/user/validate/");

  const validateToken = useCallback(() => {
    if (statusCode === 200) {
      setIsAuthenticated(true);
      setLoading(false);
    } else {
      setIsAuthenticated(false);
      setLoading(false);
      localStorage.removeItem('token');
      localStorage.removeItem('is_superuser');
      localStorage.removeItem('groups');
      localStorage.removeItem('permissions');
      localStorage.removeItem('idUser');
    }
  }, [statusCode]);

  useEffect(() => {
    if (!fetchLoading) {
      validateToken();
    }
  }, [fetchLoading, validateToken]);

  return { isAuthenticated, loading, validateToken };
};

export { useAuth };
