import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { CustomerEdit } from "../../customer/CustomerEdit";
import { IMaterial } from "../../../interfaces/IMaterial";
import { ITitle } from "../../../interfaces/ITitle";
import { IGender } from "../../../interfaces/IGender";
import { IAddressType } from "../../../interfaces/IAddress";
import { IPartner } from "../../../interfaces/IPartner";
import { getEmptyPartner } from "../../../sites/PartnerManagement/PartnerOverview";
import { IMaterialSupplier } from "../../../interfaces/IMaterialSupplier";
import { getEmptyMaterialSupplier } from "./MaterialSuppierOverview";
import { IFeedback } from "../../../interfaces/IFeedback";
import { CustomSnackbar } from "../../core/Snackbar";
import { uploadFetch, uploadFetchFeedback, useFetch } from "../../../hooks/useFetch";

interface IProps {
    materialObject: IMaterial;
    materialSupplierArray: IMaterialSupplier[];
    setMaterialSupplier: Function;
    titleArray: ITitle[];
    genderArray: IGender[];
    addressTypeArray: IAddressType[];
    partnerArray: IPartner[];
    setPartnerArray: Function;
}


export const MaterialSupplierAddPartner:React.FC<IProps> = ({
    materialObject,
    materialSupplierArray, setMaterialSupplier,
    titleArray, genderArray, addressTypeArray,
    partnerArray, setPartnerArray
}) => {
    const [editPartner,setEditPartner] = useState(getEmptyPartner());
    const [customerObject,setCustomerObject] = useState(editPartner.Contact_Person);
    const [isOpen,setIsOpen] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback);


    const handleOpen = () => {
        let emptyPartner =  getEmptyPartner();
        setEditPartner(emptyPartner);
        setCustomerObject(emptyPartner.Contact_Person)
        setIsOpen(true);
    }

    const handleAfterSave = (newPartner:IPartner) => {
        let newSupplier = getEmptyMaterialSupplier(newPartner.id,materialObject.id,materialSupplierArray);
        setFeedback({open: true, message: "Gespeichert!", severity: "success"});

        setPartnerArray([
            ...partnerArray,
            newPartner
        ])

        setMaterialSupplier([
            ...materialSupplierArray,
            newSupplier
        ])
        setIsOpen(false);
    } 

    const handleSave = () => {

        uploadFetchFeedback("/partner/",true,{...editPartner,Contact_Person: customerObject},handleAfterSave,setIsLoading,setFeedback);
    }

    return(
        <>
            <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

            <Button variant="outlined" onClick={handleOpen} sx={{float: "right"}}>Partner anlegen</Button>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md" fullWidth>
                <DialogContent>Neuen Partner anlegen und zuweisen</DialogContent>
                <DialogContent>
                    <CustomerEdit
                        key={`editCustomer-materialsupplier-new`}
                        customerObject={customerObject}
                        addressTypeData={addressTypeArray}
                        setInsuranceArray={()=>{}}
                        setPropertyManagementArray={()=>{}}
                        setCustomerObject={setCustomerObject}
                        insuranceArray={[]}
                        genderArray={genderArray}
                        titleArray={titleArray}
                        disableB2bSelection
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave} disabled={isLoading}>Speichern</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}