import {
  DateTimePicker,
  LocalizationProvider,
  deDE,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de } from 'date-fns/locale';
import React from 'react';

interface IProps {
  dateValue: string | null;
  setDateValue: Function;
  allowNull: boolean;
  allowPast: boolean;
  disabled?:boolean;
}

export const castWithZeroTimezone = (targetValue: string | null) => {
  if (targetValue === null) {
    return null;
  } else {
    if (targetValue.includes('Z')) {
      return new Date(targetValue);
    } else {
      return new Date(targetValue.replace(' ', 'T') + 'Z');
    }

    //return new Date(`${dateSting}T${targetValue}Z`)
  }
};

export const getZeroTimezoneStringFromDate = (dataObject: Date) => {
  if (isNaN(dataObject.getTime())) {
    return null;
  }

  //let hours = dataObject.getUTCHours();
  //let minutes = dataObject.getMinutes();

  const isoString = dataObject.toISOString();

  const returnString = isoString.replace('T', ' ').split('.')[0];

  return `${returnString}`;

  /*
    let splittedArray = dataObject.toISOString().split("T")

    if (splittedArray.length !== 2) {
        console.warn(`Can't cast Date ${dataObject} to string`);
        return null;
    } else {
        let minutes = dataObject.getUTCHours();
        return splittedArray[1].split(".")[0]
    }
    */
};

export const CustomeDateTimePicker: React.FC<IProps> = props => {
  const fillNumberToString = (targetNumber: number) => {
    if (targetNumber <= 9) {
      return `0${targetNumber}`;
    } else {
      return `${targetNumber}`;
    }
  };

  const getValue = () => {
    const returnValue = castWithZeroTimezone(props.dateValue);

    if (returnValue === null && props.allowNull) {
      return null;
    }
    else if (returnValue === null) {
      return new Date();
    } else {
      return returnValue;
    }
  };

  const setValue = (newValue: Date | null) => {
    if (newValue === null) {
      props.setDateValue(props.allowNull ? null : '');
    } else {
      props.setDateValue(getZeroTimezoneStringFromDate(newValue));
      /*
            let year = newValue.getFullYear();
            let month = newValue.getMonth()+1;
            let day = newValue.getDate();
            let hour = newValue.getHours();
            let minut = newValue.getMinutes();
    
            let dateTimeString = `${year}-${fillNumberToString(month)}-${fillNumberToString(day)} ${fillNumberToString(hour)}:${fillNumberToString(minut)}:00`;

            props.setDateValue(dateTimeString)
            */
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={de}
      localeText={
        deDE.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <DateTimePicker
        slotProps={{ textField: { size: 'small', fullWidth: true } }}
        value={getValue()}
        onChange={setValue}
        minDateTime={props.allowPast ? undefined : new Date()}
        disabled={props.disabled}
      />
    </LocalizationProvider>
  );
};
