import { AuthContext } from './AuthContext';
import React, { ReactNode } from 'react';
import {useAuth} from './useAuth';


const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};


export { AuthProvider };