import React, { useEffect, useState } from "react";
import { IProfile } from "../../../interfaces/IUser";
import { IProjectNote } from "../../../interfaces/IProjectNote";
import { IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { Delete } from "@mui/icons-material";


interface IProps {
    userArray: IProfile[];
    currentNoteArray: IProjectNote;
    noteArray: IProjectNote[];
    setNoteArray: Function;
}


export const ProjectNoteRow:React.FC<IProps> = ({userArray,currentNoteArray,noteArray,setNoteArray}) => {
    const [editCurrentNoteArray,setEditCurrentNoteArray] = useState(currentNoteArray);
    const [currentUser,setCurrentUser] = useState(userArray.find(x => x.id === currentNoteArray.User))

    useEffect(() => {
        if (editCurrentNoteArray.id < 0) {
            setNoteArray([
                ...noteArray.map(x =>x.id === editCurrentNoteArray.id ? editCurrentNoteArray : x)
            ])
        }
    },[editCurrentNoteArray])


    const removeMe = () => {
        setNoteArray([
            ...noteArray.filter(x =>x.id !== editCurrentNoteArray.id)
        ])
    }


    if (currentNoteArray.id > 0) {
        return(
            <>
                <TableRow>
                    <TableCell>{new Date(String(editCurrentNoteArray.created_at)).toLocaleString()}</TableCell>
                    <TableCell>
                        {
                            (currentNoteArray.User == Number(localStorage.getItem("idUser")))
                            ? <>Ich</>
                            : (currentUser === undefined) 
                            ? "FEHLER!" 
                            : <>{`${currentUser.User.first_name} ${currentUser.User.last_name}`}</>
                        }
                    </TableCell>
                    <TableCell colSpan={2}>{editCurrentNoteArray.note}</TableCell>
                </TableRow>
            </>
        )
    } else {
        return(
            <>
                <TableRow>
                    <TableCell>Neu</TableCell>
                    <TableCell>Ich</TableCell>
                    <TableCell>
                        <CustomeTextField
                            attr="note"
                            label="Notiz"
                            object={editCurrentNoteArray}
                            setObject={setEditCurrentNoteArray}
                            rows={3}
                            type="string"
                        />
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={removeMe}><Delete/></IconButton>
                    </TableCell>
                </TableRow>
            </>
        )
    }

}
