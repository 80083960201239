import React, { useEffect, useState } from "react";
import { IInsurance } from "../../interfaces/IInsurance";
import { Grid, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IPostcode } from "../../interfaces/IPostcode";
import { IAddressType } from "../../interfaces/IAddress";
import PostcodeSearchV2 from "../core/PostcodeSearchV2";

interface IProps {
    addressTypeData: IAddressType[],
    insuranceObject: IInsurance;
    setInsuranceObject: Function;
}

export const InsuranceEdit:React.FC<IProps> = ({addressTypeData, insuranceObject, setInsuranceObject}) => {
    const [addressObject,setAddressObject] = useState(insuranceObject.Address);

    useEffect(() => {
        setInsuranceObject({
            ...insuranceObject,
            Address: addressObject
        } as IInsurance)
    },[addressObject])

    return(
        <>
            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Pflegekasse"
                        attr="insurance_name"
                        type="string"
                        object={insuranceObject}
                        setObject={setInsuranceObject}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Telefon"
                        attr="telefon"
                        type="string"
                        object={insuranceObject}
                        setObject={setInsuranceObject}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Email"
                        attr="email"
                        type="string"
                        object={insuranceObject}
                        setObject={setInsuranceObject}
                        required
                    />
                </Grid>
            </Grid>


            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Anschrift"
                        attr="Address_Type"
                        type="select"
                        object={addressObject}
                        setObject={setAddressObject}
                        required
                    >
                        {addressTypeData.map(x => (
                            <MenuItem key={`Address_Type-${x.id}`} value={x.id}>
                                {x.addressCategory}
                            </MenuItem>
                        ))}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={8}>
                    <CustomeTextField
                        label="Straße"
                        attr="street"
                        type="string"
                        object={addressObject}
                        setObject={setAddressObject}
                        required
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="Nr."
                        attr="street_number"
                        type="string"
                        object={addressObject}
                        setObject={setAddressObject}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <PostcodeSearchV2
                        idPostcode={addressObject.Postcode}
                        setIdPostcode={(idPostcode:number) => setAddressObject({...addressObject, Postcode: idPostcode})}
                    />
                </Grid>
            </Grid>


            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Info"
                        attr="info"
                        type="string"
                        object={insuranceObject}
                        setObject={setInsuranceObject}
                        rows={5}
                    />
                </Grid>
            </Grid>
        </>
    )
}