import React, { useState, useEffect, ReactNode } from 'react';
import { PermissionsContext } from './PermissionContext';
import { useFetch } from '../../hooks/useFetch';

interface PermissionsProviderProps {
  children: ReactNode;
}

const PermissionsProvider: React.FC<PermissionsProviderProps> = ({ children }) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const id = localStorage.getItem("idUser");

  const [perms] = useFetch<string[]>(id ? `/user/permissions` : null);

  useEffect(() => {
    if (perms) {
      setPermissions(perms);
      setLoading(false);
    }
  }, [perms]);

  useEffect(() => {
    if (!id) {
      setLoading(false);
    }
  }, [id]);

  return (
    <PermissionsContext.Provider value={{ permissions, setPermissions, loading }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsProvider };
