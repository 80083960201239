import React from "react";
import { Grid, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { ICustomer } from "../../interfaces/ICustomer";
import { IPropertyManagment } from "../../interfaces/IPropertyManagement";
import { IGender } from "../../interfaces/IGender";
import { ITitle } from "../../interfaces/ITitle";
import { CustomerEdit } from "../customer/CustomerEdit";

interface IProps {
    genderArray: IGender[],
    titleArray: ITitle[],
    propertyManagementObject: IPropertyManagment;
    setpropertyManagementObject: Function;
}

export const PropertyManagmentEdit:React.FC<IProps> = ({genderArray, titleArray, propertyManagementObject, setpropertyManagementObject}) => {
    const [customerObject,setCustomerObject] = React.useState(propertyManagementObject.Contact_Person);

    React.useEffect(() => {
        setpropertyManagementObject({
            ...propertyManagementObject,
            Contact_Person: {...customerObject, Address: undefined} as ICustomer
        })
    }, [customerObject, setpropertyManagementObject, propertyManagementObject]);

    return(
        <>
            <CustomerEdit
                key={`editCustomer-${customerObject.id}`}
                customerObject={customerObject}
                setCustomerObject={setCustomerObject}
                setPropertyManagementArray={setpropertyManagementObject}
                insuranceArray={[]}
                addressTypeData={[]}
                setInsuranceArray={()=>{}}
                genderArray={genderArray}
                titleArray={titleArray}
                disableB2bSelection
            />
        </>
    )
}