import { useCallback, useRef, useState } from "react";
import { getFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import { IDocument } from "../../interfaces/IDocument";
import { IProjectDocument } from "../../interfaces/IProjectDocument";
import { IProject } from "../../interfaces/IProject";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import {
  Close,
  Delete,
  Download,
  Edit,
  Save,
  Visibility,
} from "@mui/icons-material";
import { PDFViewer } from "../core/PDFViewer";

interface IProps {
  document: IProjectDocument;
  handleDelete: Function;
  updateDocument: Function;
  hideLoading: Function;
  showLoading: Function;
  showAlertPopUp: Function;
  hideAlertPopUp: Function;
  enableDownload: boolean;
  setEnableDownload: Function;
}

export const DocumentRow: React.FC<IProps> = (props) => {
  const [documentName, setDocumentName] = useState<string>(
    props.document.Document.file_name
  );

  const [showPDFView, setShowPDFView] = useState<boolean>(false);

  const [localDocumentObject, setLocalDocumentObject] = useState<IDocument>();

  const [isEditing, setIsEditing] = useState(false);


  const base64Download = useCallback(
    (localDocumentObject: IDocument, download: boolean) => {
      if (localDocumentObject.data !== undefined) {
        setLocalDocumentObject(localDocumentObject);
        if (download === false) {
          setShowPDFView(true);
        } else {
          let byteCharacters = atob(localDocumentObject.data);

          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          let byteArray = new Uint8Array(byteNumbers);

          let blob = new Blob([byteArray], {
            type: localDocumentObject.MIMEtype.MIMEtype,
          });

          let blobUrl = URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = blobUrl;
          a.download = localDocumentObject.file_name;
          a.click();
        }
      }
    },
    [setShowPDFView, setLocalDocumentObject]
  );

  const downloadDocument = useCallback(
    (id: number, download: boolean) => {
      props.showLoading("Dokument wird heruntergeladen...");
      if (props.enableDownload) {
        props.setEnableDownload(false);
        if (localDocumentObject?.data !== undefined) {
          base64Download(localDocumentObject, download);
        } else {
          getFetch<IDocument>(
            "/document/",
            id,
            (localDocumentObject: IDocument) => {
              base64Download(localDocumentObject, download);
            },
            (succesfull: boolean) => {
              if (!succesfull) {
                props.showAlertPopUp("Download fehlgeschlagen");
              }
            }
          );
        }
        props.setEnableDownload(true);
      }
      props.hideLoading();
    },
    [
      localDocumentObject,
      props.enableDownload,
      props.showLoading,
      props.setEnableDownload,
      base64Download,
      props.hideLoading,
      props.showAlertPopUp,
    ]
  );

  return (
    <>
      <TableRow>
        <TableCell>{props.document.id}</TableCell>
        {isEditing && (
          <TableCell>
            <TextField
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
          </TableCell>
        )}
        <TableCell>{props.document.Document.file_name}</TableCell>
        <TableCell>
          {!isEditing && (
            <>
              <IconButton
                title="View"
                onClick={() =>
                  downloadDocument(props.document.Document.id!, false)
                }
              >
                <Visibility />
              </IconButton>
              <IconButton
                title="Download"
                onClick={() =>
                  downloadDocument(props.document.Document.id!, true)
                }
              >
                <Download />
              </IconButton>
              <IconButton
                title="Bearbeiten"
                onClick={() => setIsEditing(true)}
              >
                <Edit />
              </IconButton>
              <IconButton
                title="Löschen"
                onClick={() => props.handleDelete(props.document)}
              >
                <Delete />
              </IconButton>
            </>
          )}
          {isEditing && (
            <>
              <IconButton
                title="Speichern"
                onClick={() =>
                  props.updateDocument(props.document, documentName)
                }
              >
                <Save />
              </IconButton>
              <IconButton
                title="Abbrechen"
                onClick={() => {
                  setIsEditing(false);
                  setDocumentName(props.document.Document.file_name);
                }}
              >
                <Close />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      {props.document.Document !== undefined && (
        <Dialog
          open={showPDFView}
          fullScreen
          onClose={() => setShowPDFView(false)}
        >
          <DialogActions>
            <IconButton onClick={() => setShowPDFView(false)}>
              <Close />
            </IconButton>
          </DialogActions>
          <DialogContent>
            {props.document.Document.MIMEtype.MIMEtype ===
              "application/pdf" && (
              <PDFViewer
                documentObject={
                  localDocumentObject ? localDocumentObject : null
                }
              ></PDFViewer>
            )}
            {props.document.Document.MIMEtype.MIMEtype !==
              "application/pdf" && (
              <Box
                component={"img"}
                src={
                  `data:` +
                  props.document.Document.MIMEtype.MIMEtype +
                  `;base64,` +
                  localDocumentObject?.data
                }
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
