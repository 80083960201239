import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { MenuBook } from "@mui/icons-material";
import { DataGrid, deDE, GridColDef, GridRowSelectionModel, GridValueGetterParams } from "@mui/x-data-grid";
import { IDictionaryEntry } from "../../interfaces/IDictionaryEntry";

interface IProps {
    txt: string;
    setTxt: Function;
    //
    dictionaryIdsArray: number[];
    dictionaryEntryArray: IDictionaryEntry[];
    //
    floatRight?: boolean;
}

const columns: GridColDef[] = [
    { 
        field: 'dictionaryEntry', 
        headerName: 'Variable', 
        valueGetter: (params: GridValueGetterParams) => `{{${params.row.dictionaryEntry}}}`,
        width: 200,
    },
    { 
        field: 'description', 
        headerName: 'Beschreibung', 
        flex: 1,
        renderCell: (params) => (
            <Typography style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {params.value}
            </Typography>
        )
    },
]

export const DictionaryHelper: React.FC<IProps> = ({ txt, setTxt, dictionaryIdsArray, dictionaryEntryArray, floatRight }) => {
    //const dictionaryEntryArray = useSelector((state: State) => state.dictionaryEntryArray.storeDictionaryEntry);
    //
    const [isOpen, setIsOpen] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);


    const handleOpen = () => {
        setRowSelectionModel([]);
        setIsOpen(true);
    }

    const handleAdd = () => {
        let tmpTxt = txt;
        
        if (txt !== "") {
            tmpTxt = tmpTxt + "\n";
        }
        tmpTxt += dictionaryEntryArray
            .filter(x => rowSelectionModel.indexOf(x.id) > -1 )
            .map(x => `{{${x.dictionaryEntry}}}`)
            .join(" ");
        setTxt(tmpTxt);
        setIsOpen(false);
    }

    return (
        <>
            <IconButton 
                title="Verfügbare Variable"
                onClick={handleOpen}
                sx={{float: (floatRight) ? "right" : undefined}}
                disabled={dictionaryIdsArray.length === 0}
            >
                <MenuBook />
            </IconButton>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Verfügbare Variable</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DataGrid
                                getRowHeight={() => 'auto'}
                                sx={{ mt: 2 }}
                                autoHeight
                                checkboxSelection 
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                rows={dictionaryEntryArray.filter(x => dictionaryIdsArray.indexOf(x.Dictionary) > -1)}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel([...newRowSelectionModel]);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 50,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Abbruch</Button>
                    <Button variant="contained" disabled={rowSelectionModel.length === 0} onClick={handleAdd}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
