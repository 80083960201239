import React, { SyntheticEvent, useEffect, useState, useCallback} from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { IPostcode } from "../../interfaces/IPostcode";
import { getFetch } from "../../hooks/useFetch";


interface IProps {
  idPostcode: number|null;
  setIdPostcode: Function;
  disabledEdit?: boolean;
  required?: boolean;
  allowNull?: boolean;
}


function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }


const PostcodeSearchV2:React.FC<IProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [currentPostcodeObject,setCurrentPostcodeObject] = useState<IPostcode|null>(null);
    const [options, setOptions] = useState<readonly IPostcode[]>([]);
    //
    // const loading = open && options.length === 0;
    const [loading,setLoading] = useState(false);
    const [searchTerm,setSearchTerm] = useState("");


    const initLoad = (postcode:IPostcode) => {
      if (postcode) {
        setOptions([postcode]);
        setCurrentPostcodeObject(postcode);
      } else {
      setOptions(([]))
      setCurrentPostcodeObject(null);
        console.error("Invalid response or null postcode");
    } 
    setLoading(false);
  };


    useEffect(() => {
      if (props.idPostcode !== null && currentPostcodeObject === null) {
          setLoading(true);
          console.log(props.idPostcode)
          if(props.idPostcode > 0){
            getFetch("/postcode/",props.idPostcode,initLoad)
          }
          
      }
    },[props.idPostcode, currentPostcodeObject])


    const handleAfterLoad = (res:IPostcode[]) => {
      setLoading(false);
      setOptions(res);
    }

    const queryBackend = useCallback((localSearchTerm:string) => {
      setLoading(true);
      setOptions([]);
      getFetch(`/postcode/search/${localSearchTerm}`,undefined,handleAfterLoad)
    }, []);
    
    useEffect(() => {
      if (searchTerm !== "") {
        console.log(searchTerm)
        const timeOutId = setTimeout(() => queryBackend(searchTerm), 500);
        return () => clearTimeout(timeOutId);
      }
    }, [searchTerm, queryBackend]);


    function onChange(event: SyntheticEvent, newObject:IPostcode|null) {
      setCurrentPostcodeObject(newObject);
      if (newObject !== null) {
        props.setIdPostcode(newObject.id);
      }
      else {
        if (props.allowNull) {
          props.setIdPostcode(null);
        } else {
          props.setIdPostcode(-1);
        }

      }
    }

    function onInputChange(event: SyntheticEvent | null, searchTerm:string) {
      if (event !== null && event.type === "change") {
        setLoading(true);
        setSearchTerm(searchTerm);
      }
    }

    return (
        <Autocomplete
          id="searchbar-postcode"
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          disabled={props.disabledEdit !== undefined && props.disabledEdit === true}
          value={currentPostcodeObject}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => `${option.postcode} ${option.city}` }
          loadingText="Laden..."
          options={options}
          loading={loading}
          onInputChange={onInputChange}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Postleitzahl"
              size="small"
             
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      );
}
export default PostcodeSearchV2;

