import {
  Button,
  Typography,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


export const NoPermission: React.FC = () => {
            return (
          <>
            <Grid>
              <Typography variant="h5">
                  Sie haben leider keinen Zugriff auf diese Seite!
              </Typography>
              <Link to="/">Zurück</Link>
            </Grid> 
          </>
      )
  }