import React, { useEffect, useState } from "react";
import { IBill } from "../../interfaces/IBill";
import { IProject } from "../../interfaces/IProject";
import { ICustomer } from "../../interfaces/ICustomer";
import { IUnit } from "../../interfaces/IUnit";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IBillState } from "../../interfaces/IBillState";
import { AppBar, Box, Dialog, IconButton, Slide, TableCell, TableRow, Toolbar, Typography } from "@mui/material";
import { Check, Create } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { GridCloseIcon } from "@mui/x-data-grid";
import { BillEditor } from "./BillEditor";
import { ITemplate } from "../../interfaces/ITemplate";
import { IPartner } from "../../interfaces/IPartner";



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  






interface IProps {
    billObject: IBill;
    billArray: IBill[];
    setBillArray: Function;
    projectObject: IProject;

    customerArray: ICustomer[];
    unitArray: IUnit[];
    serviceArray: IService[];
    partnerArray: IPartner[];
    templateArray: ITemplate[],
    serviceCategoryArray: IServiceCategory[];
    billStateArray: IBillState[];
}


export const BillRow:React.FC<IProps> = ({
    billObject, billArray, setBillArray, projectObject, partnerArray,
    customerArray, unitArray, serviceArray, serviceCategoryArray, billStateArray, templateArray
}) => {
    const [editBill,setEditBill] = useState(billObject);
    const [editBillPositionArray,setEditBillPositionArray] = useState(billObject.BillPositions);
    const [editBillPartnerArray, setEditBillPartnerArray] = useState(billObject.BillPartners);
    const [isOpenEdit,setIsOpenEdit] = useState(false);


    const handleOpenEdit = () => {
        setEditBill(billObject)
        setEditBillPositionArray(billObject.BillPositions)
        setIsOpenEdit(true);
    }
    const handleCloseEdit = () => {
        setIsOpenEdit(false);
    }



    const handleSave = () => {
        setIsOpenEdit(false);
        setBillArray([...billArray, editBill])
    }



    return(
        <>
            <Dialog
                fullScreen
                open={isOpenEdit}
                onClose={handleCloseEdit}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseEdit}
                        aria-label="close"
                    >
                    <GridCloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Rechnungseditor
                    </Typography>
                </Toolbar>
                </AppBar>
                <Box style={{marginBottom: 0}}  sx={{m: 5}}>
                    <BillEditor
                        key={`edit-bill-${editBill.id}`}
                        editBillObject={editBill}
                        setEditBillObject={setEditBill}
                        billArray={billArray}
                        setBillArray={setBillArray}
                        editBillPositionArray={editBillPositionArray}
                        setEditBillPositionArray={setEditBillPositionArray}
                        billStateArray={billStateArray}
                        customerArray={customerArray}
                        projectObject={projectObject}
                        serviceArray={serviceArray}
                        partnerArray={partnerArray}
                        billPartner={editBillPartnerArray}
                        setBillPartner={setEditBillPartnerArray}
                        serviceCategoryArray={serviceCategoryArray}
                        unitArray={unitArray}
                        templateArray={templateArray}
                        handleSave={handleSave}
                    />
                </Box>
            </Dialog>


            <TableRow>
                <TableCell sx={{cursor: "pointer"}} onClick={handleOpenEdit}>{billObject.id}</TableCell>
                <TableCell sx={{cursor: "pointer"}} onClick={handleOpenEdit}>{billObject.is_final && <Check/>}</TableCell>
                <TableCell sx={{cursor: "pointer"}} onClick={handleOpenEdit}>{new Date(billObject.valid_till || "").toLocaleDateString()}</TableCell>
                <TableCell sx={{cursor: "pointer"}} onClick={handleOpenEdit}>{billStateArray.find(y => y.id === billObject.BillState)?.billState}</TableCell>
                <TableCell sx={{cursor: "pointer"}} onClick={handleOpenEdit}>{billObject.title}</TableCell>
                <TableCell>
                    <IconButton onClick={handleOpenEdit}><Create/></IconButton>

                </TableCell>
            </TableRow>
        </>
    )
}
