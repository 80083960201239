import React, { useEffect, useState } from "react";
import { IPartner } from "../../../interfaces/IPartner";
import { IUnit } from "../../../interfaces/IUnit";
import { IService } from "../../../interfaces/IService";
import { IServiceCategory } from "../../../interfaces/IServiceCategory";
import { IBillPosition } from "../../../interfaces/IBillPosition";
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { BillPositionAdd } from "./BillPositionAdd";
import { BillPositionRow } from "./BillPositionRow";
import { IBillPartner } from "../../../interfaces/IBillPartner";
import { IBill } from "../../../interfaces/IBill";

interface IProps {
    billObject: IBill;
    billPositionArray: IBillPosition[];
    setBillPosition: Function;
    billPartnerArray: IBillPartner[];
    partnerArray: IPartner[];
    unitArray: IUnit[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
    isOpenAdd: boolean;
    setIsOpenAdd: Function;
}

export const BillPositionTable: React.FC<IProps> = ({ billObject, billPositionArray, setBillPosition,
    billPartnerArray, partnerArray, unitArray, serviceArray, serviceCategoryArray, isOpenAdd, setIsOpenAdd }) => {
    const [total, setTotal] = useState(0);

    const handleOnClose = () => {
        setIsOpenAdd(false);
    }

    const getTotal = () => {
        let total = 0;
        billPositionArray.map(billPosition => {
            if (billPosition.wasChangedPrice) {
                total = total + (billPosition.price * billPosition.units)
            }
            else {
                let billPartnerObject = billPartnerArray.find(x => x.Partner === billPosition.Partner);
                let serviceObject = serviceArray.find(x => x.id === billPosition.Service);
                //
                let price_per_hour = 0;
                let timeFactor = 0;
                //
                if (billPartnerObject === undefined || billPosition.wasChangedPricePerHour) {
                    price_per_hour = billPosition.price_per_hour
                } else {
                    price_per_hour = billPartnerObject.selling_price_bill
                }

                if (serviceObject === undefined || billPosition.wasChangedTimeFactor) {
                    timeFactor = billPosition.timeFactor
                } else {
                    timeFactor = serviceObject.timeFactor
                }

                total = total + (billPosition.units * price_per_hour * timeFactor)
            }
        })
        return total
    }

    useEffect(() => {
        setTotal(getTotal())
    }, [billPositionArray, billPartnerArray])

    return (
        <>
            <Dialog open={isOpenAdd} onClose={handleOnClose} maxWidth="md" fullWidth>
                <DialogTitle style={{ background: "white", border: "10px solid white" }} >Neue Position(en) hinzufügen</DialogTitle>
                <DialogContent >
                    <BillPositionAdd
                        billObject={billObject}
                        billPartnerArray={billPartnerArray}
                        partnerArray={partnerArray}
                        billPositionArray={billPositionArray}
                        setBillPosition={setBillPosition}
                        serviceArray={serviceArray}
                        serviceCategoryArray={serviceCategoryArray}
                        setIsOpenAdd={setIsOpenAdd}
                    />
                </DialogContent>
            </Dialog>

            <Typography variant="h5">
                Positionen
            </Typography>

            <Table sx={{ mb: 5 }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: 0, width: 50 }}></TableCell>
                        <TableCell sx={{ p: 0, width: 80 }}>Artk.Nr.</TableCell>
                        {/* <TableCell sx={{ p: 0, width: 150 }}>Partner</TableCell> */}
                        <TableCell sx={{ minWidth: 300 }}>Position</TableCell>
                        <TableCell sx={{ p: 0, width: 50 }}></TableCell>
                        <TableCell sx={{ p: 0, width: 150 }}>Faktor</TableCell>
                        {/*
                            <TableCell sx={{ p: 0, width: 150 }}>P.p.S.</TableCell>
                         */}

                        <TableCell sx={{ p: 0, width: 150 }}>Einheiten</TableCell>
                        <TableCell sx={{ p: 0, width: 50 }}>Einheit</TableCell>
                        <TableCell sx={{ p: 0, width: 150 }}>Preis</TableCell>
                        <TableCell sx={{ p: 0, width: 50 }}>Total</TableCell>
                        <TableCell sx={{ p: 0, width: 50 }}></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {billPositionArray
                        .sort((a, b) => a.articelNumber > b.articelNumber ? 1 : -1)
                        .map((billPosition, idx) =>
                            <BillPositionRow
                                billObject={billObject}
                                key={`billposition-${billPosition.id}-${billPosition.position}-${idx}`}
                                billPositionObject={billPosition}
                                billPartnerArray={billPartnerArray}
                                partnerArray={partnerArray}
                                billPositionArray={billPositionArray}
                                setBillPosition={setBillPosition}
                                serviceArray={serviceArray}
                                serviceCategoryArray={serviceCategoryArray}
                                unitArray={unitArray}
                            />
                        )}

                    <TableRow>
                        <TableCell colSpan={6} />
                        <TableCell colSpan={2}><b>Summe</b></TableCell>
                        <TableCell>{total.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell />
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={6} />
                        <TableCell sx={{ borderBottom: "solid 1px" }} colSpan={2}><b>MwSt.</b></TableCell>
                        <TableCell sx={{ borderBottom: "solid 1px" }}>{(total * billObject.vat).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell />
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={6} />
                        <TableCell sx={{ borderBottom: "solid 1px" }} colSpan={2}><b>Total</b></TableCell>
                        <TableCell sx={{ borderBottom: "solid 1px" }}>{((total * billObject.vat) + total).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableBody>

            </Table>
        </>
    )
}
