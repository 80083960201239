import React, { useState } from "react";
import { IHandoverProtocol } from "../../interfaces/IHandoverProtocol";
import { IHandoverProtocolPartner } from "../../interfaces/IHandoverProtocolPartner";
import { AppBar, Box, Dialog, IconButton, Slide, TableCell, TableRow, Toolbar, Typography } from "@mui/material";
import { IPartner } from "../../interfaces/IPartner";
import { IUnit } from "../../interfaces/IUnit";
import { Download, Edit, NoteAlt } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { GridCloseIcon } from "@mui/x-data-grid";
import { HandoverPartnerEdit } from "./HandoverPartnerEdit";
import { ICustomer } from "../../interfaces/ICustomer";
import { HandoverApplicationDocumentMain } from "./HandoverApplicationDocument/HandoverApplicationDocumentMain";
import { IProject } from "../../interfaces/IProject";
import { ITemplate } from "../../interfaces/ITemplate";



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  





interface IProps {
    projectObject: IProject;
    handoverPartnerObject: IHandoverProtocolPartner;
    handoverProtocolObject: IHandoverProtocol;
    setHandoverProtocolObject: Function;
    partnerArray: IPartner[];
    unitArray: IUnit[];
    customerArray: ICustomer[];
    templateArray: ITemplate[];
}


export const HandoverPartnerRow:React.FC<IProps> = ({
    projectObject,
    handoverPartnerObject,handoverProtocolObject,
    setHandoverProtocolObject,
    partnerArray, unitArray, customerArray, templateArray
}) => {
    const [partner,setPartner] = useState(partnerArray.find(x => x.id === handoverPartnerObject.Partner));
    //
    const [isOpenEdit,setIsOpenEdit] = useState(false);
    //
    const [editHandoverPartner,setEditHandoverPartner] = useState(handoverPartnerObject);
    const [editHandoverPostition,setEditHandoverPostition] = useState(handoverPartnerObject.HandoverProtocolPositionArray);
    const [editHandoverDocumentArray,setEditHandoverDocumentArray] = useState(handoverPartnerObject.HandoverApplicationDocumentArray);

    const handleOpen = () => {
        setEditHandoverPartner(handoverPartnerObject);
        setEditHandoverPostition(handoverPartnerObject.HandoverProtocolPositionArray);
        setIsOpenEdit(true);
    }




    return(
        <>


            <Dialog
                fullScreen
                open={isOpenEdit}
                onClose={() => setIsOpenEdit(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setIsOpenEdit(false)}
                        aria-label="close"
                    >
                    <GridCloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Übergabeprotokoll: {partner === undefined ? <>Fehler</> : `${partner.Contact_Person.Company?.company_name}`}
                    </Typography>
                </Toolbar>
                </AppBar>
                <Box sx={{m: 5}}>
                    <HandoverPartnerEdit
                        handoverProtocolObject={handoverProtocolObject}
                        setHandoverProtocolObject={setHandoverProtocolObject}
                        editHandoverPartner={editHandoverPartner}
                        setEditHandoverPartner={setEditHandoverPartner}
                        editHandoverPostition={editHandoverPostition}
                        setEditHandoverPostition={setEditHandoverPostition}
                        partner={partner}
                        partnerArray={partnerArray}
                        unitArray={unitArray}
                        setIsOpenEdit={setIsOpenEdit}
                    />
                </Box>
            </Dialog>

            <TableRow>
                <TableCell>{partner === undefined ? <>Fehler</> : `${partner.Contact_Person.Company?.company_name}`}</TableCell>
                <TableCell>
                    <IconButton size="small" onClick={handleOpen}><Edit/></IconButton>
                    <HandoverApplicationDocumentMain
                        projectObject={projectObject}
                        handoverPartnerObject={handoverPartnerObject}
                        customerArray={customerArray}
                        editHandoverDocumentArray={editHandoverDocumentArray}
                        setEditHandoverDocumentArray={setEditHandoverDocumentArray}
                        templateArray={templateArray}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}