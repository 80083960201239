import React, { useEffect, useState } from "react";
import { IHandoverProtocol } from "../../../interfaces/IHandoverProtocol";
import { IHandoverProtocolPartner } from "../../../interfaces/IHandoverProtocolPartner";
import { IPartner } from "../../../interfaces/IPartner";
import { IOffer } from "../../../interfaces/IOffer";
import { Alert, AppBar, Box, Button, Collapse, Dialog, DialogContent, IconButton, LinearProgress, Slide, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { GridCloseIcon } from "@mui/x-data-grid";
import { HandoverAddOffer } from "./HandoverAddOffer";
import { IOfferState } from "../../../interfaces/IOfferState";
import { IOfferType } from "../../../interfaces/IOfferType";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";
import { HandoverPartnerPosTable } from "./HandoverPartnerPosTable";
import { HandoverAddPartner } from "./HandoverAddPartner";
import { Delete } from "@mui/icons-material";
import { uploadFetch } from "../../../hooks/useFetch";
import { IUnit } from "../../../interfaces/IUnit";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  








interface IProps {
    handoverProtocolObject: IHandoverProtocol;
    setHandoverProtocolObject: Function
    handoverPartnerArray: IHandoverProtocolPartner[];
    partnerArray: IPartner[];
    unitArray: IUnit[];
    offerArray: IOffer[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];

}



export const HandoverProtocolAllocation:React.FC<IProps> = ({
    handoverProtocolObject, setHandoverProtocolObject,
    handoverPartnerArray,
    partnerArray, unitArray,
    offerArray, offerStateArray, offerTypeArray
}) => {
    const [isOpenEdit,setIsOpenEdit] = useState(false);
    //
    const [isOpenAddOffer,setIsOpenAddOffer] = useState(false);
    const [isOpenAddPartner,setIsOpenAddPartner] = useState(false);
    //
    const [offerPosArray,setOfferPosArray] = useState<IOfferPosition[]>([]);
    //
    const [editHandoverParterArray,setEditHandoverParterArray] = useState(handoverPartnerArray)
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoading,setIsLoading] = useState(false);

    

    useEffect(() => {
        if (isOpenEdit) {
            let tmpArray:IOfferPosition[] = [];

            editHandoverParterArray.map(handoverPartner => handoverPartner.HandoverProtocolPositionArray.map(handoverPostion => {
                if (handoverPostion.OfferPosition !== null) {
                    let test = offerArray
                        .map(offer => 
                            offer.OfferPositions
                                .filter(offerPost => offerPost.id === handoverPostion.OfferPosition)
                                .map(x => tmpArray.push({...x, Partner: handoverPartner.Partner}))
                        )
                }
            }))
            console.log("LEL")
            console.log(tmpArray)
            setOfferPosArray(tmpArray)
            setEditHandoverParterArray(handoverPartnerArray)
        }
    },[isOpenEdit])



    const removePartner = (idPartner:number) => {
        let tmpPartner = [...editHandoverParterArray.filter(x => x.Partner !== idPartner)];
        let tmpPos:IOfferPosition[] = [];

        offerPosArray
            .filter(x => x.Partner === idPartner)
            .map(offerPos => {
                if (tmpPartner.length > 0) {
                    tmpPos.push({...offerPos, Partner: tmpPartner[0].Partner})
                } else {
                    tmpPos.push({...offerPos, Partner: null})
                }
        })

        setEditHandoverParterArray([...tmpPartner]);

        setOfferPosArray([
            ...offerPosArray.filter(x => x.Partner !== idPartner),
            ...tmpPos
        ])
    }


    const handleAfterSave = (res:IHandoverProtocol) => {
        setHandoverProtocolObject(res);
        setIsOpenEdit(false);
    }


    const handleAllocate = () => {
        let uploadObject:any = {
            OfferPositionArray: offerPosArray,
            HandoverProtocolPartnerArray: editHandoverParterArray
        }
        uploadFetch(`/handoverprotocol/allocate/${handoverProtocolObject.id}`,true,uploadObject,handleAfterSave,setWasSuccessfully,() => {},setIsLoading)
    }


    return(
        <>
            <Dialog
                fullScreen
                open={isOpenEdit}
                onClose={() => setIsOpenEdit(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setIsOpenEdit(false)}
                        aria-label="close"
                    >
                    <GridCloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Überarbeitung der Zuweisung
                    </Typography>
                </Toolbar>
                </AppBar>
                <Box style={{marginBottom: 0}} sx={{m: 5}}>
                    <HandoverAddOffer
                        isOpenAddOffer={isOpenAddOffer}
                        setIsOpenAddOffer={setIsOpenAddOffer}
                        handoverPartnerArray={editHandoverParterArray}
                        handoverProtocolObject={handoverProtocolObject}
                        offerArray={offerArray}
                        partnerArray={partnerArray}
                        setHandoverPartnerArray={setEditHandoverParterArray}
                        offerStateArray={offerStateArray}
                        offerTypeArray={offerTypeArray}

                        offerPosArray={offerPosArray}
                        setOfferPosArray={setOfferPosArray}
                    />

                    <HandoverAddPartner
                        handoverObject={handoverProtocolObject}
                        isOpenAddPartner={isOpenAddPartner}
                        setIsOpenAddPartner={setIsOpenAddPartner}
                        handoverPartnerArray={editHandoverParterArray}
                        partnerArray={partnerArray}
                        setHandoverPartnerArray={setEditHandoverParterArray}

                        offerPosArray={offerPosArray}
                        setOfferPosArray={setOfferPosArray}
                    />


                    <Typography variant="h5">
                        Zuweisung
                        <Button variant="contained" sx={{float: "right"}} onClick={handleAllocate} disabled={isLoading}>Speichern</Button>
                        <Button variant="outlined" sx={{float: "right", mr: 2}} onClick={() => setIsOpenAddOffer(true)}>Positionen aus Angebot hinzufügen</Button>
                    </Typography>

                    <Box sx={{mt:2}}/>

                    <Collapse in={isLoading}>
                        <LinearProgress/>
                    </Collapse>

                    <Collapse in={!wasSuccessfully}>
                        <Alert severity="warning">
                            Das Zuweisung der Übergabeprotokolle konnte nicht gespeichert werden!
                            <br/>Bitte kontrollieren Sie Ihre Angaben.
                        </Alert>
                    </Collapse>

                    <Box sx={{mt: 5}}/>
                    <Typography variant="h6">
                        Zugeordnete Partner
                        <Button variant="outlined" sx={{float: "right", mr: 2}} onClick={() => setIsOpenAddPartner(true)}>Partner hinzufügen</Button>
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableCell>Partner</TableCell>
                            <TableCell sx={{width: 200}}>Aktionen</TableCell>
                        </TableHead>
                        <TableBody>
                            {editHandoverParterArray.map(x => {
                                let partner = partnerArray.find(y => y.id === x.Partner)

                                return(
                                    <TableRow>
                                        <TableCell>{(partner === undefined) ? <>Fehler</> : `${partner.Contact_Person.Company?.company_name}`}</TableCell>
                                        <TableCell>
                                            <IconButton size="small" onClick={() => removePartner(x.Partner)}><Delete/></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>

                    <Box sx={{mt: 5}}/>
                    <Typography variant="h6">Angebotspositionen</Typography>
                    <HandoverPartnerPosTable
                        key={`handoverprotocol-null`}
                        handoverPartner={null} 
                        offerArray={offerArray}
                        partnerArray={partnerArray}
                        unitArray={unitArray}
                        offerPosArray={offerPosArray}
                        setOfferPosArray={setOfferPosArray}
                        handoverPartnerArray={editHandoverParterArray}
                        
                    />
                    {editHandoverParterArray.map(x =>
                        <HandoverPartnerPosTable
                            key={`handoverprotocol-${x.id}`}
                            handoverPartner={x} 
                            offerArray={offerArray}
                            partnerArray={partnerArray}
                            unitArray={unitArray}
                            offerPosArray={offerPosArray}
                            setOfferPosArray={setOfferPosArray}
                            handoverPartnerArray={editHandoverParterArray}
                        />
                    )}
                </Box>
            </Dialog>



            <Button onClick={() => setIsOpenEdit(true)} variant="outlined" fullWidth>Zuweisung Ändern</Button>
        </>
    )
}