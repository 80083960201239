import React, { useEffect, useState } from "react";
import { IOfferMaterialPosition } from "../../../interfaces/IOfferMaterialPosition";
import { IUnit } from "../../../interfaces/IUnit";
import { IMaterial } from "../../../interfaces/IMaterial";
import { IMaterialCategory } from "../../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../../interfaces/IMaterialSubcategory";
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { Delete, Edit } from "@mui/icons-material";
import { OfferMaterialPositionEdit } from "./OfferMaterialPositionEdit";


interface IProps {
    currentMaterialPosition:  IOfferMaterialPosition;
    offerMaterialPositionArray: IOfferMaterialPosition[];
    setOfferMaterialPositionArray: Function;
    unitArray: IUnit[];
    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
}


export const OfferMaterialPositionRow:React.FC<IProps> = ({
    currentMaterialPosition, 
    offerMaterialPositionArray, setOfferMaterialPositionArray,
    unitArray, materialArray, materialCategoryArray, materialSubcategoryArray
}) => {
    const [editCurrentMaterialPos,setEditCurrentMaterialPos] = useState(currentMaterialPosition);
    //
    const [isOpenEdit,setIsOpenEdit] = useState(false);

    useEffect(() => {
        setOfferMaterialPositionArray([
            ...offerMaterialPositionArray.map(x => x.id === currentMaterialPosition.id ? editCurrentMaterialPos: x)
        ])
    },[editCurrentMaterialPos])


    const handleRemoveMe = () => {
        setOfferMaterialPositionArray([
            ...offerMaterialPositionArray.filter(x => x.id !== currentMaterialPosition.id)
        ])
    }


    return(
        <>
            <Dialog open={isOpenEdit} onClose={() => setIsOpenEdit(false)} maxWidth="md" fullWidth>
                <DialogTitle>Material bearbeiten</DialogTitle>
                <DialogContent>
                    <OfferMaterialPositionEdit
                        key={`offermaterialposition-edit-${editCurrentMaterialPos.id}`}
                        sx={{mt: 2}}
                        offerId={editCurrentMaterialPos.Offer}
                        currentMaterialPosition={editCurrentMaterialPos}
                        setCurrentMaterialPosition={setEditCurrentMaterialPos}
                        unitArray={unitArray}
                        materialArray={materialArray}
                        materialCategoryArray={materialCategoryArray}
                        materialSubcategoryArray={materialSubcategoryArray}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpenEdit(false)}>Okay</Button>
                </DialogActions>
            </Dialog>


            <TableRow>
                <TableCell />
                <TableCell>
                    {(editCurrentMaterialPos.Material === null)
                        ? `${materialCategoryArray.find(y => y.id === editCurrentMaterialPos.MaterialCategory)?.materialCategoryNumber}${materialSubcategoryArray.find(y => y.id === editCurrentMaterialPos.MaterialSubcategory)?.materialSubcategoryNumber}000`
                        : `${materialArray.find(y => y.id === editCurrentMaterialPos.Material)?.articelNumber}`
                    }
                </TableCell>
                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Bezeichnung"
                        object={editCurrentMaterialPos}
                        setObject={setEditCurrentMaterialPos}
                        attr="title"
                        type="string"
                        error={editCurrentMaterialPos.title === ""}
                    />
                </TableCell>

                <TableCell sx={{p: 0}}>
                    <IconButton title="Beschreibungstext anpassen" onClick={() => setIsOpenEdit(true)} size="small"><Edit/></IconButton>
                </TableCell>

                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Einheiten"
                        object={editCurrentMaterialPos}
                        setObject={setEditCurrentMaterialPos}
                        attr="units"
                        type="number"
                    />
                </TableCell>

                <TableCell sx={{p: 0}}>
                    <CustomeTextField
                        label="Einheit"
                        object={editCurrentMaterialPos}
                        setObject={setEditCurrentMaterialPos}
                        attr="Unit"
                        type="select"
                    >
                        {unitArray.map(unit =>
                            <MenuItem key={`materialpos-unit-${unit.id}`} value={unit.id}>
                                {unit.unit}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </TableCell>
                
                <TableCell sx={{p:0}}>
                    <CustomeTextField
                        label="PpE"
                        object={editCurrentMaterialPos}
                        setObject={setEditCurrentMaterialPos}
                        attr="price_per_unit"
                        type="euro"
                    />
                </TableCell>
                <TableCell>
                    <Typography sx={{float: "right"}}>
                        {(currentMaterialPosition.units*currentMaterialPosition.price_per_unit).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                    </Typography>
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleRemoveMe}><Delete/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
} 