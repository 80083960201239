import React, { useState } from "react";
import { ITaskPriority } from "../../../interfaces/ITaskPriority";
import { ITaskState } from "../../../interfaces/ITaskState";
import { ITaskType } from "../../../interfaces/ITaskType";
import { ITask } from "../../../interfaces/ITask";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Switch, Typography } from "@mui/material";
import { Add, Task } from "@mui/icons-material";
import { getTmpId, insertIntoArray } from "../../../components/core/generic_helpders";
import { TaskEdit } from "../../../components/task/TaskEdit";
import { IUserSmall } from "../../../interfaces/IUser";
import { uploadFetch, uploadFetchFeedback } from "../../../hooks/useFetch";
import { IFeedback } from "../../../interfaces/IFeedback";
import { TaskView } from "./TaskView";


interface IProps {
    taskPriorityArray: ITaskPriority[];
    taskStateArray: ITaskState[];
    taskTypeArray: ITaskType[];
    taskArray: ITask[];
    userSmallArray: IUserSmall[];
    setTaskArray: Function;
}


const getEmptyTask = (taskArray:ITask[]) => {
    let tmpId = getTmpId(taskArray);

    let tmpObject:ITask = {
        id: tmpId,
        title: "",
        Creator: Number(localStorage.getItem("idProfile")),
        Editor: Number(localStorage.getItem("idProfile")),
        Customer: null,
        TaskPriority: 2,
        TaskState: 1,
        TaskType: 1,
        deadline: null,
        closed_at: null,
        description: "",
        Project: null,
        return_message: false,
    }
    return tmpObject
}



export const TaskOverview:React.FC<IProps> = ({
    taskPriorityArray, taskStateArray, taskTypeArray,
    taskArray, setTaskArray,
    userSmallArray
}) => {
    const [isOpenNew,setIsOpenNew] = useState(false);
    const [editTask,setEditTask] = useState(getEmptyTask(taskArray));
    //
    const [isLoading,setIsLoading] = useState(false);
    const [feedback,setFeedback] = useState<IFeedback>({ open: false,message: "Gespeichert!", severity: "success" } as IFeedback)
    //
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };


    const handleAddNewTask = () => {
        setEditTask(getEmptyTask(taskArray));
        setIsOpenNew(true);
    }


    const handleAfterSave = (newObject:ITask) => {
        setTaskArray(insertIntoArray(newObject,taskArray))
        setEditTask(newObject);
        setIsOpenNew(false);
    }
    const handleNew = () => {
        uploadFetchFeedback("/task/",true,editTask,handleAfterSave,setIsLoading,setFeedback)
    }


    return(
        <>
            <Dialog open={isOpenNew} onClose={() => setIsOpenNew(false)} maxWidth="sm"fullWidth>
                <DialogTitle>Neue Aufgabe</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}/>
                    <TaskEdit
                        key={`new-task`}
                        editTask={editTask}
                        setEditTask={setEditTask}
                        taskArray={taskArray}
                        taskPriorityArray={taskPriorityArray}
                        taskStateArray={taskStateArray}
                        taskTypeArray={taskTypeArray}
                        userSmallArray={userSmallArray}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" disabled={isLoading} onClick={() => setIsOpenNew(false)}>Abbruch</Button>
                    <Button onClick={handleNew} variant="contained" disabled={isLoading || editTask.title === ""}>Speichern</Button>
                </DialogActions>
            </Dialog>


            <Typography variant="h6">
                Tickets
                <IconButton sx={{float: "right"}} onClick={handleAddNewTask}><Add/></IconButton>
                <FormGroup sx={{float: "right"}}>
                    <FormControlLabel  labelPlacement="start" control={
                        <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    } label="Abgeschlossene Einblenden" />
                </FormGroup>

            </Typography>
            <Grid container spacing={1}>
                {taskStateArray.filter(x => (checked) ? true : !x.is_closed).map(state =>
                    <Grid
                        key={`state-grid-${state.id}`}
                        item
                        xs={12 / taskStateArray.filter(x => (checked) ? true : !x.is_closed).length}
                        sx={{ borderRight: 'solid' }}
                    >
                        <Box sx={{ mt: 3, mb: 3, textAlign: 'center' }}>
                            <b>
                                <u>{state.taskState}</u>
                            </b>
                        </Box>
                        {taskArray.filter(task => task.TaskState === state.id).map(tasl =>

                            <TaskView
                                key={`view-task-${tasl.id}`}
                                taskObject={tasl}
                                taskArray={taskArray}
                                setTaskArray={setTaskArray}
                                taskPriorityArray={taskPriorityArray}
                                taskStateArray={taskStateArray}
                                taskTypeArray={taskTypeArray}
                                userSmallArray={userSmallArray}
                            />

                        )}
                    </Grid>
                )}
            </Grid>
        </>
    )
}