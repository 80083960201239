import React, {useEffect, useState} from "react";
import {ICustomer} from "../../interfaces/ICustomer";
import {
    Box,
    Card,
    CardContent,
    Collapse,
    Switch,
    Grid,
    MenuItem,
    Typography,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material";
import {CustomeTextField} from "../core/CustomeTextField";
import {IInsurance} from "../../interfaces/IInsurance";
import {IGender} from "../../interfaces/IGender";
import {ITitle} from "../../interfaces/ITitle";
import {CompanyEdit, getEmptyCompany} from "../company/CompanyEdit";
import {getEmptyAddress} from "../../sites/CustomerManagment/CustomerOverview";
import PostcodeSearchV2 from "../core/PostcodeSearchV2";
import {IPropertyManagment} from "../../interfaces/IPropertyManagement";
import {useFetch, uploadFetch} from "../../hooks/useFetch";
import {IFeedback} from "../../interfaces/IFeedback";
import {InsuranceEdit} from "../insurance/InsuranceEdit";
import {getEmptyInsurance} from "../../sites/InsuranceManagement/InsuranceOverview";
import {getEmptyPropertyManagement} from "../../sites/PropertyManagement/PropertyManagementOverview";
import {IAddressType} from "../../interfaces/IAddress";
import {PropertyManagmentEdit} from "../propertymanagment/PropertyManagmentEdit";
import {CustomSnackbar} from "../core/Snackbar";
import {InsuranceContactCard} from "./Childs/InsuranceContactCard";
import {GridExpandMoreIcon} from "@mui/x-data-grid";
import {PropertyManagementContactCard} from "./Childs/PropertyManagementContactCard";

interface IProps {
    genderArray: IGender[];
    addressTypeData: IAddressType[];
    titleArray: ITitle[];
    insuranceArray: IInsurance[];
    setInsuranceArray: Function;
    propertyManagementArray?: IPropertyManagment[];
    setPropertyManagementArray: Function;
    customerObject: ICustomer;
    setCustomerObject: Function;
    disableB2bSelection?: boolean;
    areRequiredFieldsSet?: boolean;
    setAreRequiredFieldsSet?: Function;
    setMissingFields?: Function;
}


export const getEmptyCustomer = (isB2b: boolean = false) => {
    return {
        id: 0,
        Title: 1,
        Gender: 6,
        firstname: '',
        lastname: '',
        insured: false,
        Insurance: null,
        tenant: false,
        PropertyManagement: null,
        tenantNumber: '',
        b2b: isB2b,
        Company: undefined,
        Address: getEmptyAddress(1),
        telefon: '',
        mobil: '',
        email: '',
        birthday: null,
        birthplace: null,
        iban: null,
        insuranceNumber: ""
    } as ICustomer
}


export const CustomerEdit: React.FC<IProps> = ({
                                                   genderArray,
                                                   addressTypeData,
                                                   titleArray,
                                                   insuranceArray,
                                                   setInsuranceArray,
                                                   propertyManagementArray,
                                                   setPropertyManagementArray,
                                                   customerObject,
                                                   setCustomerObject,
                                                   disableB2bSelection,
                                                   areRequiredFieldsSet,
                                                   setAreRequiredFieldsSet,
                                                   setMissingFields

                                               }) => {
    const [customerFullObject, setCustomerFullObject, statusCodeCustomer] = useFetch<ICustomer>("/customer/", customerObject.id);
    //
    const [feedback, setFeedback] = useState<IFeedback>({open: false, message: '', severity: 'info'});
    const [addressObject, setAddressObject] = useState(customerObject?.Address || getEmptyAddress(1));
    const [companyObject, setCompanyObject] = useState(customerObject?.Company || getEmptyCompany());

    //
    const [isOpenNewInsurance, setIsOpenNewInsurance] = useState(false);
    const [isOpenNewPropertyManagement, setIsOpenPropertyManagement] = useState(false);

    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [isLoadgin, setIsLoading] = useState(false);

    const [editInsurance, setEditInsurance] = useState<IInsurance>(getEmptyInsurance());
    const [editPropertyManagement, setEditPropertyManagement] = useState<IPropertyManagment>(getEmptyPropertyManagement());

    //



    useEffect(() => {
        if (areRequiredFieldsSet !== undefined && setAreRequiredFieldsSet !== undefined) {
            let allFieldsSet = true;
            let missingFields: string[] = [];

            if (customerObject.insured) {
                if (customerObject.birthday === null || customerObject.birthday === "") missingFields.push('Geburtstag');
                if (customerObject.Insurance === null ) missingFields.push('Pflegekasse');
                if (customerObject.insuranceNumber === null || customerObject.insuranceNumber === "") missingFields.push('Versicherungsnummer');
                allFieldsSet = allFieldsSet && missingFields.length === 0;
            }

            if (customerObject.tenant) {
                if (customerObject.PropertyManagement === null || customerObject.PropertyManagement <= 0) missingFields.push('Hausverwaltung');
                if (customerObject.tenantNumber === null || customerObject.tenantNumber === "") missingFields.push('Mieternummer');
                allFieldsSet = allFieldsSet && missingFields.length === 0;
            }

            setAreRequiredFieldsSet(allFieldsSet);
            if (setMissingFields) {
                setMissingFields(missingFields);
            }
        }
    }, [customerFullObject, customerObject]);



    useEffect(() => {
        if (customerFullObject !== undefined) {
            setCustomerObject(customerFullObject);
            if (customerFullObject.b2b) {
                setCompanyObject(customerFullObject.Company!);
            } else {
                setAddressObject(customerFullObject.Address!);
            }


        }
    }, [customerFullObject])


    useEffect(() => {
        if (customerObject) {
            setCustomerObject({
                ...customerObject,
                Address: addressObject,
                Company: companyObject
            });
        }
    }, [addressObject, companyObject]);


    const handleAfterSave = (savedObject: IInsurance | IPropertyManagment, action: "Insurance" | "PropertyManagement") => {
        if (action === "Insurance") {
            setInsuranceArray([
                ...insuranceArray,
                savedObject
            ])
            setFeedback({open: true, message: "Pflegekrasse erfolgreich erstellt!", severity: "success"})
            setIsOpenNewInsurance(false)
            setCustomerObject({
                ...customerObject,
                Insurance: savedObject.id
            })
            setEditInsurance(getEmptyInsurance())
        } else {
            setPropertyManagementArray([...(propertyManagementArray || []), savedObject])
            setFeedback({open: true, message: "Hausverwaltung erfolgreich erstellt!", severity: "success"})
            setIsOpenPropertyManagement(false)
            setCustomerObject({
                ...customerObject,
                PropertyManagement: savedObject.id
            })
            setEditPropertyManagement(getEmptyPropertyManagement())
        }
    }

    const openNew = (action: "Insurance" | "PropertyManagement") => {
        if (action === "Insurance") {
            setIsOpenNewInsurance(true)
        } else {
            setIsOpenPropertyManagement(true)
        }

    }

    const handleNew = (action: "Insurance" | "PropertyManagement") => {
        if (action === "Insurance") {
            uploadFetch("/insurance/", true, editInsurance, (e: any) => {
                handleAfterSave(e, "Insurance")
            }, setWasSuccessfully, () => {
            }, setIsLoading)
        } else {
            uploadFetch("/property_management/", true, editPropertyManagement, (e: any) => {
                handleAfterSave(e, "PropertyManagement")
            }, setWasSuccessfully, () => {
            }, setIsLoading)
        }
    }


    if (customerObject.id > 0 && customerFullObject === undefined) {
        return <CircularProgress/>
    } else {
        return (
            <>
                <Dialog onClose={() => setIsOpenPropertyManagement(false)} maxWidth="md" fullWidth
                        open={isOpenNewPropertyManagement}>
                    <DialogTitle>Neue Hausverwaltung</DialogTitle>
                    <DialogContent>
                        <PropertyManagmentEdit
                            key={`editPropertyManagement-${editPropertyManagement.id}`}
                            propertyManagementObject={editPropertyManagement}
                            setpropertyManagementObject={setEditPropertyManagement}
                            genderArray={genderArray}
                            titleArray={titleArray}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenPropertyManagement(false)}>Abbruch</Button>
                        <Button variant="contained" onClick={() => {
                            handleNew("PropertyManagement")
                        }}>Hinzufügen</Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={() => setIsOpenNewInsurance(false)} maxWidth="md" fullWidth open={isOpenNewInsurance}>
                    <DialogTitle>Neue Pflegekasse</DialogTitle>
                    <DialogContent>
                        <InsuranceEdit
                            key={`editInsurance-${editInsurance.id}`}
                            addressTypeData={addressTypeData || []}
                            insuranceObject={editInsurance}
                            setInsuranceObject={setEditInsurance}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenNewInsurance(false)}>Abbruch</Button>
                        <Button variant="contained" onClick={() => {
                            handleNew("Insurance")
                        }}>Hinzufügen</Button>
                    </DialogActions>
                </Dialog>


                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>
                {((disableB2bSelection === undefined) || disableB2bSelection === false) &&
                    <Grid container spacing={2} sx={{mt: 3}}>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Firma"
                                attr="b2b"
                                type="boolean"
                                object={customerObject}
                                setObject={setCustomerObject}
                            />
                        </Grid>
                    </Grid>
                }


                <Collapse in={customerObject.b2b}>
                    <Box>
                        {customerObject.b2b &&
                            <>
                                <CompanyEdit
                                    key={`own-company-${companyObject.id}`}
                                    companyObject={companyObject}
                                    setCompanyObject={setCompanyObject}
                                    addressTypeData={[]}
                                />
                            </>
                        }
                    </Box>
                </Collapse>

                {customerObject.b2b &&
                    <Card variant="outlined" sx={{mt: 1, mb: 2}}>
                        <CardContent>
                            <Typography>Ansprechpartner</Typography>
                            <Grid container spacing={2} sx={{mt: 1}}>
                                <Grid item sm={2}>
                                    <CustomeTextField
                                        label="Titel"
                                        attr="Title"
                                        type="select"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                    >
                                        {titleArray.map(x => (
                                            <MenuItem key={`Title-${x.id}`} value={x.id}>
                                                {x.title}
                                            </MenuItem>
                                        ))}
                                    </CustomeTextField>
                                </Grid>
                                <Grid item sm={2}>
                                    <CustomeTextField
                                        label="Anrede"
                                        attr="Gender"
                                        type="select"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                    >
                                        {genderArray.map(x => (
                                            <MenuItem key={`Gender-${x.id}`} value={x.id}>
                                                {x.gender}
                                            </MenuItem>
                                        ))}
                                    </CustomeTextField>
                                </Grid>
                                <Grid item sm={4}>
                                    <CustomeTextField
                                        label="Vorname"
                                        attr="firstname"
                                        type="string"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                        required
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <CustomeTextField
                                        label="Nachname"
                                        attr="lastname"
                                        type="string"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                        required
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomeTextField
                                        label="Telefon"
                                        attr="telefon"
                                        type="string"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomeTextField
                                        label="Mobil"
                                        attr="mobil"
                                        type="string"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <CustomeTextField
                                        label="Email"
                                        attr="email"
                                        type="email"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>}
                {!customerObject.b2b &&
                    <Grid container spacing={1} sx={{mt: 3}}>
                        <Grid item sm={2}>
                            <CustomeTextField
                                label="Titel"
                                attr="Title"
                                type="select"
                                object={customerObject}
                                setObject={setCustomerObject}
                            >
                                {titleArray.map(x => (
                                    <MenuItem key={`Title-${x.id}`} value={x.id}>
                                        {x.title}
                                    </MenuItem>
                                ))}
                            </CustomeTextField>
                        </Grid>
                        <Grid item sm={2}>
                            <CustomeTextField
                                label="Anrede"
                                attr="Gender"
                                type="select"
                                object={customerObject}
                                setObject={setCustomerObject}
                            >
                                {genderArray.map(x => (
                                    <MenuItem key={`Gender-${x.id}`} value={x.id}>
                                        {x.gender}
                                    </MenuItem>
                                ))}
                            </CustomeTextField>
                        </Grid>
                        <Grid item sm={4}>
                            <CustomeTextField
                                label="Vorname"
                                attr="firstname"
                                type="string"
                                object={customerObject}
                                setObject={setCustomerObject}
                                required
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <CustomeTextField
                                label="Nachname"
                                attr="lastname"
                                type="string"
                                object={customerObject}
                                setObject={setCustomerObject}
                                required
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Telefon"
                                attr="telefon"
                                type="string"
                                object={customerObject}
                                setObject={setCustomerObject}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Mobil"
                                attr="mobil"
                                type="string"
                                object={customerObject}
                                setObject={setCustomerObject}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Email"
                                attr="email"
                                type="email"
                                object={customerObject}
                                setObject={setCustomerObject}
                            />
                        </Grid>
                    </Grid>
                }


                <Collapse in={!customerObject.b2b} sx={{mt: 2}}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<GridExpandMoreIcon/>}
                            id="panel1-header"
                        >
                            Erweiterte Informationen
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item sm={8}>
                                    <CustomeTextField
                                        label="Straße"
                                        attr="street"
                                        type="string"
                                        object={addressObject}
                                        setObject={setAddressObject}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <CustomeTextField
                                        label="Nr."
                                        attr="street_number"
                                        type="string"
                                        object={addressObject}
                                        setObject={setAddressObject}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <PostcodeSearchV2
                                        idPostcode={addressObject.Postcode}
                                        setIdPostcode={(idPostcode: number) => setAddressObject({
                                            ...addressObject,
                                            Postcode: idPostcode
                                        })}
                                    />
                                </Grid>

                            </Grid>

                            <Grid container spacing={2} sx={{mt: 3}}>
                                <Grid item sm={6}>
                                    <CustomeTextField
                                        label="Geburtstag"
                                        attr="birthday"
                                        type="date"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                        null
                                        error={customerObject.insured && (customerObject.birthday === null || customerObject.birthday === "")}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomeTextField
                                        label="Geburtsort"
                                        attr="birthplace"
                                        type="string"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                        null
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <CustomeTextField
                                        label="IBAN"
                                        attr="iban"
                                        type="string"
                                        object={customerObject}
                                        setObject={setCustomerObject}
                                        null
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Grid container spacing={2} sx={{mt: 2}}>
                        <Grid item sm={12} display="flex" alignItems="center">
                            <CustomeTextField
                                label="Versicherung"
                                attr="insured"
                                type="boolean"
                                object={customerObject}
                                setObject={setCustomerObject}
                            />
                            {customerObject.insured &&
                                <Button variant="outlined" sx={{width: 350, marginLeft: 10}} onClick={() => {
                                    openNew("Insurance")
                                }}>
                                    Neue Pflegekasse
                                </Button>
                            }
                        </Grid>
                        <Grid item sm={12}>
                            <Collapse in={customerObject.insured} sx={{mb: 2}}>
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        <CustomeTextField
                                            label="Pflegekasse"
                                            attr="Insurance"
                                            type="select"
                                            object={customerObject}
                                            setObject={setCustomerObject}
                                            error={customerObject.insured && (customerObject.Insurance === null || customerObject.Insurance <= 0)}
                                        >
                                            {insuranceArray.map(x => (
                                                <MenuItem key={`Insurance-${x.id}`} value={x.id}>
                                                    {x.insurance_name}
                                                </MenuItem>
                                            ))}
                                        </CustomeTextField>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomeTextField
                                            label="Versicherungsnummer"
                                            attr="insuranceNumber"
                                            type="string"
                                            object={customerObject}
                                            setObject={setCustomerObject}
                                            error={customerObject.insured && (customerObject.insuranceNumber === null || customerObject.insuranceNumber === "")}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <InsuranceContactCard
                                            is_insured={customerObject.insured}
                                            insurance={customerObject.Insurance}
                                            insuranceArray={insuranceArray}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item sm={12} display="flex" alignItems="center">
                            <CustomeTextField
                                label="Zur Miete"
                                attr="tenant"
                                type="boolean"
                                object={customerObject}
                                setObject={setCustomerObject}
                            />
                            {customerObject.tenant &&
                                <Button variant="outlined" sx={{width: 350, marginLeft: 10}} onClick={() => {
                                    openNew("PropertyManagement")
                                }}>
                                    Neue Hausverwaltung
                                </Button>
                            }
                        </Grid>
                        <Grid item sm={12}>
                            <Collapse in={customerObject.tenant}>
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        <CustomeTextField
                                            label="Hausverwaltung"
                                            attr="PropertyManagement"
                                            type="select"
                                            object={customerObject}
                                            setObject={setCustomerObject}
                                            error={customerObject.tenant && (customerObject.PropertyManagement === null || customerObject.PropertyManagement <= 0)}
                                        >
                                            {propertyManagementArray?.map(x => (
                                                <MenuItem key={`PropertyManagement-${x.id}`} value={x.id}>
                                                    {x?.Contact_Person?.Company?.company_name}
                                                </MenuItem>
                                            ))}
                                        </CustomeTextField>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <CustomeTextField
                                            label="Mieternummer"
                                            attr="tenantNumber"
                                            type="string"
                                            object={customerObject}
                                            setObject={setCustomerObject}
                                            error={customerObject.tenant && (customerObject.tenantNumber === null || customerObject.tenantNumber === "")}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <PropertyManagementContactCard
                                            is_tened={customerObject.tenant}
                                            propertyManagement={customerObject.PropertyManagement}
                                            propertyManagementArray={propertyManagementArray || []}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Collapse>

            </>
        )
    }

}