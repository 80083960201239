import React, { useEffect, useState } from "react";
import { IMaterialSupplier } from "../../../interfaces/IMaterialSupplier";
import { IPartner } from "../../../interfaces/IPartner";
import { IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { Delete } from "@mui/icons-material";


interface IProps {
    materialSupplierObject: IMaterialSupplier;
    materialSupplierArray: IMaterialSupplier[];
    setMaterialSupplierArray: Function;
    partnerArray: IPartner[];
}


export const MaterialSuppierRow:React.FC<IProps> = ({materialSupplierObject,materialSupplierArray,setMaterialSupplierArray,partnerArray}) => {
    const [editMaterialSupplier,setEditMaterialSupplier] = useState(materialSupplierObject);

    useEffect(() => {
        setMaterialSupplierArray([
            ...materialSupplierArray.map(x => x.id === editMaterialSupplier.id ? editMaterialSupplier : x )
        ])
    },[editMaterialSupplier])
    

    const handleDelete = () => {
        setMaterialSupplierArray([
            ...materialSupplierArray.filter(x => x.id !== editMaterialSupplier.id)
        ])
    }


    return(
        <>

            <TableRow>
                <TableCell sx={{pl: 0, pr:0}}>
                    <CustomeTextField
                        label="Partner"
                        attr="Partner"
                        type="select"
                        object={editMaterialSupplier}
                        setObject={setEditMaterialSupplier}
                    >
                        {partnerArray.map(x =>
                            <MenuItem key={`partner-materialsupplier-${x.id}`} value={x.id}>{x.Contact_Person.Company?.company_name}</MenuItem>
                        )}
                    </CustomeTextField>
                </TableCell>
                <TableCell sx={{pl: 0, pr:0}}>
                    <CustomeTextField
                        label="Produktnummer"
                        attr="product_number"
                        type="string"
                        object={editMaterialSupplier}
                        setObject={setEditMaterialSupplier}
                    />
                </TableCell>
                <TableCell sx={{pl: 0, pr:0}}>
                    <CustomeTextField
                        label="EK"
                        attr="purchasing_price"
                        type="number"
                        object={editMaterialSupplier}
                        setObject={setEditMaterialSupplier}
                    />
                </TableCell>
                <TableCell sx={{pl: 0, pr:0}}>
                    <CustomeTextField
                        label="VK"
                        attr="selling_price"
                        type="number"
                        object={editMaterialSupplier}
                        setObject={setEditMaterialSupplier}
                    />
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleDelete}><Delete/></IconButton>
                </TableCell>

            </TableRow>
        </>
    )
}