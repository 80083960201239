import React, { useState } from "react";
import { ITaskPriority } from "../../interfaces/ITaskPriority";
import { ITaskState } from "../../interfaces/ITaskState";
import { ITaskType } from "../../interfaces/ITaskType";
import { ITask } from "../../interfaces/ITask";
import { Grid, Menu, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IUserSmall } from "../../interfaces/IUser";
import {
    DateTimePicker,
    LocalizationProvider,
    deDE,
  } from '@mui/x-date-pickers';
  import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
  import { de } from 'date-fns/locale';
import { CustomeDateTimePicker } from "../core/CustomeDateTimePicker";


interface IProps {
    editTask: ITask;
    setEditTask: Function;
    taskPriorityArray: ITaskPriority[];
    taskStateArray: ITaskState[];
    taskTypeArray: ITaskType[];
    taskArray: ITask[];
    userSmallArray: IUserSmall[];
}

export const TaskEdit:React.FC<IProps> = ({
    editTask, setEditTask,
    taskPriorityArray, taskStateArray, taskTypeArray,
    taskArray, userSmallArray
}) => {
    const [userCretor,setUserCretor] = useState(userSmallArray.find(x => x.profile === editTask.Creator));
    return(
        <>
            {(editTask.id > 0) &&
                <Grid container sx={{mb: 5}}>
                    <Grid item xs={4}><b>Erteller:</b></Grid>
                    <Grid item xs={8}>{userCretor?.first_name} {userCretor?.last_name}</Grid>

                    <Grid item xs={4}><b>Erstellt:</b></Grid>
                    <Grid item xs={8}>{new Date(String(editTask.created_at)).toLocaleString()}</Grid>

                    <Grid item xs={4}><b>Aktualisiert:</b></Grid>
                    <Grid item xs={8}>{new Date(String(editTask.updated_at)).toLocaleString()}</Grid>
                    {(editTask.closed_at !== null) &&
                    <>
                        <Grid item xs={4}><b>Geschlossen:</b></Grid>
                        <Grid item xs={8}>{new Date(String(editTask.closed_at)).toLocaleString()}</Grid>
                    </>}
                </Grid>
            }

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <CustomeTextField
                        label="Phase"
                        attr="TaskState"
                        object={editTask}
                        setObject={setEditTask}
                        type="select"
                        disabled={editTask.closed_at !== null}
                    >
                        {taskStateArray.map(x =>
                            <MenuItem key={`edit-taskstate-${x.id}`} value={x.id}>{x.taskState}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item xs={8}>
                    <CustomeTextField
                        label="Titel"
                        attr="title"
                        object={editTask}
                        setObject={setEditTask}
                        type="string"
                        required
                        disabled={editTask.closed_at !== null}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomeTextField
                        label="Bearbeiter"
                        attr="Editor"
                        object={editTask}
                        setObject={setEditTask}
                        type="select"
                        disabled={editTask.closed_at !== null}
                    >
                        {userSmallArray.map(x =>
                            (x.profile ===  Number(localStorage.getItem("idProfile")) )
                                ? <MenuItem key={`edit-useredit-${x.id}`} value={x.profile}>Ich</MenuItem>
                                : <MenuItem key={`edit-useredit-${x.id}`} value={x.profile}>{x.first_name} {x.last_name}</MenuItem>
                            
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item xs={9}>
                    <CustomeDateTimePicker
                        dateValue={editTask.deadline}
                        setDateValue={(value:String|null) => setEditTask({...editTask, deadline: value})}
                        allowNull
                        allowPast={false}
                        disabled={editTask.closed_at !== null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomeTextField
                        label="Rückmeldung"
                        attr="return_message"
                        object={editTask}
                        setObject={setEditTask}
                        type="boolean"
                        disabled={editTask.closed_at !== null}
                    />
                </Grid>


                <Grid item xs={6}>
                    <CustomeTextField
                        label="Status"
                        attr="TaskPriority"
                        object={editTask}
                        setObject={setEditTask}
                        type="select"
                        disabled={editTask.closed_at !== null}
                    >
                        {taskPriorityArray.map(x =>
                            <MenuItem key={`edit-taskPriority-${x.id}`} value={x.id}>{x.taskPriority}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item xs={6}>
                    <CustomeTextField
                        label="Art"
                        attr="TaskType"
                        object={editTask}
                        setObject={setEditTask}
                        type="select"
                        disabled={editTask.closed_at !== null}
                    >
                        {taskTypeArray.map(x =>
                            <MenuItem key={`edit-taskTypeArray-${x.id}`} value={x.id}>{x.taskType}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item xs={12}>
                    <CustomeTextField
                        label="Beschreibung"
                        attr="description"
                        object={editTask}
                        setObject={setEditTask}
                        type="string"
                        rows={5}
                        disabled={editTask.closed_at !== null}
                    />
                </Grid>
            </Grid>
        </>
    )
}