import React, { useEffect, useState } from "react";
import { IOfferApplicationDocument } from "../../../interfaces/IOfferApplicationDocument";
import { ITemplate } from "../../../interfaces/ITemplate";
import { IOfferApplicationDocumentAnswer } from "../../../interfaces/IOfferApplicationDocumentAnswer";
import { ITemplateQuestion } from "../../../interfaces/ITemplateQuestion";
import { Grid, MenuItem, TextField } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";


interface IProps {
    template: ITemplate;
    offerAnswerObject: IOfferApplicationDocumentAnswer;
    offerApplicationDocument: IOfferApplicationDocument;
    templateArray: ITemplate[];
    offerAnswerArray: IOfferApplicationDocumentAnswer[];
    setOfferAnswerArray: Function;
}


export const OfferSignedPageQuestionRow: React.FC<IProps> = ({ template, offerAnswerObject, offerApplicationDocument, templateArray, offerAnswerArray, setOfferAnswerArray }) => {
    const [currenAnswerObject, setCurrenAnswerObject] = useState(offerAnswerObject);
    const [localTemplateQuestion,setLocalTemplateQuestion] = useState(template.TemplateQuestions.find(x => x.id === offerAnswerObject.TemplateQuestions));

    useEffect(() => {
        if (localTemplateQuestion !== undefined) {
            setOfferAnswerArray([
                ...offerAnswerArray.map(x => x.id === currenAnswerObject.id ? currenAnswerObject : x)
            ])
        }

    }, [currenAnswerObject])

    if (localTemplateQuestion === undefined) { return <>Error!</> } 
    else {
        return (
            <>
                <Grid item sm={6}>
                    {localTemplateQuestion.question}
                </Grid>
    
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Antwort"
                        type="select"
                        attr="TemplateAnswers"
                        object={currenAnswerObject}
                        setObject={setCurrenAnswerObject}
                    >
                        {localTemplateQuestion.TemplateAnswers.map(answers =>
                            <MenuItem value={answers.id} key={`answer-${currenAnswerObject.id}-${answers.id}`}>
                                {answers.answer}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
    
            </>
        )
    }

}

