import {Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, TextField, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowParams, deDE} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {CustomSnackbar} from '../../components/core/Snackbar';
import {PartnerEdit} from "../../components/partner/PartnerEdit";
import {uploadFetch, useFetch, deleteFetch} from "../../hooks/useFetch";

//interfaces imports
import {IFeedback} from "../../interfaces/IFeedback";
import {IPartner} from "../../interfaces/IPartner";
import {getEmptyCompany} from "../../components/company/CompanyEdit";
import {getEmptyCustomer} from "../../components/customer/CustomerEdit";
import {IInsurance} from "../../interfaces/IInsurance";
import {IGender} from "../../interfaces/IGender";
import {ITitle} from "../../interfaces/ITitle";
import { Delete } from "@mui/icons-material";
import { IAddressType } from "../../interfaces/IAddress";
import { usePermissions } from "../../contexts/permissions/PermissionContext";


export const getEmptyPartner = () => {
    return {
        id: 0,
        selling_price: 0,
        purchasing_price: 0,
        Company: getEmptyCompany(),
        Contact_Person: getEmptyCustomer(true)
    } as IPartner
}


export const PartnerOverview: React.FC = () => {
    const [partnerData, setPartnerData, statusCode] = useFetch<IPartner[]>("/partner/");
    //
    const [insuranceData, setInsuranceCodeData, statusCodeInsurance] = useFetch<IInsurance[]>("/insurance/");
    const [genderData, setGenderData, statusCodeGender] = useFetch<IGender[]>("/customer/gender/");
    const [titleData, setTitleData, statusCodeTitle] = useFetch<ITitle[]>("/customer/academic-title/");
    const [addressTypeArray, setAddressTypeArray, statusCodeAddressType ] = useFetch<IAddressType[]>("/address/types/");

    const [editPartner, setEditPartner] = useState(getEmptyPartner());
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [isLoadgin, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    //
    const [feedback, setFeedback] = useState<IFeedback>({
        open: false,
        message: "Gespeichert!",
        severity: "success"
    } as IFeedback)

    const [searchTerm, setSearchTerm] = useState("");


    //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [partnerIdToDelete, setPartnerIdToDelete] = React.useState<number | null>(null);
    const is_superuser = localStorage.getItem("is_superuser")

    const { permissions } = usePermissions();

    const openDeleteConfirmationDialog = (partnerId: number) => {
        setPartnerIdToDelete(partnerId);
        setOpenDeleteDialog(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setPartnerIdToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleRemove = (partnerId: number) => {
        if (is_superuser === "true"){
            deleteFetch("/partner/", partnerId,setWasSuccessfully)
            setFeedback({ open: true,message: "Partnerfirma erfolgreich gelöscht!", severity: "success" })
            const newArray = (partnerData === undefined) ? "" : partnerData.filter(partner => partner.id !== partnerId);
            setPartnerData(newArray)
            console.log(partnerId)
            closeDeleteConfirmationDialog();
        }else{
            console.log("You have no permission.")
        }   
    };
//END OF DELETE PATTERNS

    const columns: GridColDef[] = [
        {
            field: 'partnerid',
            headerName: 'Nr.',
            valueGetter: (params) => params.row.id,
            width: 70
        },

        {
            field: 'telefon',
            headerName: 'Telefon',
            valueGetter: (params) => params.row.Contact_Person.telefon,
            width: 200
        },
        {
            field: 'email',
            headerName: 'E-Mail',
            valueGetter: (params) => params.row.Contact_Person.email,
            width: 350
        },

        {
            field: 'Company',
            headerName: 'Firma',
            valueGetter: (params) => params.row.Contact_Person.Company.company_name,
            width: 350
        },
        { 
            field: 'action', 
            headerName: 'Aktionen', 
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return (
                    <> {(is_superuser == "true"  || permissions.includes("delete_partner")) && <>
                        <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                            <Delete />
                        </IconButton>
                        <Dialog
                            open={openDeleteDialog && partnerIdToDelete === params.row.id}
                            onClose={closeDeleteConfirmationDialog}
                        >
                            <DialogTitle>Bestätigung Löschung</DialogTitle>
                            <DialogContent>
                                Sind Sie sicher, dass Sie "{params.row.Contact_Person.Company.company_name}" löschen wollen?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                    Abbruch
                                </Button>
                                <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                    Bestätigen
                                </Button>
                            </DialogActions>
                        </Dialog></>}
                    </>
                );
            },
            flex: 1
        },


    ]

    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = partnerData?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditPartner(getEmptyPartner())
        } else {
            setEditPartner(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditPartner(getEmptyPartner());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject: IPartner) => {
        if (partnerData !== undefined) {
            setFeedback({open: true, message: "Gespeichert!", severity: "success"})

            let testObject = partnerData.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setPartnerData([
                    ...partnerData,
                    savedObject
                ])
            } else {
                setPartnerData([
                    ...partnerData.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }


    const handleSave = () => {
        console.log("lel")
        setWasSuccessfully(true);

        if (editPartner.id === 0) {
            uploadFetch("/partner/", true, editPartner, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading)
        } else {

            uploadFetch(`/partner/${editPartner.id}`, false, editPartner, handleAfterSave, setWasSuccessfully, () => {
            }, setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({open: true, message: "Ein fehler ist aufgetretten!", severity: "error"})
            setIsLoading(false);
        }
    }, [wasSuccessfully])

    const handleSearchTerm = () => {
        if (partnerData === undefined) {
            return [];
        } else if (searchTerm === "") {
            return partnerData
        } else {
            return partnerData.filter(x =>
                String(x.Contact_Person.Company?.company_name).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.Contact_Person.email).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }

    if (
        (statusCode !== null && statusCode !== 200)
        || (statusCodeGender !== null && statusCodeGender !== 200)
        || (statusCodeInsurance !== null && statusCodeInsurance !== 200)
        || (statusCodeTitle !== null && statusCodeTitle !== 200)
    ) {
        return <Alert severity="error">Fehler!</Alert>
    } else if (
        partnerData === undefined
        || genderData === undefined
        || titleData === undefined
    ) {
        return <LinearProgress/>
    } else {
        return (
            <>
                <Typography variant="h5">
                    Partnerfirmen
                    {permissions.includes("add_partner") &&  <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Partnerfirma
                        hinzufügen</Button> }
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>
                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={(isOpen) ? 6 : 12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={handleSearchTerm()}
                            columns={columns}
                            //getRowId={(row) => row.id}
                            onRowClick={(params, event, details) => {
                                if(permissions.includes("change_partner")){
                                const target = event.target as HTMLElement;
                                const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                                if (isDeleteIconClicked) {
                                    event.stopPropagation();
                                    openDeleteConfirmationDialog(Number(params.id));
                                } else {
                                    handleChangeId(params)
                                }
                            }}}
                            autoHeight
                        />
                    </Grid>
                    <Grid item sm={(isOpen) ? 6 : 0}>
                        <Collapse in={isOpen}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6">
                                    Partner
                                    <Button sx={{float: "right", ml: 2}} type="submit" onClick={handleSubmit}
                                            variant="contained" disabled={isLoadgin}>Speichern</Button>
                                    <Button sx={{float: "right"}} onClick={() => setIsOpen(false)}
                                            variant="outlined">Abbruch</Button>
                                </Typography>
                                <PartnerEdit
                                    key={`editPartner-${editPartner.id}`}
                                    genderArray={genderData}
                                    addressTypeArray={addressTypeArray || []}
                                    partnerObject={editPartner}
                                    setPartnerObject={setEditPartner}
                                    titleArray={titleData}
                                />
                            </form>
                        </Collapse>
                    </Grid>
                </Grid>
            </>
        )
    }

}