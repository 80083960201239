import React, { useEffect, useState } from "react";
import { IDocument } from "../../interfaces/IDocument";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {Document, Page} from 'react-pdf'
import { pdfjs } from 'react-pdf';
import { Alert, Button } from "@mui/material";



pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}





interface IProps {
    documentObject: IDocument | null;
    Width?: number;
    Height?: number;
}



export const PDFViewer:React.FC<IProps> = ({documentObject, Height, Width}) => {
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);




      function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }


    const nextPage = () => {
        if(pageNumber < numPages)
        {
            setPageNumber(pageNumber+1)
        }
    }

    const previousPage = () => {
        if(pageNumber > 1)
        {
            setPageNumber(pageNumber-1)
        }
    }




    const tsxButtons = () => {
        return(
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button onClick={previousPage} variant="outlined"><ArrowBackIos /> Vorherige Seite</Button>

                <div style={{ textAlign: 'center', flex: 1 }}>
                Seite {pageNumber} von {numPages}
                </div>
                
                <Button onClick={nextPage} variant="outlined" sx={{ float: 'right' }}>Nächste Seite <ArrowForwardIos /></Button>
          </div>
        )
    }



    if (documentObject === null || documentObject.data === undefined) {
        return <><Alert severity="info">Keine Dateien zum Render gefunden</Alert></>
    }
    else if (documentObject.MIMEtype.MIMEtype !== "application/pdf") {
        return <><Alert severity="warning">Datentyp wird nicht unterstützt!</Alert></>
    }
    else {
        return(
            <>
                {tsxButtons()}
                
                <Document file={`data:application/pdf;base64,${documentObject.data}`} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page width={Width? Width : windowDimensions.width-100} customTextRenderer={undefined} renderTextLayer={false} renderAnnotationLayer={false} pageNumber={pageNumber} />
                </Document>

                {tsxButtons()}
            </>
        )
    }

}