import React, { useEffect } from "react";
import { IHandoverApplicationDocument } from "../../../interfaces/IHandoverApplicationDocument";
import { ITemplate } from "../../../interfaces/ITemplate";
import { IHandoverApplicationDocumentAnswers } from "../../../interfaces/IHandoverApplicationDocumentAnswers";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { HandoverApplicationDocumentAnswerRow } from "./HandoverApplicationDocumentAnswerRow";

interface IProps {
    applicationDocument: IHandoverApplicationDocument;
    templateObject: ITemplate
    answerArray: IHandoverApplicationDocumentAnswers[];
    setAnswerArray: Function;
}


export const HandoverApplicationDocumentAnswer:React.FC<IProps>  = ({applicationDocument, templateObject, answerArray, setAnswerArray}) => { 
    useEffect(() => {
        let tmpOfferAnswerArray:IHandoverApplicationDocumentAnswers[] = [];

        templateObject.TemplateQuestions.map( (question,idx) => {
            if (question.TemplateAnswers.length > 0) {
                tmpOfferAnswerArray.push({
                    id: (idx*-1)-1,
                    TemplateAnswers: question.TemplateAnswers[0].id,
                    TemplateQuestions: question.id,
                    HandoverApplicationDocument: applicationDocument.id
                } as IHandoverApplicationDocumentAnswers)
            }
        })
        setAnswerArray(tmpOfferAnswerArray);
    },[])



    return(
        <>
        {(templateObject.TemplateQuestions.length > 0) &&
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h6">{templateObject.title}</Typography>
                    <Grid container spacing={2} sx={{mt: 2}}>
                        {answerArray.map(x =>
                            <HandoverApplicationDocumentAnswerRow
                                key={`question-row-${x.id}`}
                                answerObject={x}
                                template={templateObject}
                                answerArray={answerArray}
                                setAnswerArray={setAnswerArray}
                                applicationDocument={applicationDocument}
                            />
                        )}
                    </Grid>
                </CardContent>
            </Card>
        }
        </>

    )
}