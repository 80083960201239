import React from "react";
import { SystemMailOverview } from "../../components/systemMail/SystemMailOverview";



export const Template:React.FC = () =>{
    return(
        <>
            <SystemMailOverview/>
        </>
    )
}