import { createContext, useContext } from 'react';


interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
}

const defaultAuthContext: AuthContextType = {
  isAuthenticated: false,
  loading: true,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};


export {AuthContext, useAuthContext };