import React, { useEffect, useState } from "react";
import { IMaterialCategory } from "../../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../../interfaces/IMaterialSubcategory";
import { IOfferMaterialPosition } from "../../../interfaces/IOfferMaterialPosition";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { getEmptyOfferMaterialPosition, OfferMaterialPositionEdit } from "./OfferMaterialPositionEdit";
import { IMaterial } from "../../../interfaces/IMaterial";
import { off } from "process";
import { IUnit } from "../../../interfaces/IUnit";
import { OfferMaterialPositionRow } from "./OfferMaterialPositionRow";
import { IOffer } from "../../../interfaces/IOffer";
import { OfferMaterialPositionAssign } from "./OfferMaterialPositionAssign";
import { getTmpId } from "../../core/generic_helpders";


interface IProps {
    offerObject: IOffer;
    offerMaterialPositionArray: IOfferMaterialPosition[];
    setOfferMaterialPositionArray: Function;
    unitArray: IUnit[];
    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
    isOpenAddMaterial: boolean;
    setIsOpenAddMaterial: Function;
    isOpenAssignMaterial: boolean;
    setIsOpenAssignMaterial: Function;
}






export const OfferMaterialPositionTable:React.FC<IProps> = ({
    offerObject,
    offerMaterialPositionArray, setOfferMaterialPositionArray,
    unitArray, materialArray, materialCategoryArray, materialSubcategoryArray,
    isOpenAddMaterial, setIsOpenAddMaterial,
    isOpenAssignMaterial, setIsOpenAssignMaterial
}) => {
    const [isOpen,setIsOpen] = useState(false);
    //
    const [newMaterialPos,setNewMaterialPos] = useState(getEmptyOfferMaterialPosition(offerObject.id,offerMaterialPositionArray));
    const [total,setTotal] = useState(0);
    //
    const [assignMaterialList,setAssignMaterialList] = useState<number[]>([]);



    useEffect(() => {
        if (isOpenAddMaterial) {
            setNewMaterialPos(getEmptyOfferMaterialPosition(offerObject.id,offerMaterialPositionArray))
        }
        setIsOpen(isOpenAddMaterial)
    },[isOpenAddMaterial])


    const handleOnClose = () => {
        setIsOpen(false);
        setIsOpenAddMaterial(false);
    }


    const handleAddPosition = () => {
        setOfferMaterialPositionArray([
            ...offerMaterialPositionArray,
            newMaterialPos

        ])
        handleOnClose();
    }



    const handleAddAssignMaterial = () => {
        let tmpId = getTmpId(offerMaterialPositionArray);
        let tmpArray = [...offerMaterialPositionArray];

        assignMaterialList.map((x,idx) => {
            let testMaterial = materialArray.find(y => y.id === x);
            if (testMaterial !== undefined) {
                let newMaterialPos:IOfferMaterialPosition = {
                    title: `${materialSubcategoryArray.find(y => y.id === testMaterial!.MaterialSubcategory)?.materialSubcategory}: ${testMaterial.title}`,
                    description: testMaterial.description,
                    Unit: testMaterial.Unit,
                    price_per_unit: testMaterial.retail_price,
                    id: tmpId-idx,
                    Material: testMaterial.id,
                    MaterialCategory: testMaterial.MaterialCategory,
                    MaterialSubcategory: testMaterial.MaterialSubcategory,
                    position: 1,
                    units: 1,
                    Offer: offerObject.id,
                    Partner: null
                }
                tmpArray.push(newMaterialPos)
            }
        })

        setOfferMaterialPositionArray([...tmpArray]);
        handleCloseAssign();
    }

    const handleCloseAssign = () => {
        setAssignMaterialList([]);
        setIsOpenAssignMaterial(false);
    }



    useEffect(() => {
        let totalSum = offerMaterialPositionArray.map(x => (x.price_per_unit*x.units)).reduce((a,b)=> a+b,0);
        setTotal(totalSum);
    },[offerMaterialPositionArray])



    return(
        <>
            <Dialog open={isOpenAssignMaterial} onClose={handleCloseAssign} maxWidth="md" fullWidth>
                <DialogTitle>Material zuweisen</DialogTitle>
                <DialogContent>
                    <OfferMaterialPositionAssign
                        key={`offermaterialposition-new`}
                        sx={{mt: 2}}
                        materialArray={materialArray}
                        materialCategoryArray={materialCategoryArray}
                        materialSubcategoryArray={materialSubcategoryArray}
                        assignMaterialList={assignMaterialList}
                        setAssignMaterialList={setAssignMaterialList}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleCloseAssign}>Abbruch</Button>
                    <Button variant="contained" disabled={assignMaterialList.length === 0} onClick={handleAddAssignMaterial}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isOpen} onClose={handleOnClose} maxWidth="md" fullWidth>
                <DialogTitle>Neue Materialposition</DialogTitle>
                <DialogContent>
                    <OfferMaterialPositionEdit
                        key={`offermaterialposition-new`}
                        sx={{mt: 2}}
                        offerId={offerObject.id}
                        currentMaterialPosition={newMaterialPos}
                        setCurrentMaterialPosition={setNewMaterialPos}
                        unitArray={unitArray}
                        materialArray={materialArray}
                        materialCategoryArray={materialCategoryArray}
                        materialSubcategoryArray={materialSubcategoryArray}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleOnClose}>Abbruch</Button>
                    <Button variant="contained" onClick={handleAddPosition}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>




            <Typography variant="h5">
                Materialien
            </Typography>

            <Table sx={{mb: 5}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{p:0, width: 50}}></TableCell>
                        <TableCell sx={{p:0, width: 80}}>Artk.Nr.</TableCell>
                        {/* <TableCell sx={{p:0, width: 150}}>Partner</TableCell> */}
                        <TableCell sx={{minWidth: 300}}>Position</TableCell>
                        <TableCell sx={{p:0, width: 50}}></TableCell>
                        {/*
                            <TableCell sx={{p:0, width: 150}}>P.p.S.</TableCell>
                         */}
                        
                        <TableCell sx={{p:0, width: 150}}>Einheiten</TableCell>
                        <TableCell sx={{p:0, width: 50}}>Einheit</TableCell>
                        <TableCell sx={{p:0, width: 150}}>Preis</TableCell>
                        <TableCell sx={{p:0, width: 50}}>Total</TableCell>
                        <TableCell sx={{p:0, width: 50}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {materialCategoryArray.map(category =>
                        materialSubcategoryArray.filter(subcat => subcat.MaterialCategory === category.id).map(subcategory => 
                            offerMaterialPositionArray.filter(pos => pos.MaterialSubcategory === subcategory.id).map(x =>
                                <OfferMaterialPositionRow
                                        key={`materialpos-edit-${x.id}`}
                                        currentMaterialPosition={x}
                                        offerMaterialPositionArray={offerMaterialPositionArray}
                                        setOfferMaterialPositionArray={setOfferMaterialPositionArray}
                                        unitArray={unitArray}
                                        materialArray={materialArray}
                                        materialCategoryArray={materialCategoryArray}
                                        materialSubcategoryArray={materialSubcategoryArray}
                                    />
                    )))}

                    <TableRow>
                        <TableCell colSpan={5} />
                        <TableCell colSpan={2}><b>Summe</b></TableCell>
                        <TableCell>{total.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell/>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={5} />
                        <TableCell sx={{borderBottom: "solid 1px"}} colSpan={2}><b>MwSt.</b></TableCell>
                        <TableCell sx={{borderBottom: "solid 1px"}}>{(total*offerObject.vat).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell/>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={5} />
                        <TableCell sx={{borderBottom: "solid 1px"}} colSpan={2}><b>Total</b></TableCell>
                        <TableCell sx={{borderBottom: "solid 1px"}}>{((total*offerObject.vat)+total).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    )
}