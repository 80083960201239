import React, { useState } from "react";
import { IHandoverProtocolPartner } from "../../../interfaces/IHandoverProtocolPartner";
import { IOfferPosition } from "../../../interfaces/IOfferPosition";
import { IPartner } from "../../../interfaces/IPartner";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { HandoverPartnerPosRow } from "./HandoverPartnerPosRow";
import { IOffer } from "../../../interfaces/IOffer";
import { IUnit } from "../../../interfaces/IUnit";


interface IProps {
    handoverPartner: IHandoverProtocolPartner | null;
    offerArray: IOffer[];
    offerPosArray: IOfferPosition[];
    setOfferPosArray: Function;
    partnerArray: IPartner[];
    handoverPartnerArray: IHandoverProtocolPartner[];
    unitArray: IUnit[];
}


export const HandoverPartnerPosTable:React.FC<IProps> = ({handoverPartner,offerArray,offerPosArray,setOfferPosArray,partnerArray,handoverPartnerArray,unitArray}) => {
    const [partner,setPartner] = useState(handoverPartner === null ? undefined : partnerArray.find(x => x.id === handoverPartner.Partner));

    return(
        <>  <Box sx={{mt: 10}}/>
            <Typography>{(partner === undefined) ? <>Ohne Partner zuweisung</> : `${partner.Contact_Person.Company?.company_name}`}</Typography>

            <Table size="small">
                <TableHead>
                    <TableCell sx={{width: 200}}>Angebot</TableCell>                        
                    <TableCell sx={{width: 150}}>Artikelnummer</TableCell>
                    <TableCell sx={{width: 300}}>Partner</TableCell>
                    <TableCell>Bezeichnung</TableCell>
                    <TableCell sx={{width: 100}}>Einheit</TableCell>
                    <TableCell sx={{width: 200}}>Einheiten</TableCell>
                </TableHead>
                <TableBody>
                    {
                        (offerPosArray.filter(x => x.Partner === (partner === undefined ? null : partner.id)).length === 0)
                        ? <TableRow><TableCell colSpan={6}><i>Keine zugewiesene Positionen vorhanden.</i></TableCell></TableRow>
                        : offerPosArray
                            .filter(x => x.Partner === (partner === undefined ? null : partner.id))
                            .map(x =>
                                <HandoverPartnerPosRow
                                    key={`offerpos-${x.id}`}
                                    offerPosObject={x}
                                    offerArray={offerArray}
                                    offerPosArray={offerPosArray}
                                    partnerArray={partnerArray}
                                    setOfferPosArray={setOfferPosArray}
                                    handoverPartnerArray={handoverPartnerArray}
                                    unitArray={unitArray}
                                />
                                    
                            )
                    }
                </TableBody>
            </Table>
        </>
    )
}