import React, { useEffect, useState } from "react";
import { ITemplate } from "../../../interfaces/ITemplate";
import { ITemplateQuestion } from "../../../interfaces/ITemplateQuestion";
import { Grid, MenuItem, TextField } from "@mui/material";
import { CustomeTextField } from "../../core/CustomeTextField";
import { IHandoverApplicationDocumentAnswers } from "../../../interfaces/IHandoverApplicationDocumentAnswers";
import { IHandoverApplicationDocument } from "../../../interfaces/IHandoverApplicationDocument";


interface IProps {
    template: ITemplate;
    answerObject: IHandoverApplicationDocumentAnswers;
    applicationDocument: IHandoverApplicationDocument;
    answerArray: IHandoverApplicationDocumentAnswers[];
    setAnswerArray: Function;
}


export const HandoverApplicationDocumentAnswerRow: React.FC<IProps> = ({ template, answerObject, applicationDocument, answerArray, setAnswerArray }) => {
    const [currenAnswerObject, setCurrenAnswerObject] = useState(answerObject);
    const [localTemplateQuestion,setLocalTemplateQuestion] = useState(template.TemplateQuestions.find(x => x.id === answerObject.TemplateQuestions));

    useEffect(() => {
        if (localTemplateQuestion !== undefined) {
            setAnswerArray([
                ...answerArray.map(x => x.id === currenAnswerObject.id ? currenAnswerObject : x)
            ])
        }

    }, [currenAnswerObject])

    if (localTemplateQuestion === undefined) { return <>Error!</> } 
    else {
        return (
            <>
                <Grid item sm={6}>
                    {localTemplateQuestion.question}
                </Grid>
    
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Antwort"
                        type="select"
                        attr="TemplateAnswers"
                        object={currenAnswerObject}
                        setObject={setCurrenAnswerObject}
                    >
                        {localTemplateQuestion.TemplateAnswers.map(answers =>
                            <MenuItem value={answers.id} key={`answer-${currenAnswerObject.id}-${answers.id}`}>
                                {answers.answer}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
    
            </>
        )
    }

}

